import useAPI from "@toothfairy/shared-api/useApi";
import { AppText } from "@toothfairy/shared-ui";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import clsx from "clsx";
import { memo, useEffect, useState } from "react";
import envConfig from "../../../../envConfig";
import Files from "../../../API/Files";
import { useTreeview } from "../../../Hooks/useTreeView";
import useWorkspaces from "../../../Hooks/useWorkspaces";
import { useHistory } from "../../../Router";
import Utility from "../../../Utility";
import styles from "../Styles/index.css";
import TreeIcon from "./TreeIcon";

const dataTypes = [
  "pdf",
  "md",
  "txt",
  "html",
  "java",
  "py",
  "yaml",
  "yml",
  "sql",
  "sh",
  "php",
  "js",
  "ts",
  "csharp",
  "rb",
  "tsx",
  "jsx",
];

const CoreNodeView = ({
  droppable,
  name,
  currentTheme,
  editLeafParentAction = "EDIT_FOLDER",
  editLeafAction = "EDIT_DOCUMENT",
  deleteAction = "DELETE_NODE",
  isDrag,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <span
        style={{
          color: currentTheme?.theme.primary,
          position: "relative",
          marginRight: 5,
        }}
      >
        {!droppable ? (
          <TreeIcon icon={AppAssets.icons.file} />
        ) : (
          <TreeIcon icon={AppAssets.icons.folder} />
        )}
      </span>
      <span
        style={{
          color: currentTheme?.theme.font_color,
          display: "inline-block",
          maxWidth: "180px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          verticalAlign: "bottom",
        }}
      >
        {name}
      </span>
    </div>
  );
};

function Node({ node, style, dragHandle }) {
  const { currentTheme, mode } = AppTheme.useTheme();
  const [isMouseOver, setMouseOver] = useState(false);
  const [nodeImage, setNodeImage] = useState(null);
  const {
    showIconsOnSelection,
    onSelectionChanged,
    selectedNode,
    onEdit,
    onDeletion,
    setDialogActionOnTree,
    editLeafParentAction,
    editLeafAction,
    deleteAction,
    allowParentEdit,
    allowParentAction,
    onParentActionClick,
    fixedNodeIds,
    treemode,
  } = useTreeview();
  const {
    data: S3downloadUrlData,
    loading: urldownloadGenerationInProgress,
    apiRequest: S3downloadUrlRequest,
    response: S3downloadResponse,
  } = useAPI(Files.downloadUrlGeneration, envConfig);

  const getNodeImageUrl = async (path) => {
    const _url = await S3downloadUrlRequest({
      filename: `imported-image/${getActiveWorkspace()?.id}/${path}`,
      context: "pdf",
      workspaceid: getActiveWorkspace()?.id,
    });
    if (_url?.url) setNodeImage(_url?.url);
  };
  useEffect(() => {
    setMouseOver(false);
  }, [node?.data?.id]);
  const { getActiveWorkspace, isUserAIEngineer } = useWorkspaces();
  const history = useHistory();
  useEffect(() => {
    node?.state?.isSelected && onSelectionChanged(node);
  }, [node?.state?.isSelected]);
  useEffect(() => {
    if (node?.data?.icon) {
      getNodeImageUrl(node?.data?.icon);
    }
  }, [node?.data?.icon]);
  function getTreeIcon(treemode, node) {
    if (treemode === "CHATS") {
      if (node?.data?.is_agent) {
        if (node?.data?.is_agent_available == false) {
          return AppAssets.icons.accessDenied;
        } else if (node?.data?.icon) return null;
        else {
          return Utility.mapAgentTypeWithIcon(node?.data, AppAssets);
        }
      } else {
        return AppAssets.icons.day;
      }
    } else {
      return AppAssets.icons.folder;
    }
  }
  function getTreeIconColor(treemode, node) {
    if (treemode === "CHATS") {
      if (node?.data?.is_agent) {
        if (node?.data?.is_agent_available == false) {
          return currentTheme?.theme.error;
        } else if (node?.data?.icon) return null;
        else {
          if (mode == "dark")
            return node?.data?.darkColor || currentTheme?.theme.primary;
          else return node?.data?.color || currentTheme?.theme.primary;
        }
      } else {
        return currentTheme?.theme.primary;
      }
    } else {
      return currentTheme?.theme.primary;
    }
  }
  return (
    <div
      data-tooltip={
        !node?.data?.droppable && !node?.state?.isSelected && isMouseOver
          ? node?.data?.name
          : null
      }
      onMouseEnter={() => {
        setTimeout(() => {
          setMouseOver(true);
        }, 1000);
      }}
      onMouseLeave={() => {
        setMouseOver(false);
      }}
      data-tooltip-position="bottom"
      ref={dragHandle}
      style={{
        ...style,
        flexDirection: "row",
        alignItems: "center",
        marginTop:
          !node?.data?.droppable && !node?.state?.isSelected && isMouseOver
            ? 4
            : 8,
        display: "flex",
        justifyContent: "space-between",
      }}
      className={clsx(styles.node, node.state)}
      onClick={() => {
        node.isInternal && node.toggle();
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <FolderArrow
          node={node}
          onPress={() => {
            node.isInternal && node.toggle();
          }}
        />
        <span
          style={{
            color: currentTheme?.theme.primary,
            position: "relative",
            marginRight: 5,
          }}
        >
          {!node?.data?.droppable ? (
            dataTypes.includes(node?.data?.type) ? (
              <TreeIcon icon={AppAssets.icons[node?.data?.type]} />
            ) : (
              <TreeIcon
                icon={
                  treemode == "CHATS"
                    ? AppAssets.icons.chatIcon
                    : AppAssets.icons.file
                }
              />
            )
          ) : (
            <TreeIcon
              imageBorderRadius={4}
              imageSource={nodeImage}
              icon={getTreeIcon(treemode, node)}
              onPress={() => {
                node.isInternal && node.toggle();
              }}
              color={getTreeIconColor(treemode, node)}
              wrapperStyle={{
                marginBottom: node?.data?.is_agent ? 3 : 0,
              }}
            />
          )}
        </span>
        <span
          style={{
            color: currentTheme?.theme.font_color,
            display: "inline-block",
            maxWidth: treemode == "CHATS" ? "220px" : "180px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            verticalAlign: "bottom",
            fontWeight:
              treemode == "CHATS" && node?.data?.is_agent ? "bold" : "normal",
          }}
          className="node_tree_tf"
        >
          {node.isEditing ? <Input node={node} /> : node.data.name}
        </span>
        <span
          style={{
            color: currentTheme?.theme.primary,
            marginLeft: 2,
            fontSize: currentTheme?.theme.extra_small_font_size,
          }}
        >
          {node.data.children?.length > 0 &&
            `  (${node.data.children?.length})`}
        </span>
      </div>
      {allowParentAction &&
        node?.data?.is_agent &&
        node?.data?.is_agent_available != false && (
          <div
            style={{
              display: "inline-flex",
              float: "right",
            }}
          >
            <span
              style={{
                color: currentTheme?.theme.primary,
                position: "relative",
                marginRight: 5,
              }}
            >
              {!fixedNodeIds?.includes(node?.id) && (
                <div
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    display: "flex",
                    gap: 5,
                  }}
                >
                  {isUserAIEngineer() && (
                    <TreeIcon
                      wrapperStyle={{
                        marginBottom: 2,
                      }}
                      icon={AppAssets.icons.edit}
                      color={currentTheme?.theme.font_color}
                      onPress={() => {
                        history.push(
                          `/workspaces/${
                            getActiveWorkspace()?.id
                          }/settings/roles_settings/${node?.id}`
                        );
                      }}
                    />
                  )}

                  <TreeIcon
                    icon={AppAssets.icons.chatIcon}
                    onPress={() => {
                      onParentActionClick(node?.id);
                    }}
                  />
                </div>
              )}
            </span>
          </div>
        )}
      {showIconsOnSelection &&
        node?.state?.isSelected &&
        (allowParentEdit || !node?.data?.droppable) && (
          <div
            style={{
              display: "inline-flex",
              float: "right",
            }}
          >
            <span
              style={{
                color: currentTheme?.theme.primary,
                position: "relative",
                marginRight: 5,
              }}
            >
              {!fixedNodeIds?.includes(node?.id) && (
                <TreeIcon
                  icon={AppAssets.icons.edit}
                  onPress={() => {
                    onSelectionChanged(node);
                    setDialogActionOnTree(
                      node?.data?.droppable
                        ? editLeafParentAction
                        : editLeafAction
                    );
                  }}
                />
              )}
            </span>
            <span
              style={{
                color: currentTheme?.theme.red,
                position: "relative",
                marginRight: 5,
              }}
            >
              {!fixedNodeIds?.includes(node?.id) && (
                <TreeIcon
                  icon={AppAssets.icons.delete}
                  color={currentTheme?.theme?.red}
                  onPress={() => {
                    onSelectionChanged(node, true);
                    setDialogActionOnTree(deleteAction);
                  }}
                />
              )}
            </span>
          </div>
        )}
    </div>
  );
}

function Input({ node }) {
  return (
    <input
      autoFocus
      type="text"
      defaultValue={node.data.name}
      onFocus={(e) => e.currentTarget.select()}
      onBlur={() => node.reset()}
      onKeyDown={(e) => {
        if (e.key === "Escape") node.reset();
        if (e.key === "Enter") node.submit(e.currentTarget.value);
      }}
    />
  );
}

function Row({ node, innerRef, attrs, children }) {
  return <AppText> {node?.data?.name || ""}</AppText>;
}

function FolderArrow({ node, onPress }) {
  const { currentTheme } = AppTheme.useTheme();
  if (!node?.data?.droppable) return <span></span>;
  return (
    <span
      style={{
        position: "relative",
        marginRight: 5,
        color: currentTheme?.theme.primary,
      }}
    >
      {node.isOpen ? (
        <TreeIcon icon={AppAssets.icons.arrow_down} onPress={onPress} />
      ) : (
        <TreeIcon icon={AppAssets.icons.arrow_right} onPress={onPress} />
      )}
    </span>
  );
}
const layerStyles = {
  position: "fixed",
  pointerEvents: "none",
  zIndex: 100,
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
};
const getStyle = (offset) => {
  if (!offset) return { display: "none" };
  const { x, y } = offset;
  return { transform: `translate(${x}px, ${y}px)` };
};
const Overlay = memo(function Overlay(props) {
  if (!props.isDragging) return null;
  return <div style={layerStyles}>{props.children}</div>;
});

function Position(props) {
  return (
    <div className="row preview" style={getStyle(props.offset)}>
      {props.children}
    </div>
  );
}
const PreviewNode = memo(function PreviewNode(props) {
  const { treeData, findNodeById } = useTreeview();
  const { currentTheme } = AppTheme.useTheme();
  const node = findNodeById(props.id, treeData)[0];
  return (
    <CoreNodeView
      isDrag={true}
      droppable={node?.droppable}
      name={node?.name}
      currentTheme={currentTheme}
    />
  );
});
function DragPreview({ offset, mouse, id, dragIds, isDragging }) {
  const correctedOffset = {
    x: offset?.x - 200,
    y: offset?.y - 50,
  };
  return (
    <Overlay isDragging={isDragging}>
      <Position offset={correctedOffset}>
        <PreviewNode id={id} dragIds={dragIds} />
      </Position>
    </Overlay>
  );
}

export default {
  Node,
  Input,
  FolderArrow,
  Row,
  DragPreview,
};
