import styled from "@emotion/native";
import { AppIcon, AppText } from "@toothfairy/shared-ui";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppUser from "@toothfairy/shared-ui/AppUser";
import React, { useCallback } from "react";
import { Platform, TouchableOpacity, View } from "react-native";
import { useReactiveVar } from "react-reactive-var";
import {
  lastPathInWorkspace,
  pinnedSideMenu,
  showSideMenu,
  userType,
} from "../../../App";
import useWorkspaces from "../../Hooks/useWorkspaces";
import { Link, matchPath, useLocation, useHistory } from "../../Router";
import { routes } from "../../Router/routes";
import { appDimensions, isMobileScreen } from "../../Screens/MainViewContainer";
import { MenuItem } from "./shared";
import { SideBar } from "./SideBar";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppExpander from "@toothfairy/shared-ui/AppExpander";
import usePath from "../../Hooks/usePath";
import Constants from "../../Constants";
import { useMemo } from "react";
import ToothFairyLoadingPlaceholder from "../ToothFairyComponents/ToothFairyLoadingPlaceholder";

const Content = styled(View)`
  width: 100%;

  height: 100%;
`;

const ConditionalLink = ({ children, to, condition, style, onClick }) => {
  return condition && to ? (
    <Link to={to} style={style} onClick={onClick}>
      {children}
    </Link>
  ) : (
    <AppText isClickable={true} isHoverable={false} onPress={() => {}}>
      {children}
    </AppText>
  );
};

//Using styled with Link renders an error
export const MenuLink = ({ children, to }) => {
  const { pathname } = useLocation();
  const { isInWorkspace } = usePath();
  const { state } = AppStateManager.useAppStateManager();
  const closeMenu = useCallback(() => {
    if (isMobileScreen()) showSideMenu(false);
    if ((to === routes.workspaces || to === routes.profile) && isInWorkspace) {
      lastPathInWorkspace({
        workspaceid: state?.activeWorkspace?.id,
        path: pathname,
      });
    }
  }, [pathname, isInWorkspace, state?.activeWorkspace?.id, to]);
  const _menuLinkView = useMemo(() => {
    if (Platform.OS === "web") {
      return (
        <ConditionalLink
          condition={!state?.changesNotSaved}
          style={{ textDecoration: "none" }}
          to={to}
          onClick={closeMenu}
        >
          {children}
        </ConditionalLink>
      );
    } else {
      return (
        <Link
          component={TouchableOpacity}
          style={{ textDecoration: "none" }}
          to={to}
          onPress={closeMenu}
          onClick={closeMenu}
        >
          {children}
        </Link>
      );
    }
  }, [children, to, state?.changesNotSaved, closeMenu]);

  return _menuLinkView;
};

const SideMenu = ({ isInWorkspace, handleSignOut }) => {
  const { height, width } = useReactiveVar(appDimensions);
  const { currentTheme, mode } = AppTheme.useTheme();
  const showMenu = useReactiveVar(showSideMenu);
  const { state } = AppStateManager.useAppStateManager();
  const {
    isWorkspaceEnabled,
    isUserAdmin,
    isUserAIEngineer,
    isUserContentAdmin,
    isUserRoot,
    getPlanConfig,
  } = useWorkspaces();
  const isPinned = useReactiveVar(pinnedSideMenu);
  const isMobile = width < Constants.screenResponsiveWidth;
  const location = useLocation();
  const history = useHistory();
  const _appLogo = useMemo(
    () => (
      <AppIcon
        style={{
          paddingVertical: 10,
          paddingRight: 5,
          width: 152,
          marginLeft: 15,
        }}
        isClickable
        onPress={() => {
          history.push(routes.workspaces);
        }}
        size={24}
        imageSource={require("../../assets/logoTitle.png")}
        width={"100%"}
      />
    ),
    []
  );
  const multiPathMatch = useCallback(
    (paths) => {
      let matchFound = false;
      paths.forEach((element) => {
        if (
          matchPath(location?.pathname, {
            path: element,
            exact: true,
          })?.isExact
        )
          matchFound = true;
      });
      return matchFound;
    },
    [location?.pathname]
  );
  const {
    section,
    path,
    lastNLPdocumentOpened,
    lastRCdocumentOpened,
    lastMRCdocumentOpened,
    lastGNdocumentOpened,
    lastDashboardTabOpened,
    lastDocumentsTabOpened,
    lastTrainingTabOpened,
    lastBatchTabOpened,
    lastSettingsTabOpened,
    lastAdminTabOpened,
    lastArenaTabOpened,
    lastProfileTabOpened,
    lastChatOpened,
  } = usePath();
  const logoutButton = (
    <AppText
      iconSize={20}
      style={{
        paddingVertical: 10,
        paddingHorizontal: 20,
        justifyContent: "flex-start",
        fontWeight: "800",
      }}
      wrapperStyle={{
        paddingHorizontal: 20,
      }}
      // color={currentTheme?.theme?.red}
      leftIcon={AppAssets?.icons?.signOut}
      // leftIconColor={currentTheme?.theme?.red}
      isClickable
      isHoverable={false}
      onPress={handleSignOut}
    >
      Logout
    </AppText>
  );
  const _outsideWorkspaceMenuItems = useMemo(() => {
    return (
      <View
        style={{
          marginBottom: 10,
        }}
      >
        <MenuItem
          to={routes.workspaces}
          label="Workspaces"
          icon={AppAssets.icons.workspace}
          accessibilityLabel="WorkspacesRoute"
        />
        <MenuItem
          to={_profileTo}
          current={multiPathMatch([`/profile`, `/profile/:sectionId`])}
          label="Profile"
          icon={AppAssets.icons.account}
          accessibilityLabel="ProfileRoute"
        />
        {state?.activeWorkspaceID &&
          !isInWorkspace &&
          isWorkspaceEnabled &&
          goBackButton}
      </View>
    );
  }, [
    isInWorkspace,
    isWorkspaceEnabled,
    state?.activeWorkspaceID,
    multiPathMatch,
    _profileTo,
    goBackButton,
    isUserAdmin(),
  ]);
  const goBackButton = (
    <AppText
      iconSize={20}
      style={{
        paddingVertical: 10,
        paddingHorizontal: 20,
        justifyContent: "flex-start",
        fontWeight: "800",
      }}
      wrapperStyle={{
        paddingHorizontal: 20,
      }}
      leftIcon={AppAssets?.icons?.back_icon}
      isClickable
      isHoverable={false}
      onPress={() => {
        if (lastPathInWorkspace()?.path)
          history?.push(lastPathInWorkspace()?.path);
      }}
    >
      Back to workspace
    </AppText>
  );
  const _dashboardTo =
    lastDashboardTabOpened ||
    `/workspaces/${state.activeWorkspaceID}/dashboard/model`;
  const _nlpTo = `/workspaces/${state.activeWorkspaceID}/nlp`;
  const _rcTo =
    section === "RC_TRAINER"
      ? path.url
      : lastRCdocumentOpened
      ? lastRCdocumentOpened
      : `/workspaces/${state.activeWorkspaceID}/answerer`;
  const _mrcTo =
    section === "MRC_DOCUMENTS"
      ? path.url
      : lastMRCdocumentOpened
      ? lastMRCdocumentOpened
      : `/workspaces/${state.activeWorkspaceID}/mrc_contents`;
  const _gnTo =
    section === "GN_DOCUMENTS"
      ? path.url
      : lastGNdocumentOpened
      ? lastGNdocumentOpened
      : `/workspaces/${state.activeWorkspaceID}/generator`;

  const _chatsTo =
    section === "CHATS"
      ? path.url
      : lastChatOpened
      ? lastChatOpened
      : `/workspaces/${state.activeWorkspaceID}/chats`;
  const _documentsTo =
    lastDocumentsTabOpened ||
    `/workspaces/${state.activeWorkspaceID}/documents/gn`;
  const _trainingTo =
    lastTrainingTabOpened ||
    `/workspaces/${state.activeWorkspaceID}/training/gn`;
  const _batchTo =
    lastBatchTabOpened || `/workspaces/${state.activeWorkspaceID}/batch/gn`;
  const _settingsTo =
    section === "SETTINGS"
      ? path.url
      : lastSettingsTabOpened
      ? lastSettingsTabOpened
      : `/workspaces/${state.activeWorkspaceID}/settings/${
          isUserAIEngineer() ? "roles_settings" : "topic"
        }`;
  const _adminTo =
    section === "ADMIN"
      ? path.url
      : lastAdminTabOpened
      ? lastAdminTabOpened
      : `/workspaces/${state.activeWorkspaceID}/admin/workspace_settings`;
  const _arenaTo =
    section === "ARENA"
      ? path.url
      : lastArenaTabOpened
      ? lastArenaTabOpened
      : `/workspaces/${state.activeWorkspaceID}/arena`;
  const _profileTo =
    section === "PROFILE_SETTINGS"
      ? path.url
      : lastProfileTabOpened
      ? lastProfileTabOpened
      : `/profile/profile_settings`;
  const { user } = AppUser.useUser();
  const { getActiveWorkspace, isBatchEnabled } = useWorkspaces();

  const _inWorkspaceMenuItems = useMemo(() => {
    return (
      <View
        style={{
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <MenuItem
          current={multiPathMatch([
            `/workspaces/:workspaceId/chats`,
            `/workspaces/:workspaceId/chats/:chatId`,
          ])}
          to={_chatsTo}
          label="Chat agent"
          accessibilityLabel="ChatRoute"
          icon={AppAssets.icons.quantumChat}
        />
        {getPlanConfig()?.isContentCreatorEnabled && (
          <MenuItem
            current={multiPathMatch([
              `/workspaces/:workspaceId/generator`,
              `/workspaces/:workspaceId/generator/:documentId`,
            ])}
            to={_gnTo}
            label="Content generation"
            accessibilityLabel="GNRoute"
            icon={AppAssets.icons.writer}
          />
        )}
        {getPlanConfig()?.isBusinessAnalystEnabled && (
          <MenuItem
            current={multiPathMatch([
              `/workspaces/:workspaceId/answerer`,
              `/workspaces/:workspaceId/answerer/:documentId`,
            ])}
            to={_rcTo}
            label="Reading comprehension"
            accessibilityLabel="RCRoute"
            icon={AppAssets.icons.ba}
          />
        )}

        {
          <MenuItem
            current={false}
            to={_nlpTo}
            label="Sentiment reviewer"
            icon={AppAssets.icons.trainingLog}
            accessibilityLabel="NLPRoute"
          />
        }

        {isUserContentAdmin() && (
          <MenuItem
            current={multiPathMatch([
              `/workspaces/:workspaceId/mrc_contents`,
              `/workspaces/:workspaceId/mrc_contents/:documentId`,
            ])}
            to={_mrcTo}
            label="Knowledge hub"
            accessibilityLabel="MRCRoute"
            icon={AppAssets.icons.notes}
          />
        )}

        <>
          {(isUserContentAdmin() || isUserAIEngineer()) && (
            <MenuItem
              to={_settingsTo}
              current={multiPathMatch([
                `/workspaces/:workspaceId/settings`,
                `/workspaces/:workspaceId/settings/:sectionId`,
              ])}
              label="Settings"
              icon={AppAssets.icons.settings}
              accessibilityLabel="SettingsRoute"
            />
          )}
          {isUserAIEngineer() && (
            <AppExpander
              style={{ minWidth: 250 }}
              expanderStyle={{
                marginLeft: 13,
                paddingVertical: 3,
              }}
              isExpandedOnLoad={multiPathMatch([
                `/workspaces/:workspaceId/dashboard/:sectionId`,
                `/workspaces/:workspaceId/documents`,
                `/workspaces/:workspaceId/documents/:sectionId`,
                `/workspaces/:workspaceId/documents/:sectionId/:documentId`,
                `/workspaces/:workspaceId/nlp/:documentId`,
                `/workspaces/:workspaceId/training`,
                `/workspaces/:workspaceId/training/:sectionId`,
                `/workspaces/:workspaceId/arena`,
                `/workspaces/:workspaceId/arena/:sectionId`,
                `/workspaces/:workspaceId/arena_leaderboard/:sectionId`,
                `/workspaces/:workspaceId/arena_leaderboard`,
                `/workspaces/:workspaceId/batch`,
                `/workspaces/:workspaceId/batch/:sectionId`,
                `/workspaces/:workspaceId/admin`,
                `/workspaces/:workspaceId/admin/:sectionId`,
              ])}
              title="Advanced"
              icon={AppAssets.icons.advanced}
              titleStyle={{
                marginLeft: 14,
                color: currentTheme?.theme?.font_color,
                fontWeight: "800",
                fontFamily: "normalFont",
              }}
              forceIconColor={currentTheme?.theme?.primary}
            >
              <View
                style={{
                  paddingLeft: 10,
                }}
              >
                {isUserAdmin() && (
                  <MenuItem
                    current={multiPathMatch([
                      "/workspaces/:workspaceId/dashboard/:sectionId",
                    ])}
                    to={_dashboardTo}
                    label="Dashboard"
                    icon={AppAssets.icons.dashboard}
                    accessibilityLabel="DashboardRoute"
                  />
                )}
                {isUserAIEngineer() && (
                  <>
                    {
                      <>
                        <MenuItem
                          to={_documentsTo}
                          current={multiPathMatch([
                            `/workspaces/:workspaceId/documents`,
                            `/workspaces/:workspaceId/documents/:sectionId`,
                            `/workspaces/:workspaceId/documents/:sectionId/:documentId`,
                            `/workspaces/:workspaceId/nlp/:documentId`,
                          ])}
                          label="Documents"
                          icon={AppAssets.icons.trainAI}
                          accessibilityLabel="DocumentsRoute"
                        />
                        <MenuItem
                          to={_trainingTo}
                          current={multiPathMatch([
                            `/workspaces/:workspaceId/training`,
                            `/workspaces/:workspaceId/training/:sectionId`,
                          ])}
                          label="Fine-tuning"
                          icon={AppAssets.icons.slidersH}
                          accessibilityLabel="TrainingRoute"
                        />
                      </>
                    }
                    {isBatchEnabled() && (
                      <MenuItem
                        to={_batchTo}
                        current={multiPathMatch([
                          `/workspaces/:workspaceId/batch`,
                          `/workspaces/:workspaceId/batch/:sectionId`,
                        ])}
                        label="Batch"
                        icon={AppAssets.icons.duplicate}
                        accessibilityLabel="batchRoute"
                      />
                    )}
                  </>
                )}
                {isUserAdmin() && (
                  <MenuItem
                    to={_arenaTo}
                    current={multiPathMatch([
                      `/workspaces/:workspaceId/arena`,
                      `/workspaces/:workspaceId/arena/:sectionId`,
                      `/workspaces/:workspaceId/arena_leaderboard/:sectionId`,
                      `/workspaces/:workspaceId/arena_leaderboard`,
                    ])}
                    label="Arena"
                    icon={AppAssets.icons.arena}
                    accessibilityLabel="ArenaRoute"
                  />
                )}
                {isUserAdmin() && (
                  <MenuItem
                    to={_adminTo}
                    current={multiPathMatch([
                      `/workspaces/:workspaceId/admin`,
                      `/workspaces/:workspaceId/admin/:sectionId`,
                    ])}
                    label="Admin"
                    icon={AppAssets.icons.admin}
                    accessibilityLabel="AdminRoute"
                  />
                )}
              </View>
            </AppExpander>
          )}
        </>
      </View>
    );
  }, [
    getActiveWorkspace()?.id,
    isBatchEnabled(),
    user?.id,
    _adminTo,
    _arenaTo,
    _dashboardTo,
    _documentsTo,
    _nlpTo,
    _rcTo,
    _mrcTo,
    _gnTo,
    _settingsTo,
    _trainingTo,
    location?.pathname,
    userType(),
  ]);
  return (
    <SideBar
      showMenu={showMenu}
      width={width}
      height={height}
      isPinned={isPinned}
      currentTheme={currentTheme}
    >
      <Content height={height} width={width}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: currentTheme?.theme?.backgroundColor,
          }}
        >
          {_appLogo}
        </View>
        <View
          style={{
            flexDirection: "column",
            justifyContent: "space-between",
            overflowY: "auto",
            flex: 1,
            backgroundColor: currentTheme?.theme?.backgroundColor,
            borderTopColor: currentTheme?.theme?.app_outline_color,
            borderTopWidth: 1,
            borderRightWidth: 1,
            borderRightColor: currentTheme?.theme?.app_outline_color,
          }}
        >
          {!userType() && isInWorkspace ? (
            <ToothFairyLoadingPlaceholder caption="Loading..." />
          ) : (
            isInWorkspace && userType() && _inWorkspaceMenuItems
          )}
          <View
            style={{
              marginBottom: 10,
              justifyContent: "space-between",
            }}
          >
            <View>
              <MenuItem
                to={routes.workspaces}
                label="Workspaces"
                icon={AppAssets.icons.workspace}
                accessibilityLabel="WorkspacesRoute"
              />
              <MenuItem
                to={_profileTo}
                current={multiPathMatch([`/profile`, `/profile/:sectionId`])}
                label="Profile"
                icon={AppAssets.icons.account}
                accessibilityLabel="ProfileRoute"
              />
              {state?.activeWorkspaceID &&
                !isInWorkspace &&
                isWorkspaceEnabled &&
                !multiPathMatch([routes.accessDenied]) &&
                lastPathInWorkspace() &&
                goBackButton}
            </View>
            {isInWorkspace && logoutButton}
          </View>
          {!isInWorkspace && logoutButton}
        </View>
      </Content>
    </SideBar>
  );
};

export default SideMenu;
