export const schema = {
  models: {
    AppConfig: {
      name: "AppConfig",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        saveTimer: {
          name: "saveTimer",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        screenResponsiveWidth: {
          name: "screenResponsiveWidth",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        mobileResponsiveWidth: {
          name: "mobileResponsiveWidth",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        veryLargeScreenResponsiveWidth: {
          name: "veryLargeScreenResponsiveWidth",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        chartMaxHeight: {
          name: "chartMaxHeight",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        inactiveTimeout: {
          name: "inactiveTimeout",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        sideMenuWidth: {
          name: "sideMenuWidth",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        availableLanguages: {
          name: "availableLanguages",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        baseIntents: {
          name: "baseIntents",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        baseEntities: {
          name: "baseEntities",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        baseTopics: {
          name: "baseTopics",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        supportDomain: {
          name: "supportDomain",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        metadata: {
          name: "metadata",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "AppConfigs",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    User: {
      name: "User",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        username: {
          name: "username",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        user_email: {
          name: "user_email",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        user_email_verified: {
          name: "user_email_verified",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        user_phone_number: {
          name: "user_phone_number",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        timezone: {
          name: "timezone",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        language: {
          name: "language",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        isSentenceSplitEnabled: {
          name: "isSentenceSplitEnabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isImplicitSelfEnabled: {
          name: "isImplicitSelfEnabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        replyLanguage: {
          name: "replyLanguage",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        theme: {
          name: "theme",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        user_phone_number_verified: {
          name: "user_phone_number_verified",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        userInstructions: {
          name: "userInstructions",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        userMemories: {
          name: "userMemories",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        isOnboarded: {
          name: "isOnboarded",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        userHelperPreferences: {
          name: "userHelperPreferences",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        type: {
          name: "type",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        workspaces: {
          name: "workspaces",
          isArray: true,
          type: {
            model: "Workspace",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "userID",
          },
        },
        cognitoUsername: {
          name: "cognitoUsername",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        stripeCustomerId: {
          name: "stripeCustomerId",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        stripePaymentIntentId: {
          name: "stripePaymentIntentId",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        maximumNumberOfWorkspaces: {
          name: "maximumNumberOfWorkspaces",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        workspacesAccessLevel: {
          name: "workspacesAccessLevel",
          isArray: true,
          type: {
            model: "UserWorkspaceType",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "userID",
          },
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Users",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "usersByOwner",
            queryField: "usersByOwner",
            fields: ["owner"],
          },
        },
        {
          type: "key",
          properties: {
            name: "usersByEmail",
            queryField: "usersByEmail",
            fields: ["user_email"],
          },
        },
        {
          type: "key",
          properties: {
            name: "usersByStripeCustomerID",
            queryField: "usersByStripeCustomerID",
            fields: ["stripeCustomerId"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                provider: "userPools",
                ownerField: "owner",
                allow: "owner",
                identityClaim: "cognito:username",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Workspace: {
      name: "Workspace",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        title: {
          name: "title",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        visibility: {
          name: "visibility",
          isArray: false,
          type: {
            enum: "Visibility",
          },
          isRequired: true,
          attributes: [],
        },
        userID: {
          name: "userID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        isMed: {
          name: "isMed",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        type: {
          name: "type",
          isArray: false,
          type: {
            enum: "WorkspaceAiType",
          },
          isRequired: true,
          attributes: [],
        },
        usagePlanID: {
          name: "usagePlanID",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        workspaceTokenID: {
          name: "workspaceTokenID",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        workspaceToken: {
          name: "workspaceToken",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        subscriptionType: {
          name: "subscriptionType",
          isArray: false,
          type: {
            enum: "PlanType",
          },
          isRequired: false,
          attributes: [],
        },
        subscriptionId: {
          name: "subscriptionId",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        subscriptionStatus: {
          name: "subscriptionStatus",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        enableExperimentalFeatures: {
          name: "enableExperimentalFeatures",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        utilityEndpoint: {
          name: "utilityEndpoint",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        utilityUrl: {
          name: "utilityUrl",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        utilityUrlStatus: {
          name: "utilityUrlStatus",
          isArray: false,
          type: {
            enum: "QaUrlStatus",
          },
          isRequired: false,
          attributes: [],
        },
        utilityUrlType: {
          name: "utilityUrlType",
          isArray: false,
          type: {
            enum: "QaUrlType",
          },
          isRequired: false,
          attributes: [],
        },
        utilityBaseModel: {
          name: "utilityBaseModel",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        utilityProvider: {
          name: "utilityProvider",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        codingEndpoint: {
          name: "codingEndpoint",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        codingUrl: {
          name: "codingUrl",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        codingUrlStatus: {
          name: "codingUrlStatus",
          isArray: false,
          type: {
            enum: "QaUrlStatus",
          },
          isRequired: false,
          attributes: [],
        },
        codingUrlType: {
          name: "codingUrlType",
          isArray: false,
          type: {
            enum: "QaUrlType",
          },
          isRequired: false,
          attributes: [],
        },
        codingBaseModel: {
          name: "codingBaseModel",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        codingProvider: {
          name: "codingProvider",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        generalPurposeEndpoint: {
          name: "generalPurposeEndpoint",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        generalPurposeUrl: {
          name: "generalPurposeUrl",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        generalPurposeUrlStatus: {
          name: "generalPurposeUrlStatus",
          isArray: false,
          type: {
            enum: "QaUrlStatus",
          },
          isRequired: false,
          attributes: [],
        },
        generalPurposeUrlType: {
          name: "generalPurposeUrlType",
          isArray: false,
          type: {
            enum: "QaUrlType",
          },
          isRequired: false,
          attributes: [],
        },
        generalPurposeBaseModel: {
          name: "generalPurposeBaseModel",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        generalPurposeProvider: {
          name: "generalPurposeProvider",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        qaEnpoint: {
          name: "qaEnpoint",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        qaUrl: {
          name: "qaUrl",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        qaUrlStatus: {
          name: "qaUrlStatus",
          isArray: false,
          type: {
            enum: "QaUrlStatus",
          },
          isRequired: false,
          attributes: [],
        },
        qaUrlType: {
          name: "qaUrlType",
          isArray: false,
          type: {
            enum: "QaUrlType",
          },
          isRequired: false,
          attributes: [],
        },
        qaBaseModel: {
          name: "qaBaseModel",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        qaProvider: {
          name: "qaProvider",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        cnEndpoint: {
          name: "cnEndpoint",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        cnUrl: {
          name: "cnUrl",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        cnUrlStatus: {
          name: "cnUrlStatus",
          isArray: false,
          type: {
            enum: "QaUrlStatus",
          },
          isRequired: false,
          attributes: [],
        },
        cnUrlType: {
          name: "cnUrlType",
          isArray: false,
          type: {
            enum: "QaUrlType",
          },
          isRequired: false,
          attributes: [],
        },
        cnBaseModel: {
          name: "cnBaseModel",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        cnProvider: {
          name: "cnProvider",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        gnEnpoint: {
          name: "gnEnpoint",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        gnUrl: {
          name: "gnUrl",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        gnUrlStatus: {
          name: "gnUrlStatus",
          isArray: false,
          type: {
            enum: "QaUrlStatus",
          },
          isRequired: false,
          attributes: [],
        },
        gnUrlType: {
          name: "gnUrlType",
          isArray: false,
          type: {
            enum: "QaUrlType",
          },
          isRequired: false,
          attributes: [],
        },
        gnBaseModel: {
          name: "gnBaseModel",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        gnProvider: {
          name: "gnProvider",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        nlpEnpoint: {
          name: "nlpEnpoint",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        nlpUrl: {
          name: "nlpUrl",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        nlpUrlStatus: {
          name: "nlpUrlStatus",
          isArray: false,
          type: {
            enum: "QaUrlStatus",
          },
          isRequired: false,
          attributes: [],
        },
        nlpUrlType: {
          name: "nlpUrlType",
          isArray: false,
          type: {
            enum: "QaUrlType",
          },
          isRequired: false,
          attributes: [],
        },
        nlpBaseModel: {
          name: "nlpBaseModel",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        nlpProvider: {
          name: "nlpProvider",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        embeddingEndpoint: {
          name: "embeddingEndpoint",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        embeddingUrl: {
          name: "embeddingUrl",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        embeddingUrlStatus: {
          name: "embeddingUrlStatus",
          isArray: false,
          type: {
            enum: "QaUrlStatus",
          },
          isRequired: false,
          attributes: [],
        },
        embeddingUrlType: {
          name: "embeddingUrlType",
          isArray: false,
          type: {
            enum: "QaUrlType",
          },
          isRequired: false,
          attributes: [],
        },
        embeddingBaseModel: {
          name: "embeddingBaseModel",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        embeddingProvider: {
          name: "embeddingProvider",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        chartingEndpoint: {
          name: "chartingEndpoint",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        chartingUrl: {
          name: "chartingUrl",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        chartingUrlStatus: {
          name: "chartingUrlStatus",
          isArray: false,
          type: {
            enum: "QaUrlStatus",
          },
          isRequired: false,
          attributes: [],
        },
        chartingUrlType: {
          name: "chartingUrlType",
          isArray: false,
          type: {
            enum: "QaUrlType",
          },
          isRequired: false,
          attributes: [],
        },
        chartingBaseModel: {
          name: "chartingBaseModel",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        chartingProvider: {
          name: "chartingProvider",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        imagingEndpoint: {
          name: "imagingEndpoint",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        imagingUrl: {
          name: "imagingUrl",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        imagingUrlStatus: {
          name: "imagingUrlStatus",
          isArray: false,
          type: {
            enum: "QaUrlStatus",
          },
          isRequired: false,
          attributes: [],
        },
        imagingUrlType: {
          name: "imagingUrlType",
          isArray: false,
          type: {
            enum: "QaUrlType",
          },
          isRequired: false,
          attributes: [],
        },
        imagingBaseModel: {
          name: "imagingBaseModel",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        imagingProvider: {
          name: "imagingProvider",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        reasoningEndpoint: {
          name: "reasoningEndpoint",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        reasoningUrl: {
          name: "reasoningUrl",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        reasoningUrlStatus: {
          name: "reasoningUrlStatus",
          isArray: false,
          type: {
            enum: "QaUrlStatus",
          },
          isRequired: false,
          attributes: [],
        },
        reasoningUrlType: {
          name: "reasoningUrlType",
          isArray: false,
          type: {
            enum: "QaUrlType",
          },
          isRequired: false,
          attributes: [],
        },
        reasoningBaseModel: {
          name: "reasoningBaseModel",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        reasoningProvider: {
          name: "reasoningProvider",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        billingStatus: {
          name: "billingStatus",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        lastBillingDate: {
          name: "lastBillingDate",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        lastConsumptionNotificationDate: {
          name: "lastConsumptionNotificationDate",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        activeUntil: {
          name: "activeUntil",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        paymentFailed: {
          name: "paymentFailed",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        bulkImportAllowed: {
          name: "bulkImportAllowed",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        lastPaymentAttempt: {
          name: "lastPaymentAttempt",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        targetSubscriptionType: {
          name: "targetSubscriptionType",
          isArray: false,
          type: {
            enum: "PlanType",
          },
          isRequired: false,
          attributes: [],
        },
        lastChangeSubscriptionDate: {
          name: "lastChangeSubscriptionDate",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        maxUsers: {
          name: "maxUsers",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        baseModel: {
          name: "baseModel",
          isArray: false,
          type: {
            enum: "BaseModelType",
          },
          isRequired: false,
          attributes: [],
        },
        domain: {
          name: "domain",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        trainingConfig: {
          name: "trainingConfig",
          isArray: false,
          type: {
            nonModel: "TrainingConfig",
          },
          isRequired: false,
          attributes: [],
        },
        parsingConfig: {
          name: "parsingConfig",
          isArray: false,
          type: {
            nonModel: "ParsingConfig",
          },
          isRequired: false,
          attributes: [],
        },
        notificationConfig: {
          name: "notificationConfig",
          isArray: false,
          type: {
            nonModel: "NotificationConfig",
          },
          isRequired: false,
          attributes: [],
        },
        embeddingConfig: {
          name: "embeddingConfig",
          isArray: false,
          type: {
            nonModel: "EmbeddingConfig",
          },
          isRequired: false,
          attributes: [],
        },
        customGenerationConfig: {
          name: "customGenerationConfig",
          isArray: false,
          type: {
            nonModel: "CustomGenerationConfig",
          },
          isRequired: false,
          attributes: [],
        },
        customImageRecognitionModel: {
          name: "customImageRecognitionModel",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        customVectorDB: {
          name: "customVectorDB",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        workspaceAgents: {
          name: "workspaceAgents",
          isArray: true,
          type: {
            model: "Agent",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "workspaceID",
          },
        },
        workspaceCustomPrompts: {
          name: "workspaceCustomPrompts",
          isArray: true,
          type: {
            model: "CustomPrompt",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "workspaceID",
          },
        },
        customQaConfig: {
          name: "customQaConfig",
          isArray: false,
          type: {
            nonModel: "CustomQaConfig",
          },
          isRequired: false,
          attributes: [],
        },
        aiConsumption: {
          name: "aiConsumption",
          isArray: false,
          type: {
            nonModel: "AiWorkspaceConsumption",
          },
          isRequired: false,
          attributes: [],
        },
        disabled: {
          name: "disabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        subscriptionPricingPlan: {
          name: "subscriptionPricingPlan",
          isArray: true,
          type: {
            model: "SubscriptionPricingPlan",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "type",
          },
        },
        folders: {
          name: "folders",
          isArray: true,
          type: {
            model: "Folders",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "workspaceID",
          },
        },
        trainingLogs: {
          name: "trainingLogs",
          isArray: true,
          type: {
            model: "TrainingLog",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "workspaceID",
          },
        },
        entities: {
          name: "entities",
          isArray: true,
          type: {
            model: "EntityAI",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "workspaceID",
          },
        },
        appLogs: {
          name: "appLogs",
          isArray: true,
          type: {
            model: "AppLog",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "workspaceID",
          },
        },
        batchJobs: {
          name: "batchJobs",
          isArray: true,
          type: {
            model: "BatchJobLog",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "workspaceID",
          },
        },
        dictionaryItems: {
          name: "dictionaryItems",
          isArray: true,
          type: {
            model: "DictionaryItem",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "workspaceID",
          },
        },
        requestsLogs: {
          name: "requestsLogs",
          isArray: true,
          type: {
            model: "RequestLog",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "workspaceID",
          },
        },
        communicationServices: {
          name: "communicationServices",
          isArray: true,
          type: {
            model: "CommunicationService",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "workspaceID",
          },
        },
        sites: {
          name: "sites",
          isArray: true,
          type: {
            model: "Site",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "workspaceID",
          },
        },
        users: {
          name: "users",
          isArray: true,
          type: "ID",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        usersAccessLevel: {
          name: "usersAccessLevel",
          isArray: true,
          type: {
            model: "UserWorkspaceType",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "workspaceID",
          },
        },
        workspaceCreationDate: {
          name: "workspaceCreationDate",
          isArray: false,
          type: "AWSTimestamp",
          isRequired: false,
          attributes: [],
        },
        workspaceModelReady: {
          name: "workspaceModelReady",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        enableDeleteFunctionInAgents: {
          name: "enableDeleteFunctionInAgents",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        enableUpdateFunctionInAgents: {
          name: "enableUpdateFunctionInAgents",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        enableCustomFunctionInAgents: {
          name: "enableCustomFunctionInAgents",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        defaultChatAgent: {
          name: "defaultChatAgent",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        defaultBusinessAnalystAgent: {
          name: "defaultBusinessAnalystAgent",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        defaultContentCreatorAgent: {
          name: "defaultContentCreatorAgent",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        isSentimentReviewerEnabled: {
          name: "isSentimentReviewerEnabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isExtendedContentCreatorEnabled: {
          name: "isExtendedContentCreatorEnabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isExtendedBusinessAnalystReportEnabled: {
          name: "isExtendedBusinessAnalystReportEnabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        customIndexedDBendpoint: {
          name: "customIndexedDBendpoint",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdBy: {
          name: "createdBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        updatedBy: {
          name: "updatedBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        federationMetadataUrl: {
          name: "federationMetadataUrl",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        federationMetadataUrlStatus: {
          name: "federationMetadataUrlStatus",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        federationMetadataUrlType: {
          name: "federationMetadataUrlType",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        ssoType: {
          name: "ssoType",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        ssoDomain: {
          name: "ssoDomain",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        ssoProviderName: {
          name: "ssoProviderName",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        isMultiLanguageEnabled: {
          name: "isMultiLanguageEnabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        customChartingConfig: {
          name: "customChartingConfig",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        twilioConfig: {
          name: "twilioConfig",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        emailConfig: {
          name: "emailConfig",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Workspaces",
      attributes: [
        {
          type: "aws_lambda",
          properties: {},
        },
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "UserWorkspaces",
            queryField: "UserWorkspaces",
            fields: ["userID"],
          },
        },
        {
          type: "key",
          properties: {
            name: "workspacesByToken",
            queryField: "workspacesByToken",
            fields: ["workspaceToken"],
          },
        },
        {
          type: "key",
          properties: {
            name: "workspacesBySSOdomain",
            queryField: "workspacesBySSOdomain",
            fields: ["ssoDomain"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    UserWorkspaceType: {
      name: "UserWorkspaceType",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        workspaceID: {
          name: "workspaceID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        userID: {
          name: "userID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        userType: {
          name: "userType",
          isArray: false,
          type: {
            enum: "UserType",
          },
          isRequired: true,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "UserWorkspaceTypes",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "userToWorkspaceMapping",
            queryField: "userToWorkspaceMapping",
            fields: ["workspaceID", "userID"],
          },
        },
        {
          type: "key",
          properties: {
            name: "ByUser",
            queryField: "workspacesByUser",
            fields: ["userID"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Agent: {
      name: "Agent",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        type: {
          name: "type",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        label: {
          name: "label",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        interpolationString: {
          name: "interpolationString",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        emoji: {
          name: "emoji",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        color: {
          name: "color",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        darkColor: {
          name: "darkColor",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        icon: {
          name: "icon",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        largeLogo: {
          name: "largeLogo",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        icoUrl: {
          name: "icoUrl",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        logoBackground: {
          name: "logoBackground",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        darkLogoBackground: {
          name: "darkLogoBackground",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        messageOnLaunch: {
          name: "messageOnLaunch",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        messagesOnLaunch: {
          name: "messagesOnLaunch",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        rotateMessagesOnLaunch: {
          name: "rotateMessagesOnLaunch",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        rotateMessagesOnLaunchInterval: {
          name: "rotateMessagesOnLaunchInterval",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        placeholderMessageOnLoading: {
          name: "placeholderMessageOnLoading",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        customGreetingInstructions: {
          name: "customGreetingInstructions",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        customConclusionInstructions: {
          name: "customConclusionInstructions",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        customChartingInstructions: {
          name: "customChartingInstructions",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        customSummarisationInstructions: {
          name: "customSummarisationInstructions",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        customImagesInstructions: {
          name: "customImagesInstructions",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        customToolingInstructions: {
          name: "customToolingInstructions",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        placeholderInputMessage: {
          name: "placeholderInputMessage",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        openByDefault: {
          name: "openByDefault",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        disclaimer: {
          name: "disclaimer",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        showDocumentsReferences: {
          name: "showDocumentsReferences",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isDefault: {
          name: "isDefault",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        hasMemory: {
          name: "hasMemory",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        hasImages: {
          name: "hasImages",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        promptTopKeywords: {
          name: "promptTopKeywords",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        keyWordsForKnowledgeBase: {
          name: "keyWordsForKnowledgeBase",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        hasTopicsContext: {
          name: "hasTopicsContext",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        hasFunctions: {
          name: "hasFunctions",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        advancedLanguageDetection: {
          name: "advancedLanguageDetection",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        allowedTopics: {
          name: "allowedTopics",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        defaultAnswer: {
          name: "defaultAnswer",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        noKnowledgeDefaultAnswer: {
          name: "noKnowledgeDefaultAnswer",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        quickQuestions: {
          name: "quickQuestions",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        recencyImportance: {
          name: "recencyImportance",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        maxRecencyIncrease: {
          name: "maxRecencyIncrease",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        recencyTopKIncreaseFactor: {
          name: "recencyTopKIncreaseFactor",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        stopSequence: {
          name: "stopSequence",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        examples: {
          name: "examples",
          isArray: true,
          type: {
            nonModel: "QA",
          },
          isRequired: true,
          attributes: [],
          isArrayNullable: true,
        },
        enhancementPassage: {
          name: "enhancementPassage",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        inhibitionPassage: {
          name: "inhibitionPassage",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        pertinencePassage: {
          name: "pertinencePassage",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        goals: {
          name: "goals",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        qaUrl: {
          name: "qaUrl",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        topK: {
          name: "topK",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        mode: {
          name: "mode",
          isArray: false,
          type: {
            enum: "ChatMode",
          },
          isRequired: false,
          attributes: [],
        },
        charting: {
          name: "charting",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        summarisation: {
          name: "summarisation",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        compressor: {
          name: "compressor",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        docTopK: {
          name: "docTopK",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        minRetrievalScore: {
          name: "minRetrievalScore",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        timeSignificance: {
          name: "timeSignificance",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        hasModeration: {
          name: "hasModeration",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        customModerations: {
          name: "customModerations",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        maxHistory: {
          name: "maxHistory",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        maxTokens: {
          name: "maxTokens",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        topicEnhancer: {
          name: "topicEnhancer",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        maxTopics: {
          name: "maxTopics",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        blender: {
          name: "blender",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        temperature: {
          name: "temperature",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        version: {
          name: "version",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        subject: {
          name: "subject",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        workspaceID: {
          name: "workspaceID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        createdBy: {
          name: "createdBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        updatedBy: {
          name: "updatedBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        agentFunctions: {
          name: "agentFunctions",
          isArray: true,
          type: "ID",
          isRequired: true,
          attributes: [],
          isArrayNullable: true,
        },
        isGlobal: {
          name: "isGlobal",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        plainTextOutput: {
          name: "plainTextOutput",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        chainOfThoughts: {
          name: "chainOfThoughts",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        treeOfThoughts: {
          name: "treeOfThoughts",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        extendedOutput: {
          name: "extendedOutput",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        agentType: {
          name: "agentType",
          isArray: false,
          type: {
            enum: "AgentType",
          },
          isRequired: false,
          attributes: [],
        },
        agentsPool: {
          name: "agentsPool",
          isArray: true,
          type: "ID",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        maxPlanningSteps: {
          name: "maxPlanningSteps",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        planningInstructions: {
          name: "planningInstructions",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        plannerExecutionAttempts: {
          name: "plannerExecutionAttempts",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        plannerRequiresApprovalForPlanExecution: {
          name: "plannerRequiresApprovalForPlanExecution",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        allowReplanning: {
          name: "allowReplanning",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        plannerProceedsWhenImprovementsRequired: {
          name: "plannerProceedsWhenImprovementsRequired",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        reviewInstructions: {
          name: "reviewInstructions",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        showReferencesToInternalDocs: {
          name: "showReferencesToInternalDocs",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        preventWidgetUsage: {
          name: "preventWidgetUsage",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        allowFeedback: {
          name: "allowFeedback",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        generateExtendedReport: {
          name: "generateExtendedReport",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        showTimeForResponse: {
          name: "showTimeForResponse",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        showDetectedLanguage: {
          name: "showDetectedLanguage",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isMultiLanguageEnabled: {
          name: "isMultiLanguageEnabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        hasMath: {
          name: "hasMath",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        hasCode: {
          name: "hasCode",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        hasNER: {
          name: "hasNER",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        restrictedAccess: {
          name: "restrictedAccess",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        restrictedAccessUsers: {
          name: "restrictedAccessUsers",
          isArray: true,
          type: "ID",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        splashTitle: {
          name: "splashTitle",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        showSplashWhenEmpty: {
          name: "showSplashWhenEmpty",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        llmEndpoint: {
          name: "llmEndpoint",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        llmUrl: {
          name: "llmUrl",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        llmUrlStatus: {
          name: "llmUrlStatus",
          isArray: false,
          type: {
            enum: "QaUrlStatus",
          },
          isRequired: false,
          attributes: [],
        },
        llmUrlType: {
          name: "llmUrlType",
          isArray: false,
          type: {
            enum: "QaUrlType",
          },
          isRequired: false,
          attributes: [],
        },
        llmBaseModel: {
          name: "llmBaseModel",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        llmProvider: {
          name: "llmProvider",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        functionCallingProvider: {
          name: "functionCallingProvider",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        functionCallingModel: {
          name: "functionCallingModel",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        showAgentName: {
          name: "showAgentName",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        allowImagesUpload: {
          name: "allowImagesUpload",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        allowVideosUpload: {
          name: "allowVideosUpload",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        allowAudiosUpload: {
          name: "allowAudiosUpload",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        allowDocsUpload: {
          name: "allowDocsUpload",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        allowImagesGeneration: {
          name: "allowImagesGeneration",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        baseImageGenerationModel: {
          name: "baseImageGenerationModel",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        maxImagesGenerated: {
          name: "maxImagesGenerated",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        advancedImageGeneration: {
          name: "advancedImageGeneration",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        allowVideosGeneration: {
          name: "allowVideosGeneration",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        allowAudiosGeneration: {
          name: "allowAudiosGeneration",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        voiceFileKey: {
          name: "voiceFileKey",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        allowInternetSearch: {
          name: "allowInternetSearch",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        bannedDomains: {
          name: "bannedDomains",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        maxUrlsForInternetSearch: {
          name: "maxUrlsForInternetSearch",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        allowDeepInternetSearch: {
          name: "allowDeepInternetSearch",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        internetSearchModes: {
          name: "internetSearchModes",
          isArray: true,
          type: {
            enum: "InternetSearchMode",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        internetSearchLocation: {
          name: "internetSearchLocation",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        autoVoiceMode: {
          name: "autoVoiceMode",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        keywordsWeightInRetrieval: {
          name: "keywordsWeightInRetrieval",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        customCodeExecutionEnvironments: {
          name: "customCodeExecutionEnvironments",
          isArray: true,
          type: "ID",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        phoneNumberAsSender: {
          name: "phoneNumberAsSender",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        whatsappNumberAsSender: {
          name: "whatsappNumberAsSender",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        emailAsSender: {
          name: "emailAsSender",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        communicationServices: {
          name: "communicationServices",
          isArray: true,
          type: "ID",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        channelCallbackDelay: {
          name: "channelCallbackDelay",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Agents",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "WorkspaceAgents",
            queryField: "agentsByWorkspace",
            fields: ["workspaceID"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    AgentFunction: {
      name: "AgentFunction",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        parameters: {
          name: "parameters",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        model: {
          name: "model",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        url: {
          name: "url",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        requestType: {
          name: "requestType",
          isArray: false,
          type: {
            enum: "FunctionRequestType",
          },
          isRequired: false,
          attributes: [],
        },
        authorisationType: {
          name: "authorisationType",
          isArray: false,
          type: {
            enum: "AuthorisationType",
          },
          isRequired: false,
          attributes: [],
        },
        authorisationKey: {
          name: "authorisationKey",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        authorisationID: {
          name: "authorisationID",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        paramsAsPath: {
          name: "paramsAsPath",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        scope: {
          name: "scope",
          isArray: false,
          type: {
            enum: "FunctionScope",
          },
          isRequired: false,
          attributes: [],
        },
        jsonPathExtractor: {
          name: "jsonPathExtractor",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        headers: {
          name: "headers",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        staticArgs: {
          name: "staticArgs",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        customExecutionCode: {
          name: "customExecutionCode",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        isCallbackFn: {
          name: "isCallbackFn",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isDatabaseScript: {
          name: "isDatabaseScript",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isHtmlFn: {
          name: "isHtmlFn",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isChatFn: {
          name: "isChatFn",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isHandOffFn: {
          name: "isHandOffFn",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        onFnCall: {
          name: "onFnCall",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        handOffAgent: {
          name: "handOffAgent",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        handOffToOriginalAgentOnCompletion: {
          name: "handOffToOriginalAgentOnCompletion",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        handOffToOriginalAgentOnFailure: {
          name: "handOffToOriginalAgentOnFailure",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        handOffAgentOnCompletion: {
          name: "handOffAgentOnCompletion",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        handOffAgentOnFailure: {
          name: "handOffAgentOnFailure",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        chatScript: {
          name: "chatScript",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        chatAction: {
          name: "chatAction",
          isArray: false,
          type: {
            enum: "AgentFunctionChatAction",
          },
          isRequired: false,
          attributes: [],
        },
        htmlUrl: {
          name: "htmlUrl",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        htmlScript: {
          name: "htmlScript",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        isHtmlRedirectFn: {
          name: "isHtmlRedirectFn",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        htmlFormOnSuccessfulSubmitMessage: {
          name: "htmlFormOnSuccessfulSubmitMessage",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        htmlFormOnFailedSubmitMessage: {
          name: "htmlFormOnFailedSubmitMessage",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        dbSchemaMetadata: {
          name: "dbSchemaMetadata",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        preExecutionScript: {
          name: "preExecutionScript",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        postExecutionScript: {
          name: "postExecutionScript",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        dbScript: {
          name: "dbScript",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        dbConnectionID: {
          name: "dbConnectionID",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        preExecutionFnID: {
          name: "preExecutionFnID",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        preExecutionMapping: {
          name: "preExecutionMapping",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        OAuthConnectionID: {
          name: "OAuthConnectionID",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        workspaceID: {
          name: "workspaceID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "AgentFunctions",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "AgentFunctionsByWorkspace",
            queryField: "functionsByWorkspace",
            fields: ["workspaceID"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Authorisation: {
      name: "Authorisation",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        tokenSecret: {
          name: "tokenSecret",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        scope: {
          name: "scope",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        grantType: {
          name: "grantType",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        clientId: {
          name: "clientId",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        clientSecret: {
          name: "clientSecret",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        authorizationBaseUrl: {
          name: "authorizationBaseUrl",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        staticArgs: {
          name: "staticArgs",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        tokenBaseUrl: {
          name: "tokenBaseUrl",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        type: {
          name: "type",
          isArray: false,
          type: {
            enum: "AuthorisationType",
          },
          isRequired: true,
          attributes: [],
        },
        workspaceID: {
          name: "workspaceID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        createdBy: {
          name: "createdBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        updatedBy: {
          name: "updatedBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Authorisations",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "AuthorisationsByWorkspace",
            queryField: "authorisationsByWorkspace",
            fields: ["workspaceID"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Hosting: {
      name: "Hosting",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        tokenSecret: {
          name: "tokenSecret",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        endpoint: {
          name: "endpoint",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        modelName: {
          name: "modelName",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        baseModel: {
          name: "baseModel",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        customModel: {
          name: "customModel",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        apiVersion: {
          name: "apiVersion",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        type: {
          name: "type",
          isArray: false,
          type: {
            enum: "HostingType",
          },
          isRequired: true,
          attributes: [],
        },
        workspaceID: {
          name: "workspaceID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        createdBy: {
          name: "createdBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        updatedBy: {
          name: "updatedBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Hostings",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "HostingsByWorkspace",
            queryField: "HostingsByWorkspace",
            fields: ["workspaceID"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    DatabaseConnection: {
      name: "DatabaseConnection",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        type: {
          name: "type",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        host: {
          name: "host",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        port: {
          name: "port",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        username: {
          name: "username",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        passwordSecret: {
          name: "passwordSecret",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        database: {
          name: "database",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        schema: {
          name: "schema",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        ssl: {
          name: "ssl",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        ssh: {
          name: "ssh",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        sshHost: {
          name: "sshHost",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        sshPort: {
          name: "sshPort",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        sshUsername: {
          name: "sshUsername",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        sshPasswordSecret: {
          name: "sshPasswordSecret",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        workspaceID: {
          name: "workspaceID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        createdBy: {
          name: "createdBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        updatedBy: {
          name: "updatedBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "DatabaseConnections",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "DatabaseConnectionsByWorkspace",
            queryField: "connectionsByWorkspace",
            fields: ["workspaceID"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Site: {
      name: "Site",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        url: {
          name: "url",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        workspaceID: {
          name: "workspaceID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        topics: {
          name: "topics",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        status: {
          name: "status",
          isArray: false,
          type: {
            enum: "SiteStatus",
          },
          isRequired: false,
          attributes: [],
        },
        lastValidationDate: {
          name: "lastValidationDate",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
        },
        lastValidationRequested: {
          name: "lastValidationRequested",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
        },
        validationToken: {
          name: "validationToken",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        allowedPaths: {
          name: "allowedPaths",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        siteTree: {
          name: "siteTree",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        lastScraped: {
          name: "lastScraped",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
        },
        skipOwnerValidation: {
          name: "skipOwnerValidation",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        useProxy: {
          name: "useProxy",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        completion_percentage: {
          name: "completion_percentage",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        extractImages: {
          name: "extractImages",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        freeRoaming: {
          name: "freeRoaming",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        scrapingCycle: {
          name: "scrapingCycle",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        imgRetrievalPrompt: {
          name: "imgRetrievalPrompt",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        metadata: {
          name: "metadata",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        createdBy: {
          name: "createdBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        updatedBy: {
          name: "updatedBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Sites",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "WorkspaceSites",
            queryField: "sitesByWorkspace",
            fields: ["workspaceID"],
          },
        },
        {
          type: "key",
          properties: {
            name: "ByValidationToken",
            queryField: "siteByValidationToken",
            fields: ["validationToken"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    EntityAI: {
      name: "EntityAI",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        label: {
          name: "label",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        type: {
          name: "type",
          isArray: false,
          type: {
            enum: "EntityAiType",
          },
          isRequired: true,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        emoji: {
          name: "emoji",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        parentEntity: {
          name: "parentEntity",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        backgroundColor: {
          name: "backgroundColor",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        workspaceID: {
          name: "workspaceID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        createdBy: {
          name: "createdBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        updatedBy: {
          name: "updatedBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "EntityAIS",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "WorkspaceEntities",
            queryField: "entitiesByWorkspace",
            fields: ["workspaceID"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    TrainingLog: {
      name: "TrainingLog",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        status: {
          name: "status",
          isArray: false,
          type: {
            enum: "TrainingStatus",
          },
          isRequired: true,
          attributes: [],
        },
        trainingIterations: {
          name: "trainingIterations",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        lastActivation: {
          name: "lastActivation",
          isArray: false,
          type: "AWSTimestamp",
          isRequired: false,
          attributes: [],
        },
        duration: {
          name: "duration",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        trainingMeta: {
          name: "trainingMeta",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        trainingConfig: {
          name: "trainingConfig",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        workspaceID: {
          name: "workspaceID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        type: {
          name: "type",
          isArray: false,
          type: {
            enum: "TrainingLogType",
          },
          isRequired: false,
          attributes: [],
        },
        createdBy: {
          name: "createdBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        updatedBy: {
          name: "updatedBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "TrainingLogs",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "WorkspaceTrainingLogs",
            fields: ["workspaceID"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    BatchJobLog: {
      name: "BatchJobLog",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        status: {
          name: "status",
          isArray: false,
          type: {
            enum: "BatchJobStatus",
          },
          isRequired: true,
          attributes: [],
        },
        duration: {
          name: "duration",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        batchMeta: {
          name: "batchMeta",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        batchConfig: {
          name: "batchConfig",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        batchOutput: {
          name: "batchOutput",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        workspaceID: {
          name: "workspaceID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        type: {
          name: "type",
          isArray: false,
          type: {
            enum: "BatchLogType",
          },
          isRequired: true,
          attributes: [],
        },
        createdBy: {
          name: "createdBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        updatedBy: {
          name: "updatedBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "BatchJobLogs",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "WorkspaceBatchJobLogs",
            fields: ["workspaceID"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    AppLog: {
      name: "AppLog",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        type: {
          name: "type",
          isArray: false,
          type: {
            enum: "LogType",
          },
          isRequired: true,
          attributes: [],
        },
        filename: {
          name: "filename",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        event: {
          name: "event",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        status: {
          name: "status",
          isArray: false,
          type: {
            enum: "LogStatus",
          },
          isRequired: true,
          attributes: [],
        },
        workspaceID: {
          name: "workspaceID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        createdBy: {
          name: "createdBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        updatedBy: {
          name: "updatedBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "AppLogs",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "WorkspaceAppLogs",
            fields: ["workspaceID"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    RequestLog: {
      name: "RequestLog",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        srtKey: {
          name: "srtKey",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        type: {
          name: "type",
          isArray: false,
          type: {
            enum: "RequestType",
          },
          isRequired: true,
          attributes: [],
        },
        status: {
          name: "status",
          isArray: false,
          type: {
            enum: "RequestStatus",
          },
          isRequired: true,
          attributes: [],
        },
        words: {
          name: "words",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        tokens: {
          name: "tokens",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        sentences: {
          name: "sentences",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        workspaceID: {
          name: "workspaceID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        createdBy: {
          name: "createdBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        updatedBy: {
          name: "updatedBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "RequestLogs",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "requestsByMonthAndWorkspace",
            queryField: "requestsByMonthAndWorkspace",
            fields: ["srtKey"],
          },
        },
        {
          type: "key",
          properties: {
            name: "WorkspaceRequestLogs",
            fields: ["workspaceID"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    OutputStream: {
      name: "OutputStream",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        content: {
          name: "content",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        t_content: {
          name: "t_content",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        prompt: {
          name: "prompt",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        t_prompt: {
          name: "t_prompt",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        workspaceID: {
          name: "workspaceID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        type: {
          name: "type",
          isArray: false,
          type: {
            enum: "RequestType",
          },
          isRequired: false,
          attributes: [],
        },
        status: {
          name: "status",
          isArray: false,
          type: {
            enum: "RequestStatus",
          },
          isRequired: false,
          attributes: [],
        },
        humanFeedback: {
          name: "humanFeedback",
          isArray: false,
          type: {
            enum: "HumanFeedback",
          },
          isRequired: false,
          attributes: [],
        },
        humanFeedbackComment: {
          name: "humanFeedbackComment",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        humanFeedbackType: {
          name: "humanFeedbackType",
          isArray: false,
          type: {
            enum: "HumanFeedbackType",
          },
          isRequired: false,
          attributes: [],
        },
        humanFeedbackSubType: {
          name: "humanFeedbackSubType",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        humanFeedbackProvidedBy: {
          name: "humanFeedbackProvidedBy",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        adminResolution: {
          name: "adminResolution",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        adminChecked: {
          name: "adminChecked",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        autoTune: {
          name: "autoTune",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        scoring: {
          name: "scoring",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        metadata: {
          name: "metadata",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        createdBy: {
          name: "createdBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        updatedBy: {
          name: "updatedBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "OutputStreams",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "WorkspaceOutputStreams",
            queryField: "streamsByWorkspace",
            fields: ["workspaceID", "humanFeedback"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Folders: {
      name: "Folders",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        emoji: {
          name: "emoji",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        status: {
          name: "status",
          isArray: false,
          type: {
            enum: "FolderStatus",
          },
          isRequired: false,
          attributes: [],
        },
        parent: {
          name: "parent",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        workspaceID: {
          name: "workspaceID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        createdBy: {
          name: "createdBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        updatedBy: {
          name: "updatedBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Folders",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "WorkspaceFolders",
            fields: ["workspaceID"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    DictionaryItem: {
      name: "DictionaryItem",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        sourceLanguage: {
          name: "sourceLanguage",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        targetLanguage: {
          name: "targetLanguage",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        sourceText: {
          name: "sourceText",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        targetText: {
          name: "targetText",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        workspaceID: {
          name: "workspaceID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        pos: {
          name: "pos",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdBy: {
          name: "createdBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        updatedBy: {
          name: "updatedBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "DictionaryItems",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "WorkspaceDictionaryItems",
            fields: ["workspaceID"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Chat: {
      name: "Chat",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        status: {
          name: "status",
          isArray: false,
          type: {
            nonModel: "ChatStatus",
          },
          isRequired: false,
          attributes: [],
        },
        workspaceID: {
          name: "workspaceID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        creationTime: {
          name: "creationTime",
          isArray: false,
          type: "AWSTimestamp",
          isRequired: false,
          attributes: [],
        },
        createdBy: {
          name: "createdBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        updatedBy: {
          name: "updatedBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        visibility: {
          name: "visibility",
          isArray: false,
          type: {
            enum: "ChatVisibility",
          },
          isRequired: false,
          attributes: [],
        },
        isAIReplying: {
          name: "isAIReplying",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        activeMessageForAI: {
          name: "activeMessageForAI",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        messages: {
          name: "messages",
          isArray: true,
          type: {
            model: "ChatMessage",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "chatID",
          },
        },
        chatHistory: {
          name: "chatHistory",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        topics: {
          name: "topics",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        documentId: {
          name: "documentId",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        primaryRole: {
          name: "primaryRole",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        primaryAgent: {
          name: "primaryAgent",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        plannerAgent: {
          name: "plannerAgent",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        forcePlannerStop: {
          name: "forcePlannerStop",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        allowMultipleRoles: {
          name: "allowMultipleRoles",
          isArray: false,
          type: "Boolean",
          isRequired: true,
          attributes: [],
        },
        roles: {
          name: "roles",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        language: {
          name: "language",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        summary: {
          name: "summary",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        caseId: {
          name: "caseId",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        customerId: {
          name: "customerId",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        caseInfo: {
          name: "caseInfo",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        customerInfo: {
          name: "customerInfo",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        metadata: {
          name: "metadata",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        channelSettings: {
          name: "channelSettings",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        externalParticipantId: {
          name: "externalParticipantId",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Chats",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "WorkspaceChats",
            queryField: "chatsByWorkspace",
            fields: ["workspaceID", "creationTime"],
          },
        },
        {
          type: "key",
          properties: {
            name: "chatByExternalParticipantId",
            queryField: "chatByExternalParticipantId",
            fields: ["externalParticipantId"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    CustomPrompt: {
      name: "CustomPrompt",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        type: {
          name: "type",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        scope: {
          name: "scope",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        style: {
          name: "style",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        domain: {
          name: "domain",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        label: {
          name: "label",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        promptLength: {
          name: "promptLength",
          isArray: false,
          type: "Float",
          isRequired: true,
          attributes: [],
        },
        promptPlaceholder: {
          name: "promptPlaceholder",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        interpolationString: {
          name: "interpolationString",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        availableToAgents: {
          name: "availableToAgents",
          isArray: true,
          type: "ID",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        workspaceID: {
          name: "workspaceID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        createdBy: {
          name: "createdBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        updatedBy: {
          name: "updatedBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "CustomPrompts",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "WorkspaceCustomPrompts",
            queryField: "customPromptsByWorkspace",
            fields: ["workspaceID"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    CustomCodeExecutionEnvironment: {
      name: "CustomCodeExecutionEnvironment",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        functionName: {
          name: "functionName",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        availableLibraries: {
          name: "availableLibraries",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        customExecutionInstructions: {
          name: "customExecutionInstructions",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        customExecutionCode: {
          name: "customExecutionCode",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        allowExternalAPI: {
          name: "allowExternalAPI",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        workspaceID: {
          name: "workspaceID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        createdBy: {
          name: "createdBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        updatedBy: {
          name: "updatedBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "CustomCodeExecutionEnvironments",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "WorkspaceCustomCodeExecutionEnvironments",
            queryField: "customCodeExecutionEnvironmentsByWorkspace",
            fields: ["workspaceID"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    ChatMessage: {
      name: "ChatMessage",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        role: {
          name: "role",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        chatID: {
          name: "chatID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        text: {
          name: "text",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        t_text: {
          name: "t_text",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        t_text_summary: {
          name: "t_text_summary",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        creationTime: {
          name: "creationTime",
          isArray: false,
          type: "AWSTimestamp",
          isRequired: false,
          attributes: [],
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        scoring: {
          name: "scoring",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        embeddingId: {
          name: "embeddingId",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        humanFeedback: {
          name: "humanFeedback",
          isArray: false,
          type: {
            enum: "HumanFeedback",
          },
          isRequired: false,
          attributes: [],
        },
        humanFeedbackComment: {
          name: "humanFeedbackComment",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        humanFeedbackType: {
          name: "humanFeedbackType",
          isArray: false,
          type: {
            enum: "HumanFeedbackType",
          },
          isRequired: false,
          attributes: [],
        },
        humanFeedbackSubType: {
          name: "humanFeedbackSubType",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        humanFeedbackProvidedBy: {
          name: "humanFeedbackProvidedBy",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        adminResolution: {
          name: "adminResolution",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        prevMessage: {
          name: "prevMessage",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        prevMessagePosEq: {
          name: "prevMessagePosEq",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        prevMessageNegEq: {
          name: "prevMessageNegEq",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        correctContext: {
          name: "correctContext",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        correctAnswer: {
          name: "correctAnswer",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        adminChecked: {
          name: "adminChecked",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        autoTune: {
          name: "autoTune",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        userID: {
          name: "userID",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        agentID: {
          name: "agentID",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        humanFeedbackForInstruction: {
          name: "humanFeedbackForInstruction",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        images: {
          name: "images",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        audios: {
          name: "audios",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        videos: {
          name: "videos",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        files: {
          name: "files",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        imagesText: {
          name: "imagesText",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        audiosText: {
          name: "audiosText",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        videosText: {
          name: "videosText",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        filesText: {
          name: "filesText",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        isAI: {
          name: "isAI",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        messageType: {
          name: "messageType",
          isArray: false,
          type: {
            enum: "MessageType",
          },
          isRequired: false,
          attributes: [],
        },
        status: {
          name: "status",
          isArray: false,
          type: {
            enum: "RequestStatus",
          },
          isRequired: false,
          attributes: [],
        },
        metadata: {
          name: "metadata",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        callbackMetadata: {
          name: "callbackMetadata",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        workspaceID: {
          name: "workspaceID",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: "ChatMessages",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "ChatMessages",
            queryField: "messagesByChat",
            fields: ["chatID", "creationTime"],
          },
        },
        {
          type: "key",
          properties: {
            name: "AgentChatMessages",
            queryField: "messagesByAgent",
            fields: ["agentID", "updatedAt"],
          },
        },
        {
          type: "key",
          properties: {
            name: "WorkspaceChatMessages",
            queryField: "messagesByWorkspace",
            fields: ["workspaceID", "humanFeedback"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    CommunicationService: {
      name: "CommunicationService",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        workspaceID: {
          name: "workspaceID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        channel: {
          name: "channel",
          isArray: false,
          type: {
            enum: "CommunicationChannel",
          },
          isRequired: true,
          attributes: [],
        },
        provider: {
          name: "provider",
          isArray: false,
          type: {
            enum: "CommunicationProvider",
          },
          isRequired: true,
          attributes: [],
        },
        senderid: {
          name: "senderid",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        fromNumber: {
          name: "fromNumber",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        tokenSecret: {
          name: "tokenSecret",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        isActive: {
          name: "isActive",
          isArray: false,
          type: "Boolean",
          isRequired: true,
          attributes: [],
        },
        config: {
          name: "config",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        createdBy: {
          name: "createdBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        updatedBy: {
          name: "updatedBy",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "CommunicationServices",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "WorkspaceCommunicationServices",
            queryField: "CommunicationServicesByWorkspace",
            fields: ["workspaceID"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    SubscriptionPricingPlan: {
      name: "SubscriptionPricingPlan",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        type: {
          name: "type",
          isArray: false,
          type: {
            enum: "PlanType",
          },
          isRequired: true,
          attributes: [],
        },
        subscriptionStatus: {
          name: "subscriptionStatus",
          isArray: false,
          type: {
            enum: "SubscriptionStatus",
          },
          isRequired: true,
          attributes: [],
        },
        isPredictionOnDemandGlobal: {
          name: "isPredictionOnDemandGlobal",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isTrainingOnDemandGlobal: {
          name: "isTrainingOnDemandGlobal",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        predictionRequestsGlobal: {
          name: "predictionRequestsGlobal",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        batchRequestsGlobal: {
          name: "batchRequestsGlobal",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        trainingMinutesGlobal: {
          name: "trainingMinutesGlobal",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        baseModelGN: {
          name: "baseModelGN",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        baseModelQA: {
          name: "baseModelQA",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        baseModelNLP: {
          name: "baseModelNLP",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        maxUsers: {
          name: "maxUsers",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        agcEnabled: {
          name: "agcEnabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isDownloadModelEnabled: {
          name: "isDownloadModelEnabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isDownloadDataEnabled: {
          name: "isDownloadDataEnabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isAPIAccessEnabled: {
          name: "isAPIAccessEnabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isBatchJobsEnabled: {
          name: "isBatchJobsEnabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isSentimentReviewerEnabled: {
          name: "isSentimentReviewerEnabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isBusinessAnalystEnabled: {
          name: "isBusinessAnalystEnabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isContentCreatorEnabled: {
          name: "isContentCreatorEnabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isPriorityTrainingEnabled: {
          name: "isPriorityTrainingEnabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isPredictionGPUAccelerationEnabledGlobal: {
          name: "isPredictionGPUAccelerationEnabledGlobal",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isPredictionGPUAccelerationEnabledRC: {
          name: "isPredictionGPUAccelerationEnabledRC",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isPredictionGPUAccelerationEnabledNLP: {
          name: "isPredictionGPUAccelerationEnabledNLP",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        aiMaxConsumption: {
          name: "aiMaxConsumption",
          isArray: false,
          type: {
            nonModel: "AiWorkspaceConsumption",
          },
          isRequired: false,
          attributes: [],
        },
        maxMrcDocs: {
          name: "maxMrcDocs",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        accuracyModelEnabled: {
          name: "accuracyModelEnabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isTrainingNLPEnabled: {
          name: "isTrainingNLPEnabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isTrainingQAEnabled: {
          name: "isTrainingQAEnabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isTrainingGNEnabled: {
          name: "isTrainingGNEnabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isLongTermMemoryEnabled: {
          name: "isLongTermMemoryEnabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isFunctionsEnabled: {
          name: "isFunctionsEnabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isDBFunctionsEnabled: {
          name: "isDBFunctionsEnabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isHtmlFunctionsEnabled: {
          name: "isHtmlFunctionsEnabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isSitesBindingEnabled: {
          name: "isSitesBindingEnabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isAgentsSecurityEnabled: {
          name: "isAgentsSecurityEnabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isWidgetChatsHistoryEnabled: {
          name: "isWidgetChatsHistoryEnabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "SubscriptionPricingPlans",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "WorkspaceSubscription",
            fields: ["type"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    SubscriptionBaseConfig: {
      name: "SubscriptionBaseConfig",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        type: {
          name: "type",
          isArray: false,
          type: {
            enum: "PlanType",
          },
          isRequired: true,
          attributes: [],
        },
        planConfig: {
          name: "planConfig",
          isArray: false,
          type: {
            nonModel: "ConfigMapByPlan",
          },
          isRequired: true,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "SubscriptionBaseConfigs",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "WorkspaceSubscriptionBaseConfig",
            fields: ["type"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    GenerationConfig: {
      name: "GenerationConfig",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        styles: {
          name: "styles",
          isArray: true,
          type: {
            enum: "GenerationStyles",
          },
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
        },
        domains: {
          name: "domains",
          isArray: true,
          type: {
            enum: "GenerationDomains",
          },
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
        },
        scopes: {
          name: "scopes",
          isArray: true,
          type: {
            enum: "GenerationScopes",
          },
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
        },
        prompts: {
          name: "prompts",
          isArray: true,
          type: {
            nonModel: "Prompt",
          },
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "GenerationConfigs",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    ArenaBattle: {
      name: "ArenaBattle",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        firstAgent: {
          name: "firstAgent",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        secondAgent: {
          name: "secondAgent",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        firstAgentChatID: {
          name: "firstAgentChatID",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        secondAgentChatID: {
          name: "secondAgentChatID",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        winnerID: {
          name: "winnerID",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        creationTime: {
          name: "creationTime",
          isArray: false,
          type: "AWSTimestamp",
          isRequired: false,
          attributes: [],
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        workspaceID: {
          name: "workspaceID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: "ArenaBattles",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "ArenaBattlesByWorkspace",
            queryField: "ArenaBattlesByWorkspace",
            fields: ["workspaceID"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
  },
  enums: {
    UserType: {
      name: "UserType",
      values: [
        "Root",
        "SuperAdmin",
        "ContentAdmin",
        "BillingAdmin",
        "AIEngineer",
        "User",
      ],
    },
    AuthorisationType: {
      name: "AuthorisationType",
      values: ["bearer", "apikey", "oauth", "none"],
    },
    HostingType: {
      name: "HostingType",
      values: [
        "azure_openai",
        "aws",
        "local",
        "google",
        "anthropic",
        "together",
        "tf",
        "replicate",
        "azure",
        "openai",
        "groq",
      ],
    },
    FunctionRequestType: {
      name: "FunctionRequestType",
      values: ["get", "post", "put", "delete", "custom"],
    },
    RequestType: {
      name: "RequestType",
      values: [
        "translator",
        "answerer",
        "parser",
        "tokenizer",
        "embedder",
        "detector",
        "questioner",
        "generator",
        "chatter",
      ],
    },
    RequestStatus: {
      name: "RequestStatus",
      values: [
        "success",
        "failed",
        "timeout",
        "fulfilled",
        "pending",
        "inProgress",
        "cancelled",
      ],
    },
    AgentType: {
      name: "AgentType",
      values: ["Chatbot", "BusinessAnalyst", "ContentCreator", "Default"],
    },
    SiteStatus: {
      name: "SiteStatus",
      values: [
        "noDomain",
        "noAnswer",
        "active",
        "syncing",
        "inactive",
        "invalidToken",
        "failed",
        "pending",
        "inProgress",
        "validationTokenCreationError",
        "readyForValidation",
        "tokenRequested",
      ],
    },
    FunctionScope: {
      name: "FunctionScope",
      values: [
        "customerAuthentication",
        "customer",
        "caseAuthentication",
        "case",
      ],
    },
    ChatVisibility: {
      name: "ChatVisibility",
      values: ["public", "private", "widget"],
    },
    HumanFeedbackType: {
      name: "HumanFeedbackType",
      values: [
        "multilanguage",
        "documentation",
        "reasoning",
        "images",
        "charting",
        "api",
        "code",
        "math",
        "moderation",
      ],
    },
    MultiLanguageHumanFeedbackSubType: {
      name: "MultiLanguageHumanFeedbackSubType",
      values: [
        "translation",
        "languageDetection",
        "languageDetectionAndTranslation",
      ],
    },
    DocumentationHumanFeedbackSubType: {
      name: "DocumentationHumanFeedbackSubType",
      values: [
        "OutdatedDocumentation",
        "MissingDocumentation",
        "IncorrectDocumentation",
      ],
    },
    ReasoningHumanFeedbackSubType: {
      name: "ReasoningHumanFeedbackSubType",
      values: ["IncorrectAnswer", "OutOfContextAnswer"],
    },
    ImagesHumanFeedbackSubType: {
      name: "ImagesHumanFeedbackSubType",
      values: ["IncorrectImage", "MissingImage"],
    },
    ChartingHumanFeedbackSubType: {
      name: "ChartingHumanFeedbackSubType",
      values: ["IncorrectChart", "MissingChart"],
    },
    ModerationHumanFeedbackSubType: {
      name: "ModerationHumanFeedbackSubType",
      values: ["IncorrectModeration", "MissingModeration"],
    },
    AgentFunctionChatAction: {
      name: "AgentFunctionChatAction",
      values: [
        "suggestion",
        "nextQuestion",
        "humanSupport",
        "redirection",
        "script",
      ],
    },
    CommunicationChannel: {
      name: "CommunicationChannel",
      values: ["email", "sms", "whatsapp", "linkedin"],
    },
    CommunicationProvider: {
      name: "CommunicationProvider",
      values: [
        "sendgrid",
        "amazon_ses",
        "mailgun",
        "postmark",
        "mailjet",
        "twilio",
        "whatsapp",
        "sms_magic",
        "sms_magic_sf",
        "linkedin",
      ],
    },
    InternetSearchMode: {
      name: "InternetSearchMode",
      values: [
        "search",
        "news",
        "images",
        "videos",
        "maps",
        "places",
        "shopping",
      ],
    },
    ChunkingStrategy: {
      name: "ChunkingStrategy",
      values: ["keywords", "summary"],
    },
    ExtractionStrategy: {
      name: "ExtractionStrategy",
      values: ["safe", "brute"],
    },
    GenerationScopes: {
      name: "GenerationScopes",
      values: [
        "generation",
        "tweet",
        "post",
        "title",
        "email",
        "paraphrase",
        "paraphraseLiteral",
        "summary",
        "simplify",
        "custom",
      ],
    },
    GenerationStyles: {
      name: "GenerationStyles",
      values: [
        "default",
        "short",
        "long",
        "funny",
        "informal",
        "formal",
        "interview",
        "academic",
        "business",
        "creative",
        "scientific",
        "poetry",
        "news",
      ],
    },
    ChatMode: {
      name: "ChatMode",
      values: [
        "speed",
        "accuracy",
        "custom",
        "retriever",
        "coder",
        "mathematician",
        "chatter",
        "planner",
      ],
    },
    GenerationMode: {
      name: "GenerationMode",
      values: ["speed", "accuracy", "custom"],
    },
    HumanFeedback: {
      name: "HumanFeedback",
      values: ["positive", "negative", "neutral"],
    },
    MessageType: {
      name: "MessageType",
      values: ["system", "handoff", "notification"],
    },
    GenerationDomains: {
      name: "GenerationDomains",
      values: [
        "editorial",
        "customerService",
        "sales",
        "marketing",
        "hr",
        "finance",
        "legal",
        "medical",
        "science",
        "technology",
        "default",
      ],
    },
    Visibility: {
      name: "Visibility",
      values: ["private", "public", "shared"],
    },
    BaseModelType: {
      name: "BaseModelType",
      values: ["productivity", "sentiment", "none"],
    },
    EntityAiType: {
      name: "EntityAiType",
      values: ["ner", "intent", "topic"],
    },
    FolderStatus: {
      name: "FolderStatus",
      values: ["active", "inactive"],
    },
    WorkspaceAiType: {
      name: "WorkspaceAiType",
      values: ["hierarchical", "flat"],
    },
    BatchLogType: {
      name: "BatchLogType",
      values: ["nlp", "rc", "gn"],
    },
    SubscriptionStatus: {
      name: "SubscriptionStatus",
      values: ["active", "inactive"],
    },
    TrainingStatus: {
      name: "TrainingStatus",
      values: [
        "dispatched",
        "inProgress",
        "completed",
        "inError",
        "cancelled",
        "cancellationRequested",
        "training",
        "swapping",
        "swapModelFailed",
        "swapModelRequested",
        "datasetReady",
        "modelReady",
        "fineTuningRequested",
      ],
    },
    BatchJobStatus: {
      name: "BatchJobStatus",
      values: [
        "dispatched",
        "inProgress",
        "completed",
        "inError",
        "cancelled",
        "cancellationRequested",
      ],
    },
    QaUrlStatus: {
      name: "QaUrlStatus",
      values: ["ready", "notReady", "dismissed", "unknown", "swapping"],
    },
    PlanType: {
      name: "PlanType",
      values: ["base", "pro", "enterprise"],
    },
    LogType: {
      name: "LogType",
      values: ["import", "export", "entityDeletion", "documentImport"],
    },
    LogStatus: {
      name: "LogStatus",
      values: ["dispatched", "inProgress", "completed", "inError"],
    },
    TrainingLogType: {
      name: "TrainingLogType",
      values: [
        "nlpTraining",
        "readComprehensionTraining",
        "generativeTraining",
        "conversationalTraining",
      ],
    },
    QaUrlType: {
      name: "QaUrlType",
      values: ["serverless", "provisioned", "elastic"],
    },
  },
  nonModels: {
    ChatStatus: {
      name: "ChatStatus",
      fields: {
        status: {
          name: "status",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        lastUpdated: {
          name: "lastUpdated",
          isArray: false,
          type: "AWSTimestamp",
          isRequired: false,
          attributes: [],
        },
      },
    },
    ChannelSettings: {
      name: "ChannelSettings",
      fields: {
        email: {
          name: "email",
          isArray: false,
          type: {
            nonModel: "ChannelConfig",
          },
          isRequired: false,
          attributes: [],
        },
        sms: {
          name: "sms",
          isArray: false,
          type: {
            nonModel: "ChannelConfig",
          },
          isRequired: false,
          attributes: [],
        },
        whatsapp: {
          name: "whatsapp",
          isArray: false,
          type: {
            nonModel: "ChannelConfig",
          },
          isRequired: false,
          attributes: [],
        },
        linkedin: {
          name: "linkedin",
          isArray: false,
          type: {
            nonModel: "ChannelConfig",
          },
          isRequired: false,
          attributes: [],
        },
      },
    },
    ChannelConfig: {
      name: "ChannelConfig",
      fields: {
        isEnabled: {
          name: "isEnabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        recipient: {
          name: "recipient",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        providerID: {
          name: "providerID",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
      },
    },
    CustomGenerationConfig: {
      name: "CustomGenerationConfig",
      fields: {
        styles: {
          name: "styles",
          isArray: true,
          type: "String",
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
        },
        domains: {
          name: "domains",
          isArray: true,
          type: "String",
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
        },
        scopes: {
          name: "scopes",
          isArray: true,
          type: "String",
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
        },
        prompts: {
          name: "prompts",
          isArray: true,
          type: {
            nonModel: "Prompt",
          },
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
        },
        hasTopicsContext: {
          name: "hasTopicsContext",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        topics: {
          name: "topics",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        docTopK: {
          name: "docTopK",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        topK: {
          name: "topK",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        blender: {
          name: "blender",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        mode: {
          name: "mode",
          isArray: false,
          type: {
            enum: "GenerationMode",
          },
          isRequired: false,
          attributes: [],
        },
        aiKnowledge: {
          name: "aiKnowledge",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        compressor: {
          name: "compressor",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
      },
    },
    QaRole: {
      name: "QaRole",
      fields: {
        type: {
          name: "type",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        label: {
          name: "label",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        interpolationString: {
          name: "interpolationString",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        emoji: {
          name: "emoji",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        color: {
          name: "color",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        isDefault: {
          name: "isDefault",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        hasMemory: {
          name: "hasMemory",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        hasTopicsContext: {
          name: "hasTopicsContext",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        allowedTopics: {
          name: "allowedTopics",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        defaultAnswer: {
          name: "defaultAnswer",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        stopSequence: {
          name: "stopSequence",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        examples: {
          name: "examples",
          isArray: true,
          type: {
            nonModel: "QA",
          },
          isRequired: true,
          attributes: [],
          isArrayNullable: true,
        },
        enhancementPassage: {
          name: "enhancementPassage",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        inhibitionPassage: {
          name: "inhibitionPassage",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        pertinencePassage: {
          name: "pertinencePassage",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        goals: {
          name: "goals",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        qaUrl: {
          name: "qaUrl",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        topK: {
          name: "topK",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        mode: {
          name: "mode",
          isArray: false,
          type: {
            enum: "ChatMode",
          },
          isRequired: false,
          attributes: [],
        },
        charting: {
          name: "charting",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        summarisation: {
          name: "summarisation",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        compressor: {
          name: "compressor",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        docTopK: {
          name: "docTopK",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        maxHistory: {
          name: "maxHistory",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        maxTokens: {
          name: "maxTokens",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        topicEnhancer: {
          name: "topicEnhancer",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        maxTopics: {
          name: "maxTopics",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        blender: {
          name: "blender",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        temperature: {
          name: "temperature",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        version: {
          name: "version",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        subject: {
          name: "subject",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
      },
    },
    CustomQaConfig: {
      name: "CustomQaConfig",
      fields: {
        roles: {
          name: "roles",
          isArray: true,
          type: {
            nonModel: "QaRole",
          },
          isRequired: true,
          attributes: [],
          isArrayNullable: true,
        },
      },
    },
    AiWorkspaceConsumption: {
      name: "AiWorkspaceConsumption",
      fields: {
        generator: {
          name: "generator",
          isArray: false,
          type: {
            nonModel: "ApiMethodConsumption",
          },
          isRequired: false,
          attributes: [],
        },
        questioner: {
          name: "questioner",
          isArray: false,
          type: {
            nonModel: "ApiMethodConsumption",
          },
          isRequired: false,
          attributes: [],
        },
        translator: {
          name: "translator",
          isArray: false,
          type: {
            nonModel: "ApiMethodConsumption",
          },
          isRequired: false,
          attributes: [],
        },
        answerer: {
          name: "answerer",
          isArray: false,
          type: {
            nonModel: "ApiMethodConsumption",
          },
          isRequired: false,
          attributes: [],
        },
        parser: {
          name: "parser",
          isArray: false,
          type: {
            nonModel: "ApiMethodConsumption",
          },
          isRequired: false,
          attributes: [],
        },
        tokenizer: {
          name: "tokenizer",
          isArray: false,
          type: {
            nonModel: "ApiMethodConsumption",
          },
          isRequired: false,
          attributes: [],
        },
        embedder: {
          name: "embedder",
          isArray: false,
          type: {
            nonModel: "ApiMethodConsumption",
          },
          isRequired: false,
          attributes: [],
        },
        detector: {
          name: "detector",
          isArray: false,
          type: {
            nonModel: "ApiMethodConsumption",
          },
          isRequired: false,
          attributes: [],
        },
        chatter: {
          name: "chatter",
          isArray: false,
          type: {
            nonModel: "ApiMethodConsumption",
          },
          isRequired: false,
          attributes: [],
        },
        lastUpdate: {
          name: "lastUpdate",
          isArray: false,
          type: "AWSTimestamp",
          isRequired: false,
          attributes: [],
        },
      },
    },
    ApiMethodConsumption: {
      name: "ApiMethodConsumption",
      fields: {
        requests: {
          name: "requests",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        words: {
          name: "words",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        tokens: {
          name: "tokens",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        sentences: {
          name: "sentences",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
      },
    },
    Prompt: {
      name: "Prompt",
      fields: {
        type: {
          name: "type",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        label: {
          name: "label",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        promptLength: {
          name: "promptLength",
          isArray: false,
          type: "Float",
          isRequired: true,
          attributes: [],
        },
        promptPlaceholder: {
          name: "promptPlaceholder",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        interpolationString: {
          name: "interpolationString",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
      },
    },
    ConfigMapByPlan: {
      name: "ConfigMapByPlan",
      fields: {
        domain: {
          name: "domain",
          isArray: false,
          type: {
            enum: "GenerationDomains",
          },
          isRequired: true,
          attributes: [],
        },
        parsingConfig: {
          name: "parsingConfig",
          isArray: false,
          type: {
            nonModel: "ParsingConfig",
          },
          isRequired: true,
          attributes: [],
        },
        notificationConfig: {
          name: "notificationConfig",
          isArray: false,
          type: {
            nonModel: "NotificationConfig",
          },
          isRequired: true,
          attributes: [],
        },
        trainingConfig: {
          name: "trainingConfig",
          isArray: false,
          type: {
            nonModel: "TrainingConfig",
          },
          isRequired: true,
          attributes: [],
        },
      },
    },
    UserWorkspace: {
      name: "UserWorkspace",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        username: {
          name: "username",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        user_email: {
          name: "user_email",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
      },
    },
    TrainingConfig: {
      name: "TrainingConfig",
      fields: {
        trainingIterations: {
          name: "trainingIterations",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        trainingIterationsNLP: {
          name: "trainingIterationsNLP",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        trainingIterationsRC: {
          name: "trainingIterationsRC",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        trainingEpochsNLP: {
          name: "trainingEpochsNLP",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        trainingEpochsRC: {
          name: "trainingEpochsRC",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        trainingEpochsGN: {
          name: "trainingEpochsGN",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        maxStepsNLP: {
          name: "maxStepsNLP",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        patienceNLP: {
          name: "patienceNLP",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        learningRateNLP: {
          name: "learningRateNLP",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        learningRateRC: {
          name: "learningRateRC",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        testSizeNLP: {
          name: "testSizeNLP",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        testSizeRC: {
          name: "testSizeRC",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        batchSizeNLP: {
          name: "batchSizeNLP",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        batchSizeRC: {
          name: "batchSizeRC",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        datasetTestSizeThresholdNLP: {
          name: "datasetTestSizeThresholdNLP",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        datasetTestSizeThresholdRC: {
          name: "datasetTestSizeThresholdRC",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        learningRateGN: {
          name: "learningRateGN",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        testSizeGN: {
          name: "testSizeGN",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        batchSizeGN: {
          name: "batchSizeGN",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        datasetTestSizeThresholdGN: {
          name: "datasetTestSizeThresholdGN",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        enableManagedSpotTraining: {
          name: "enableManagedSpotTraining",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        maxRuntimeWaitTime: {
          name: "maxRuntimeWaitTime",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        maxRuntime: {
          name: "maxRuntime",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        includeReadComprehensionDataInTraining: {
          name: "includeReadComprehensionDataInTraining",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        generateDockerImageForRCengine: {
          name: "generateDockerImageForRCengine",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        generateDockerImageForNLPengine: {
          name: "generateDockerImageForNLPengine",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        generateDockerImageForGNengine: {
          name: "generateDockerImageForGNengine",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        emailsListForTrainingConfirmation: {
          name: "emailsListForTrainingConfirmation",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        maxQueueWaitTime: {
          name: "maxQueueWaitTime",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
      },
    },
    ParsingConfig: {
      name: "ParsingConfig",
      fields: {
        questionsLimit: {
          name: "questionsLimit",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        answerAsNER: {
          name: "answerAsNER",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        answerByParagraph: {
          name: "answerByParagraph",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        uniqueAnswer: {
          name: "uniqueAnswer",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isSentenceSplitEnabled: {
          name: "isSentenceSplitEnabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        isImplicitSelfEnabled: {
          name: "isImplicitSelfEnabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        temperature: {
          name: "temperature",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        maxTokens: {
          name: "maxTokens",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        topP: {
          name: "topP",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        topK: {
          name: "topK",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        qaDocTopK: {
          name: "qaDocTopK",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        frequencyPenalty: {
          name: "frequencyPenalty",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        presencePenalty: {
          name: "presencePenalty",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        qaTopP: {
          name: "qaTopP",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        qaTopK: {
          name: "qaTopK",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        qaMaxTokens: {
          name: "qaMaxTokens",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        qaTemperature: {
          name: "qaTemperature",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        qaFrequencyPenalty: {
          name: "qaFrequencyPenalty",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        qaPresencePenalty: {
          name: "qaPresencePenalty",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        qaReplyOutsidePassage: {
          name: "qaReplyOutsidePassage",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        qaAiKnowledge: {
          name: "qaAiKnowledge",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        qaInhibitionPassage: {
          name: "qaInhibitionPassage",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        qaEnhancementPassage: {
          name: "qaEnhancementPassage",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        qaRole: {
          name: "qaRole",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        qaDefaultAnswer: {
          name: "qaDefaultAnswer",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        qaStopSequence: {
          name: "qaStopSequence",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        stopSequence: {
          name: "stopSequence",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        stopPrompt: {
          name: "stopPrompt",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        qaExamples: {
          name: "qaExamples",
          isArray: true,
          type: {
            nonModel: "QA",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        qaBlender: {
          name: "qaBlender",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        agcEnabled: {
          name: "agcEnabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
      },
    },
    NotificationConfig: {
      name: "NotificationConfig",
      fields: {
        emailsList: {
          name: "emailsList",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        emailOnTrainingCompleted: {
          name: "emailOnTrainingCompleted",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        emailOnBatchCompleted: {
          name: "emailOnBatchCompleted",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        emailOnBatchFailed: {
          name: "emailOnBatchFailed",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        emailOnBatchStart: {
          name: "emailOnBatchStart",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        emailOnTrainingFailed: {
          name: "emailOnTrainingFailed",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        emailOnTrainingStart: {
          name: "emailOnTrainingStart",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        notifyAllUsersInWorkspace: {
          name: "notifyAllUsersInWorkspace",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        notifyOnThreasholdConsumption: {
          name: "notifyOnThreasholdConsumption",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        notificationThresholdConsumption: {
          name: "notificationThresholdConsumption",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
      },
    },
    EmbeddingConfig: {
      name: "EmbeddingConfig",
      fields: {
        maxChunkWords: {
          name: "maxChunkWords",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        maxOverlapSentences: {
          name: "maxOverlapSentences",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        chunkingStrategy: {
          name: "chunkingStrategy",
          isArray: false,
          type: {
            enum: "ChunkingStrategy",
          },
          isRequired: false,
          attributes: [],
        },
        imageExtractionStrategy: {
          name: "imageExtractionStrategy",
          isArray: false,
          type: {
            enum: "ExtractionStrategy",
          },
          isRequired: false,
          attributes: [],
        },
        minImageWidth: {
          name: "minImageWidth",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        minImageHeight: {
          name: "minImageHeight",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        aspectRatioMin: {
          name: "aspectRatioMin",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        aspectRatioMax: {
          name: "aspectRatioMax",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
      },
    },
    ModelDocumentFilterInput: {
      name: "ModelDocumentFilterInput",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        type: {
          name: "type",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        istrained: {
          name: "istrained",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        workspaceid: {
          name: "workspaceid",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
      },
    },
    QA: {
      name: "QA",
      fields: {
        question: {
          name: "question",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        answer: {
          name: "answer",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
      },
    },
    Document: {
      name: "Document",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        embedding_status: {
          name: "embedding_status",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        completion_percentage: {
          name: "completion_percentage",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
      },
    },
  },
  codegenVersion: "3.4.3",
  version: "b90943057a206374d74f7494f883e472",
};
