import AppTextInput from "@toothfairy/shared-ui/AppTextInput";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import { AppText } from "@toothfairy/shared-ui";
import React from "react";
import { View } from "react-native";
import ToothFairyButton from "../../ToothFairyComponents/ToothFairyButton";
import useWorkspaces from "../../../Hooks/useWorkspaces";
import AppToast from "@toothfairy/shared-ui/AppToast";

const UsersToolbar = ({
  searchBtnLabel = "Search",
  createBtnLabel = "Invite users",
  searchBtnAccLabel = "searchButton",
  createBtnAccLabel = "createButton",
  handleSearch = () => {},
  handleCreation = () => {},
  usersCount = 1,
}) => {
  const { getActiveWorkspace } = useWorkspaces();
  const [nameText, setNameText] = React.useState("");
  const [emailText, setEmailText] = React.useState("");
  const [typeText, setTypeText] = React.useState("");
  const AppToasty = AppToast.useToast();
  const { currentTheme } = AppTheme?.useTheme();
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: -10,
          flexWrap: "wrap",
          maxWidth: "100%",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginTop: 10,
          }}
        >
          <AppTextInput
            value={nameText}
            onChangeText={setNameText}
            style={{
              fontSize: 16,
              marginLeft: 0,
            }}
            placeholder="Name"
          />
        </View>
        {/* <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <AppTextInput
          value={typeText}
          onChangeText={setTypeText}
          style={{
            fontSize: 16,
            marginLeft: 0,
          }}
          placeholder="Type"
        />
      </View> */}
        <ToothFairyButton
          wrapperStyle={{ marginTop: 10 }}
          title={searchBtnLabel}
          accessibilityLabel={searchBtnAccLabel}
          onPress={() => handleSearch(nameText)}
        />
        <ToothFairyButton
          wrapperStyle={{ marginTop: 10 }}
          title={createBtnLabel}
          accessibilityLabel={createBtnAccLabel}
          onPress={() => {
            if (
              getActiveWorkspace()?.users?.length >=
              getActiveWorkspace()?.maxUsers
            ) {
              return AppToasty.show(
                "You have reached the maximum number of users for this workspace. Please upgrade your plan to add more users.",
                {
                  type: "danger",
                }
              );
            } else {
              handleCreation();
            }
          }}
        />
      </View>
      <AppText
        wrapperStyle={{
          marginRight: 12,
          paddingLeft: 10,
        }}
        style={{
          fontWeight: "bold",
        }}
        color={
          getActiveWorkspace()?.users?.length == getActiveWorkspace()?.maxUsers
            ? currentTheme?.theme?.red
            : currentTheme?.theme?.primary
        }
      >{`Seats used ${usersCount} / ${
        getActiveWorkspace()?.maxUsers
      }`}</AppText>
    </View>
  );
};

export default UsersToolbar;
