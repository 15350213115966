import useAPI from "@toothfairy/shared-api/useApi";
import { AppText } from "@toothfairy/shared-ui";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppChoice from "@toothfairy/shared-ui/AppChoice";
import AppContactForm from "@toothfairy/shared-ui/AppContactForm";
import AppForms from "@toothfairy/shared-ui/AppForms";
import AppPricing from "@toothfairy/shared-ui/AppPricing";
import AppPricingScreen from "@toothfairy/shared-ui/AppPricingScreen";
import AppTextInput from "@toothfairy/shared-ui/AppTextInput";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import AppUser from "@toothfairy/shared-ui/AppUser";
import React from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import envConfig from "../../../../envConfig";
import API_Consumption from "../../../API/API_Consumption";
import Emails from "../../../API/Emails";
import workspacesHelper from "../../../API/Workspaces/workspacesHelper";
import ToothFairyButton from "../../../Components/ToothFairyComponents/ToothFairyButton";
import ToothFairyLoadingPlaceholder from "../../../Components/ToothFairyComponents/ToothFairyLoadingPlaceholder";
import ToothFairyModal from "../../../Components/ToothFairyComponents/ToothFairyModal";
import useWorkspaces from "../../../Hooks/useWorkspaces";
import { isLargeScreen } from "../../MainViewContainer";
import {
  isBillingAddressSet,
  isUserWithNoPaymentMethod,
  isSSO,
} from "../../../../App";
import { useHistory } from "../../../Router";

const WorkspaceFormView = ({
  onboardingData,
  workspaceCreationData,
  modalContentKey,
  cancelPlanChange = () => {},
  targetPlan,
  setTargetPlan,
  selectedWorkspace,
  onPlanSelection = () => {},
}) => {
  const { values, initialValues } = AppForms.useFormikContext();
  const [showPlan, setShowPlan] = React.useState(false);
  const { user } = AppUser.useUser();
  const history = useHistory();
  const [selectedPlan, setSelectedPlan] = React.useState(null);
  const [confirmationRequired, setConfirmationRequired] = React.useState(false);
  const [contactRequired, setContactRequired] = React.useState(false);
  const [sendingInProgress, setSendingInProgress] = React.useState(false);
  const [updateInProgress, setUpdateInProgress] = React.useState(false);
  const [tempPayload, setTempPayload] = React.useState(null);
  const [updateRequired, setUpdateRequired] = React.useState(false);
  const isBillingDisabled =
    (isUserWithNoPaymentMethod() || !isBillingAddressSet()) && !isSSO();
  const currencyFormatter = (value) => {
    let formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "AUD",
    });
    return formatter.format(value);
  };
  const {
    data,
    loading,
    apiRequest: updateUsageForWorkspaceRequest,
    response,
  } = useAPI(API_Consumption.updateUsageForWorkspace, envConfig);
  const isNew = modalContentKey === "NEW";
  const isChangeOfSubRequested =
    values?.targetSubscriptionType !== null &&
    values?.targetSubscriptionType !== values?.subscriptionType;
  const { getActiveWorkspace, canUpdateSubscription } = useWorkspaces();
  const _targetPlan =
    targetPlan || getActiveWorkspace()?.targetSubscriptionType;
  const { currentTheme } = AppTheme.useTheme();
  const { t } = useTranslation();
  const customDomains =
    getActiveWorkspace()?.customGenerationConfig?.domains || [];
  const isMessageVisible =
    (initialValues.subscriptionType !== values.subscriptionType ||
      isChangeOfSubRequested) &&
    !isNew;
  const isChangeCancellable = isChangeOfSubRequested && !isNew;
  const AppToasty = AppToast.useToast();
  const _modal = showPlan ? (
    <ToothFairyModal
      headerContainerStyle={{
        paddingTop: 10,
        paddingHorizontal: 20,
      }}
      scrollViewStyle={{
        margin: 20,
        width: "95%",
        overflow: "auto",
      }}
      modalBackdrop={true}
      modalBackdropStatic={true}
      modalTitle={
        modalContentKey === "UPDATE"
          ? t("Update your plan")
          : t("Select your plan")
      }
      modalStyle={{
        width: confirmationRequired ? 650 : "90%",
        maxWidth: "unset",
        margin: "auto",
        maxHeight: confirmationRequired && 460,
      }}
      isCloseButtonVisible
      closeModal={() => {
        setShowPlan(false);
        setContactRequired(false);
        setUpdateRequired(false);
      }}
      isModalVisible={showPlan}
      transparent={true}
    >
      {updateInProgress ? (
        <ToothFairyLoadingPlaceholder
          style={{
            position: "absolute",
            maxHeight: 300,
          }}
          caption="Plan update in progress"
        />
      ) : contactRequired ? (
        <AppContactForm
          sendingInProgress={sendingInProgress}
          email={user?.user_email}
          enquiryType="Enterprise upgrade"
          onSubmitEvent={async (values) => {
            try {
              setSendingInProgress(true);
              const emailSendResult = await Emails.sendEmail({
                userEmail: user?.user_email,
                subject: values?.enquiryType,
                message: values?.message,
                workspaceId: getActiveWorkspace()?.id,
              });
              const name = user?.username || user?.user_email;
              const email = user?.user_email;
              const enquiry = values?.enquiryType;
              const data = {
                fields: [
                  {
                    name: "email",
                    value: email,
                  },
                  {
                    name: "firstname",
                    value: name,
                  },
                  {
                    name: "enquiry_type",
                    value: enquiry,
                  },
                  {
                    name: "message",
                    value: values?.message,
                  },
                ],
                context: {
                  pageUri: "www.app.toothfairyai.com/pricing",
                  pageName: "Pricing",
                },
              };
              let url = `https://api.hsforms.com/submissions/v3/integration/submit/40158468/4f2f88c6-dbcd-498d-8abf-3325acb782ca`;
              const _hub_result = await fetch(url, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                  "Content-Type": "application/json",
                },
              });
              console.log("_hub_result", _hub_result);
              AppToasty.show(
                "The upgrade request has been sent. We will be in touch shortly",
                {
                  type: "success",
                }
              );
            } catch (error) {
              console.log("error", error);
              AppToasty.show(
                "The request could not be sent. Please try again later",
                {
                  type: "danger",
                }
              );
            } finally {
              setSendingInProgress(false);
              setShowPlan(false);
              setContactRequired(false);
              setUpdateRequired(false);
            }
          }}
          wrapperStyle={{
            padding: 20,
            backgroundColor: currentTheme?.theme?.lightGrey,
            margin: "auto",
            minWidth: isLargeScreen() && 600,
          }}
        />
      ) : confirmationRequired ? (
        <AppChoice
          wrapperStyle={{
            alignSelf: "center",
            maxWidth: 600,
            margin: "auto",
            padding: 20,
            // backgroundColor: currentTheme?.theme?.lightGrey,
            borderRadius: 10,
          }}
          cancelCaption="Click 'Cancel' to abort the change of plan"
          confirmCaption="Click 'Confirm' to change the plan"
          onConfirmButtonPress={async () => {
            setUpdateInProgress(true);
            const { subscriptionType, seats } = tempPayload;
            try {
              // if the selection of seats is less than the users invited then show error
              if (seats < getActiveWorkspace()?.users?.length)
                return AppToasty.show(
                  "Number of seats should be greater than the number of users currently active in the workspace",
                  {
                    type: "error",
                  }
                );
              if (
                envConfig?.REACT_APP_ENV !== "dev" &&
                !canUpdateSubscription() &&
                subscriptionType !== getActiveWorkspace()?.subscriptionType
              )
                return AppToasty.show(
                  `You cannot update the subscription type more than once in a month`,
                  {
                    type: "danger",
                  }
                );

              const updateWorkspace = await workspacesHelper.updateWorkspace(
                getActiveWorkspace()?.id,
                [
                  {
                    property: "maxUsers",
                    value: seats,
                  },
                  {
                    property: "subscriptionType",
                    value: subscriptionType,
                  },
                ]
              );

              if (updateWorkspace) {
                const resultUpdateStripe = await updateUsageForWorkspaceRequest(
                  {
                    workspaceId: getActiveWorkspace()?.id,
                    usage: seats,
                  }
                );
              }
              setShowPlan(false);
              AppToasty.show(
                "Workspace plan updated successfully. The application will reload",
                {
                  type: "success",
                }
              );
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } catch (error) {
              console.log("error", error);
              AppToasty.show(
                "Workspace plan could not be updated. Please try again later.",
                { type: "danger" }
              );
            } finally {
              setConfirmationRequired(false);
              setTempPayload(null);
              setUpdateInProgress(false);
              setContactRequired(false);
            }
          }}
          onCancelButtonPress={() => {
            setConfirmationRequired(false);
            setTempPayload(null);
            setUpdateRequired(false);
            setContactRequired(false);
          }}
          AppChoiceButton={ToothFairyButton}
          caption="You are about to update the plan for this workspace. Are you sure you want to continue?"
          subCaption={`Once done no further changes can be made within the month`}
        >
          <View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: 10,
              }}
            >
              <AppText>{`Your new plan will be `}</AppText>
              <AppText
                style={{
                  color: currentTheme?.theme?.primary,
                }}
                fontWeight="bold"
              >
                {`${tempPayload?.subscriptionType?.toUpperCase()}`}
              </AppText>
              <AppText>{` with `}</AppText>
              <AppText
                style={{
                  color: currentTheme?.theme?.primary,
                }}
                fontWeight="bold"
              >
                {`${tempPayload?.seats}`}
              </AppText>
              <AppText>{` seats`}</AppText>
            </View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: 10,
              }}
            >
              <AppText>{`Your new monthly subscription cost will be: `}</AppText>
              <AppText
                style={{
                  color: currentTheme?.theme?.primary,
                }}
                fontWeight="bold"
              >
                {`${currencyFormatter(
                  AppPricing?.find(
                    (u) => u?.subscriptionType === tempPayload?.subscriptionType
                  )?.price * tempPayload?.seats
                )}`}
              </AppText>
            </View>
          </View>
        </AppChoice>
      ) : (
        <AppPricingScreen
          onPress={(sub) => {
            setSelectedPlan(sub?.subscriptionType);
          }}
          credit={user?.stripeCustomerData?.balance / -100}
          isBillingDisabled={isBillingDisabled}
          isUpdateRequired={modalContentKey !== "UPDATE" || updateRequired}
          isUpdate={modalContentKey === "UPDATE"}
          currentPlan={
            modalContentKey !== "UPDATE"
              ? initialValues.subscriptionType
              : getActiveWorkspace()?.subscriptionType
          }
          onSeatsChange={(seats, subscriptionType) => {
            if (
              subscriptionType !== getActiveWorkspace()?.subscriptionType ||
              seats !== getActiveWorkspace()?.maxUsers
            ) {
              setUpdateRequired(true);
            } else {
              setUpdateRequired(false);
            }
          }}
          selectedSubscriptionType={selectedPlan}
          initialSeats={
            modalContentKey === "UPDATE"
              ? getActiveWorkspace()?.maxUsers || 1
              : initialValues.maxUsers || 1
          }
          onUpdatePlan={async (subscriptionType, seats, isBillOverCredit) => {
            if (
              subscriptionType !== "enterprise" &&
              isBillingDisabled &&
              isBillOverCredit
            ) {
              history.push("/profile/billing");
            } else if (
              modalContentKey !== "UPDATE" &&
              subscriptionType !== "enterprise"
            ) {
              onPlanSelection(subscriptionType, seats);
              setShowPlan(false);
              return;
            }
            if (subscriptionType === "enterprise") {
              // var email = "info@toothfairyai.com";
              // var subject = encodeURIComponent("Request for Custom Pricing");
              // var body = encodeURIComponent(
              //   `The user is requesting custom pricing for workspace ${
              //     getActiveWorkspace()?.id
              //   } with ${getActiveWorkspace()?.maxUsers} users`
              // );
              // var url = `mailto:${email}?subject=${subject}&body=${body}`;
              // window.location.href = url;
              setContactRequired(true);
            } else {
              setConfirmationRequired(true);
              setTempPayload({
                subscriptionType,
                seats,
              });
            }
          }}
        />
      )}
    </ToothFairyModal>
  ) : null;
  function capitalizeFirstCharacter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  function decapitalizeButFirst(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  return (
    <>
      {_modal}
      <AppForms.AppFormField
        showTextInputLabel
        label="Name"
        isMandatory
        name={"workspaceName"}
        placeholder={onboardingData?.workspaceName?.fieldPlaceholder}
      />
      <AppTextInput
        editable={false}
        showTextInputLabel
        isRightLabelFixed={true}
        label="Choose your plan"
        name={
          isChangeOfSubRequested && !isNew
            ? "targetSubscriptionType"
            : "subscriptionType"
        }
        rightIconLabel={modalContentKey === "UPDATE" ? "Update" : "Select"}
        value={
          values?.subscriptionType !== "Select Plan"
            ? `${capitalizeFirstCharacter(values?.subscriptionType)} - ${
                values?.maxUsers
              } ${values?.maxUsers > 1 ? "seats" : "seat"}`
            : values?.subscriptionType
        }
        textContainer={{
          paddingHorizontal: 10,
          marginHorizontal: 5,
        }}
        style={{
          marginHorizontal: 0,
        }}
        onRightIconClick={() => {
          setShowPlan(true);
        }}
        rightIcon={AppAssets?.icons?.arrow_right}
        rightIconSize={16}
        rightIconStyle={{
          position: "relative",
          left: 8,
          alignItems: "center",
        }}
      />

      {/* <AppForms.AppFormDropDown
        items={workspaceCreationData?.workspaceBaseModel?.fieldOptions}
        showTextInputLabel
        isElevated={false}
        disabled={modalContentKey === "UPDATE"}
        label="Sentiment reviewer model"
        name={"baseModel"}
        propertyValue={"id"}
        propertyLabel="title"
      /> */}
      <AppForms.AppFormDropDown
        items={[
          ...workspaceCreationData?.workspaceDomain?.fieldOptions,
          ...customDomains,
        ]?.map((u) => ({
          ...u,
          title: decapitalizeButFirst(u?.title),
        }))}
        showTextInputLabel
        isElevated={false}
        label="Domain"
        name={"domain"}
        propertyValue={"id"}
        propertyLabel="title"
      />
      {modalContentKey !== "UPDATE" && (
        <AppText
          wrapperStyle={{
            marginTop: -25,
            maxWidth: 340,
            textAlign: "center",
          }}
          color={currentTheme?.theme?.primary}
        >
          It enables our agents to concentrate on the domain that matters to you
        </AppText>
      )}

      {(isMessageVisible || isChangeCancellable) && (
        <View
          style={{
            maxWidth: 340,
            padding: 10,
            alignItems: "center",
          }}
        >
          {isMessageVisible && (
            <AppText
              preventTranslation={true}
              color={currentTheme?.theme?.orange}
            >
              {canUpdateSubscription()
                ? `The transition to ${_targetPlan} plan will take effect immediately`
                : t(
                    `The transition to ${
                      values?.targetSubscriptionType || values.subscriptionType
                    } plan will take effect from next month`
                  )}
            </AppText>
          )}
          {isChangeCancellable && (
            <AppText
              isClickable={true}
              preventTranslation={true}
              onPress={() => cancelPlanChange()}
              style={{ fontWeight: "bold", color: currentTheme?.theme?.blue }}
            >
              {t("Click here to cancel")}
            </AppText>
          )}
        </View>
      )}
    </>
  );
};

export default WorkspaceFormView;
