import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppDropDown from "@toothfairy/shared-ui/AppDropDown";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, StyleSheet, View } from "react-native";
import EditorWrapper from "../../Components/EditorWrapper";
import LoadingPlaceholder from "../../Components/LoadingPlaceholder";
import ToothFairyClickableOption from "../../Components/ToothFairyComponents/ToothFairyClickableOption";
import ToothFairyModal from "../../Components/ToothFairyComponents/ToothFairyModal";
import ToothFairyOutputModal from "../../Components/ToothFairyComponents/ToothFairyOutputModal";
import ToothFairyResponsiveOptionsModal from "../../Components/ToothFairyComponents/ToothFairyResponsiveOptionsModal";
import ToothFairySaveOutput from "../../Components/ToothFairyComponents/ToothFairySaveOutput";
import { AppText } from "@toothfairy/shared-ui";
import ToothFairySwitch from "../../Components/ToothFairyComponents/ToothFairySwitch";
import Constants from "../../Constants";
import btnStyleGenerator from "../../Constants/btnStyleGenerator";
import useWorkspaces from "../../Hooks/useWorkspaces";
import AnswerLanguageDropDown from "../Answerer/Components/AnswerLanguageDropDown";
import {
  isLargeScreen,
  isMobileScreen,
  isVeryLargeScreen,
} from "../MainViewContainer";
import TopicsSelectionComponent from "../MrcDocuments/Components/TopicsSelectionComponent";
import {
  emptyEditorValue,
  initialEditorValue,
} from "../TestExample/commonUtils";
import GeneratorActionButtons from "./Components/GeneratorActionButtons";
import GeneratorConfig from "./Components/GeneratorConfig";
import GeneratorScreenHeader from "./Components/GeneratorScreenHeader";
import ToothFairyLoadingPlaceholder from "../../Components/ToothFairyComponents/ToothFairyLoadingPlaceholder";
import FeedbackView from "../../Components/ToothFairyComponents/FeedbackView";

const GeneratorScreen = ({
  user,
  generateData,
  fakeCheckData,
  configData,
  isInactive,
  handleParse,
  handleOnChange,
  editorState,
  nlpConfig,
  envConfig,
  initialState,
  initialSummaryState,
  editorReducer,
  nlpData,
  parseInProgress,
  fakeCheckInProgress,
  isRefreshRequired,
  value,
  setValue,
  agent,
  setAgent,
  handleOutputCopy = () => {},
  handleFeedback = () => {},
  onPositiveFeedbackPress = () => {},
  onNegativeFeedbackPress = () => {},
  outputStream,
  eValue,
  setEValue,
  answerResult,
  essayInProgress,
  onAnalysisCompleted,
  documentId,
  summmaryInProgress,
  storeInProgress,
  isSaved,
  storeInError,
  onSocketConnectionChange,
  isParser,
  setIsParser,
  handleNewDocument,
  routeToParsing,
  selectedTopics,
  setIsTopicModeOn,
  isTopicModeOn,
  setIsTrainerNoDocumentId,
  isTrainerNoDocumentId,
  isDirty,
  setEditor,
  setEditorQA,
  createAPIInProgress,
  isUploading,
  handleEditorError,
  isSummaryGenerationOn,
  setIsSummaryGenerationModeOn,
  questionInProgress,
  rawText,
  isOutputUpToDate,
  charactersCount,
  wordsCount,
  questionsWordsCount,
  questionsCharactersCount,
  isTopicTrainingScope,
  setisTopicTrainingScopeAsTopic,
  passageForcedLanguage,
  setPassageForcedLanguage,
  requestPayload,
  workspaceid,
  trainingData,
  isEssayModeOn,
  setIsEssayModeOn,
  prompt,
  setPrompt,
  eEditor,
  setEEditor,
  setIsValidationData,
  isValidationData,
  scope,
  styles,
  setScope,
  setStyles,
  handleCreationOfDocumentFromGnOutput,
  handleCheckOnFakeData,
  handleContentLenghtChange = () => {},
  maxOutputContentLength,
  configInProgress,
  agcEnabled,
  scopePlaceholder,
  topics,
  handleOnRecorderClick,
  onSelectedTopicsChange = () => {},
  handleCancellation = () => {},
  S3downloadUrlRequest = () => {},
  handleGenerationOfCustomPrompt = () => {},
  setSelectedTopics,
  isTopicUpdateModeEnabled,
  setIsTopicUpdateModeEnabled,
  trainingContentType,
  setFeedbackModalOpen,
  isFeedbackModalOpen,
  streamid,
  setIsSaveRequested,
}) => {
  const [isOutputExpanded, setIsOutputExpanded] = useState(true);
  const [isSaveOutputModalOpen, setIsSaveOutputModalOpen] = useState(false);
  const [isTrDataModalOpen, setIsTrDataModalOpen] = useState(false);
  const [isOptionsModalOpen, setOptionsModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTopicModalOpen, setIsTopicModalOpen] = useState(false);
  const { mode, currentTheme } = AppTheme?.useTheme();
  const cursor = mode === "light" ? "\u26AB" : "\u26AA";
  const { getContentMultiplier, getActiveWorkspace, isUserContentAdmin } =
    useWorkspaces();
  const decorateOutputString = (markdownString, parseInProgress) => {
    if (parseInProgress) {
      return markdownString + " " + cursor;
    }
    return markdownString;
  };
  const { state } = AppStateManager.useAppStateManager();
  const _styles = styleGenerator({
    currentTheme,
    flexDirection: "column",

    isParser,
  });
  const commonBtnStyle = useMemo(
    () => btnStyleGenerator.commonBtnStyleGenerator(currentTheme),
    [currentTheme]
  );
  const [formatSwitch, setFormatSwitch] = useState(true);
  const isMainParsingFnDisabled =
    isUploading ||
    parseInProgress ||
    !state?.isWorkspaceReady ||
    createAPIInProgress ||
    (!isParser && !documentId && !isTrainerNoDocumentId);

  const isPassageNil = useMemo(() => {
    return rawText?.trim() === "";
  }, [isTopicModeOn, selectedTopics, rawText, value, prompt, isEssayModeOn]);

  const { t } = useTranslation();
  const handleShowTrData = () => {
    setIsTrDataModalOpen(true);
  };
  const saveOutputComponent = useMemo(() => {
    return (
      <ToothFairyModal
        modalStyle={{
          height: 608,
          maxWidth: isLargeScreen() ? 700 : 450,
          marginHorizontal: "auto",
        }}
        isModalOpen={isSaveOutputModalOpen}
        setIsModalOpen={setIsSaveOutputModalOpen}
        modalTitle="Save output"
      >
        <ToothFairySaveOutput
          setIsSaveOutputModalOpen={setIsSaveOutputModalOpen}
          handleCreationOfDocumentFromQaOutput={
            handleCreationOfDocumentFromGnOutput
          }
          topics={topics}
          topicLabelOption="label"
        />
      </ToothFairyModal>
    );
  }, [isSaveOutputModalOpen, setIsSaveOutputModalOpen, JSON.stringify(topics)]);
  const topicSelectionComponent = useMemo(() => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          zIndex: 1,
        }}
      >
        {state?.agents?.find((u) => u?.id === agent)?.allowedTopics?.length >
          0 && isParser ? (
          <TopicsSelectionComponent
            showEditIcon={false}
            isTopicUpdateModeEnabled={true}
            selectedTopics={selectedTopics}
            onSelectedTopicsChange={onSelectedTopicsChange}
            topics={
              state?.agents?.find((u) => u?.id === agent)?.allowedTopics || []
            }
            placeholder="Filter topics"
          />
        ) : (
          isParser && (
            <AppText
              style={{
                fontWeight: "bold",
              }}
              color={currentTheme?.theme?.orange}
            >
              No knowledge hub access
            </AppText>
          )
        )}

        {!isTopicModeOn &&
          state?.languagesConfig &&
          isParser &&
          isVeryLargeScreen() && (
            <AppDropDown
              showTextInputLabel
              label="Output lang."
              inputLabelStyle={{
                marginLeft: 0,
                marginRight: 10,
                marginTop: 10,
              }}
              dropDownStyle={{
                borderColor: currentTheme?.theme?.border_color,
                maxWidth: 250,
              }}
              wrapperContainer={{
                flexDirection: "row",
                alignItems: "center",
              }}
              isElevated={false}
              dropdownIconColor="transparent"
              items={[
                { id: "def", name: "Auto-detect" },
                ...state?.languagesConfig,
              ]}
              propertyLabel="name"
              propertyValue="id"
              selectedValue={passageForcedLanguage}
              onValueChange={(itemValue, itemIndex) => {
                setPassageForcedLanguage(itemValue);
              }}
            />
          )}
      </View>
    );
  }, [
    selectedTopics?.length,
    JSON.stringify(topics),
    isLargeScreen(),
    isMobileScreen(),
    isParser,
    state?.agents?.length,
    agent,
    passageForcedLanguage,
  ]);
  const actionsButtons = useMemo(() => {
    return (
      <GeneratorActionButtons
        value={value}
        setIsSaveOutputModalOpen={setIsSaveOutputModalOpen}
        handleCancellation={handleCancellation}
        handleCheckOnFakeData={handleCheckOnFakeData}
        passageForcedLanguage={passageForcedLanguage}
        setPassageForcedLanguage={setPassageForcedLanguage}
        isTopicModeOn={isTopicModeOn}
        data={{}}
        fakeCheckData={fakeCheckData}
        handleNewDocument={handleNewDocument}
        handleParse={handleParse}
        isDirty={isDirty}
        isMainParsingFnDisabled={isMainParsingFnDisabled}
        isParser={isParser}
        isPassageNil={isPassageNil}
        isTrainerNoDocumentId={isTrainerNoDocumentId}
        setIsModalOpen={setIsModalOpen}
        handleShowTrData={handleShowTrData}
        setOptionsModalOpen={setOptionsModalOpen}
        eValue={eValue}
        handleCreationOfDocumentFromGnOutput={
          handleCreationOfDocumentFromGnOutput
        }
        handleGenerationOfCustomPrompt={handleGenerationOfCustomPrompt}
        parseInProgress={parseInProgress}
        setFeedbackModalOpen={setFeedbackModalOpen}
        isFeedbackModalOpen={isFeedbackModalOpen}
        streamid={streamid}
        agentId={agent}
      />
    );
  }, [
    isDirty,
    isMainParsingFnDisabled,
    isParser,
    isPassageNil,
    isTrainerNoDocumentId,
    setIsModalOpen,
    handleCheckOnFakeData,
    isSummaryGenerationOn,
    eValue,
    selectedTopics?.length,
    handleParse,
    fakeCheckData,
    setIsSaveOutputModalOpen,
    setOptionsModalOpen,
    parseInProgress,
    JSON.stringify(value),
    isFeedbackModalOpen,
    setFeedbackModalOpen,
    streamid,
    agent,
  ]);
  const generateRequestPayload = () => {
    return {
      prompts: [
        {
          text: rawText,
          targetLanguage: passageForcedLanguage,
          scope: scope,
          styles: [styles],
        },
      ],
      workspaceid: workspaceid,
    };
  };

  const outputModal = useMemo(() => {
    return (
      !isUploading && (
        <ToothFairyOutputModal
          urlInputWidth={450}
          path={"generate/content"}
          isOutputUpToDate={isOutputUpToDate}
          isOutputExpanded={isOutputExpanded}
          data={generateData}
          requestPayload={generateRequestPayload()}
          subCaption={"ContentSubCaption"}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          checkLength={false}
        />
      )
    );
  }, [
    isUploading,
    isOutputExpanded,
    prompt,
    parseInProgress,
    isOutputUpToDate,
    answerResult,
    isModalOpen,
    setIsModalOpen,
    passageForcedLanguage,
    isEssayModeOn,
    rawText,
    scope,
    styles,
  ]);
  const feedbackModal = useMemo(() => {
    return (
      <ToothFairyModal
        modalStyle={{
          height: 350,
          maxWidth: isLargeScreen() ? 700 : 450,
          marginHorizontal: "auto",
        }}
        isModalOpen={isFeedbackModalOpen}
        setIsModalOpen={setFeedbackModalOpen}
        modalTitle="Feedback"
      >
        <FeedbackView
          handleFeedback={handleFeedback}
          message={{
            _id: streamid,
          }}
        />
      </ToothFairyModal>
    );
  }, [isFeedbackModalOpen, setFeedbackModalOpen, handleFeedback, streamid]);
  const trainingDataModal = useMemo(() => {
    return (
      <ToothFairyOutputModal
        checkLength={false}
        method="GET"
        path={"trainingdata"}
        isOutputUpToDate={true}
        handleOutputResize={() => {}}
        isOutputExpanded={true}
        parsingInProgress={false}
        data={trainingData}
        requestPayload={{
          pathParams: {
            id: documentId || "Undefined - document must be saved",
            workspaceId: workspaceid,
            trainingData: trainingData?.trainingData,
          },
        }}
        subCaption={"noTrainingDataInDocumentCaption"}
        isModalOpen={isTrDataModalOpen}
        setIsModalOpen={setIsTrDataModalOpen}
      />
    );
  }, [JSON.stringify(trainingData), isTrDataModalOpen]);
  const optionsModal = () => {
    return (
      isOptionsModalOpen && (
        <ToothFairyResponsiveOptionsModal
          isModalOpen={isOptionsModalOpen}
          setIsModalOpen={setOptionsModalOpen}
          modalTitle="Options"
          scrollViewStyle={{
            height: 250,
          }}
          height={"auto"}
        >
          {Constants.isShowRequestEnabled && (
            <ToothFairyClickableOption
              leftIcon={AppAssets.icons.info}
              onPress={() => {
                setOptionsModalOpen(false);
                setIsModalOpen(true);
              }}
            >
              Show Request
            </ToothFairyClickableOption>
          )}

          {!isParser && (
            <>
              <ToothFairyClickableOption
                leftIcon={AppAssets.icons.save}
                onPress={() =>
                  handleNewDocument(!isTrainerNoDocumentId, initialEditorValue)
                }
                isClickable={isDirty}
              >
                {isTrainerNoDocumentId ? "Save" : "New"}
              </ToothFairyClickableOption>
              <ToothFairySwitch
                wrapperStyle={{
                  flex: 1,
                  marginRight: 0,
                  backgroundColor: currentTheme?.theme?.inputBackgroundColor,
                }}
                label={"Validation data"}
                onChange={(v) => {
                  setIsValidationData(v);
                }}
                isTicked={isValidationData}
              />
            </>
          )}
          {isParser && (
            <>
              <GeneratorConfig
                configData={configData}
                setScope={setScope}
                setPrompt={setPrompt}
                scope={scope}
                setStyles={setStyles}
                styles={styles}
                agent={agent}
                setAgent={setAgent}
                isInModal
              />
              <AnswerLanguageDropDown
                languages={state?.languagesConfig}
                language={passageForcedLanguage}
                setLanguage={setPassageForcedLanguage}
              />
              {parseInProgress ? (
                <ToothFairyClickableOption
                  leftIcon={AppAssets.icons.close}
                  onPress={() => {
                    setIsSaveOutputModalOpen(true);
                    setOptionsModalOpen(false);
                    handleCancellation();
                  }}
                  isClickable
                >
                  Cancel
                </ToothFairyClickableOption>
              ) : (
                isUserContentAdmin() && (
                  <>
                    <ToothFairyClickableOption
                      leftIcon={AppAssets.icons.save}
                      onPress={() => {
                        setIsSaveOutputModalOpen(true);
                        setOptionsModalOpen(false);
                      }}
                      isClickable={
                        eValue[0]?.children?.length !== 0 &&
                        eValue[0]?.children[0]?.text?.trim() !== "" &&
                        !isMainParsingFnDisabled
                      }
                    >
                      Save output
                    </ToothFairyClickableOption>
                    <ToothFairyClickableOption
                      leftIcon={AppAssets.icons.writer}
                      onPress={() => {
                        handleGenerationOfCustomPrompt(value, [agentId]);
                      }}
                      isClickable={
                        value[0]?.children?.length !== 0 &&
                        value[0]?.children[0]?.text?.trim() !== "" &&
                        !isMainParsingFnDisabled
                      }
                    >
                      Save prompt
                    </ToothFairyClickableOption>
                  </>
                )
              )}
            </>
          )}
        </ToothFairyResponsiveOptionsModal>
      )
    );
  };
  const _editorStyles = useMemo(() => {
    return {
      wrapperStyle: {
        // backgroundColor: currentTheme?.theme?.softBackgroundColor,
        marginBottom: 10,
        marginTop: 10,
        flex: 1,
        padding: 10,
        borderWidth: 1,
        borderRadius: 10,
        borderColor: currentTheme?.theme?.border_color,
      },
      style: {
        overflowY: Platform.OS === "web" ? "auto" : "scroll",
        flex: 1,
        zIndex: -1,
        fontFamily: "normalFont",
      },
      titleStyle: {
        fontSize: currentTheme?.theme?.base_font_size,
      },
    };
  }, [currentTheme]);
  const _maxWords = useMemo(() => {
    if (isParser) return 2048;
    else return 8000;
  }, [
    state?.customPrompts,
    scope,
    isParser,
    getActiveWorkspace()?.isExtendedContentCreatorEnabled,
  ]);

  const passageComponent = useMemo(() => {
    return createAPIInProgress ? (
      <LoadingPlaceholder
        backgroundColor={currentTheme?.theme?.transparent}
        visible={true}
        caption={`Generating document...`}
      />
    ) : (
      <EditorWrapper
        allowForHidingToolbar
        showLabel="Show prompt"
        hideLabel="Hide prompt"
        editMode={true}
        isPlaceHolderEnabled
        placeholder={"Write your prompt here..."}
        readOnly={parseInProgress}
        isInactive={isInactive}
        {..._editorStyles}
        forceTitle={false}
        isTitleEditable={false}
        onError={handleEditorError}
        handleParse={handleParse}
        baseDocumentRedirectLink="generator"
        initialState={emptyEditorValue}
        editorReducer={editorReducer}
        editorState={editorState}
        handleOnChange={handleOnChange}
        isRefreshRequired={isRefreshRequired}
        isToolBarVisible={formatSwitch}
        value={value}
        setValue={setValue}
        setEditor={setEditor}
        nlpConfig={nlpConfig}
        envConfig={envConfig}
        onAnalysisCompleted={onAnalysisCompleted}
        maxWords={_maxWords}
        // 512 as unit of measure multiplied by 3 to get 1536
        // 1 token equals 4 characters and 100 tokens equals 75 words
        maxCharacters={_maxWords * 8}
        characters={charactersCount}
        words={wordsCount}
        onRecordClick={handleOnRecorderClick}
        allowTranscription={false}
      />
    );
  }, [
    createAPIInProgress,
    parseInProgress,
    value,
    documentId,
    formatSwitch,
    isParser,
    setEditor,
    isEssayModeOn,
    prompt,
    setPrompt,
    handleCheckOnFakeData,
    fakeCheckData,
    charactersCount,
  ]);
  const trainerComponent = useMemo(() => {
    if (isParser) return <></>;
    return createAPIInProgress ? (
      <LoadingPlaceholder
        backgroundColor={currentTheme?.theme?.transparent}
        visible={true}
        caption={`Generating document...`}
      />
    ) : (
      <EditorWrapper
        allowForHidingToolbar
        key={documentId?.toString()}
        showLabel="Show passage"
        hideLabel="Hide passage"
        editMode={true}
        trainingContentType={trainingContentType}
        isPlaceHolderEnabled
        placeholder={scopePlaceholder}
        readOnly={parseInProgress}
        isInactive={isInactive}
        {..._editorStyles}
        forceTitle={false}
        isTitleEditable={false}
        trainGenerationMode={!isParser}
        onSocketConnectionChange={onSocketConnectionChange}
        onError={handleEditorError}
        handleParse={handleParse}
        baseDocumentRedirectLink="generator"
        initialState={emptyEditorValue}
        editorReducer={editorReducer}
        editorState={editorState}
        handleOnChange={handleOnChange}
        isRefreshRequired={isRefreshRequired}
        isToolBarVisible={formatSwitch}
        value={value}
        setValue={setValue}
        setEditor={setEditor}
        nlpConfig={nlpConfig}
        envConfig={envConfig}
        onAnalysisCompleted={onAnalysisCompleted}
        documentId={documentId}
        maxWords={_maxWords}
        // 512 as unit of measure multiplied by 3 to get 1536
        // 1 token equals 4 characters and 100 tokens equals 75 words
        maxCharacters={_maxWords * 8}
        characters={charactersCount}
        words={wordsCount}
      />
    );
  }, [
    createAPIInProgress,
    parseInProgress,
    value,
    documentId,
    formatSwitch,
    isParser,
    setEditor,
    isEssayModeOn,
    prompt,
    setPrompt,
    handleCheckOnFakeData,
    fakeCheckData,
    charactersCount,
    trainingContentType,
  ]);

  const outputComponentRenderer = useMemo(() => {
    return createAPIInProgress ? (
      <></>
    ) : parseInProgress && eValue[0]?.children[0]?.text == "**...**" ? (
      <ToothFairyLoadingPlaceholder
        caption="Generating content..."
        style={{
          paddingTop: 50,
          position: "unset",
          alignItems: "flex-start",
          flex: 1,
          flexShrink: 1,
          flexBasis: "100%",
        }}
      />
    ) : (
      <AppText
        onCopy={handleOutputCopy}
        S3downloadUrlRequest={S3downloadUrlRequest}
        workspaceid={getActiveWorkspace()?.id}
        renderMarkdown={true}
        showCopyButton={eValue[0]?.children[0]?.text?.length > 0}
        showFeedbackButtons={streamid}
        onPositiveFeedbackPress={() => handleFeedback(streamid, "positive")}
        onNegativeFeedbackPress={() => {
          setFeedbackModalOpen(true);
        }}
        wrapperStyle={{
          alignItems: "flex-start",
          flex: 1,
          flexShrink: 1,
          flexBasis: "100%",
          // backgroundColor: currentTheme?.theme?.softBackgroundColor,
          borderWidth: 1,
          borderRadius: 10,
          borderColor: currentTheme?.theme?.border_color,
          padding: 10,
        }}
        iconSize={16}
        style={{
          color: currentTheme?.theme?.font_color,
          fontWeight: "400",
          lineHeight: 18,
          marginLeft: 10,
        }}
      >
        {eValue[0]?.children[0]?.text?.length > 0 || parseInProgress
          ? decorateOutputString(eValue[0]?.children[0]?.text, parseInProgress)
          : "# Content output  \nClick on 'Create' to generate content"}
      </AppText>
    );
    //   <EditorWrapper
    //     fixedTitle={t("Output")}
    //     placeholder={
    //       isEssayModeOn ? "Content generated..." : "Summary generated..."
    //     }
    //     // eslint-disable-next-line no-useless-concat
    //     baseDocumentRedirectLink="generator"
    //     onError={handleEditorError}
    //     forceTitle={false}
    //     // editMode={!parseInProgress}
    //     {..._editorStyles}
    //     handleCheckOnFakeData={handleCheckOnFakeData}
    //     fakeCheckData={fakeCheckData}
    //     agcEnabled={agcEnabled}
    //     acgShownInFixedTitle={true}
    //     fakeCheckInProgress={fakeCheckInProgress}
    //     words={questionsWordsCount}
    //     characters={questionsCharactersCount}
    //     initialState={initialSummaryState}
    //     editorReducer={editorReducer}
    //     handleOnChange={handleOnChange}
    //     isToolBarVisible={false}
    //     value={eValue}
    //     editor={eEditor}
    //     setValue={setEValue}
    //     setEditor={setEEditor}
    //     showCounter={false}
    //     fixedTitleContainerWrapperStyle={{
    //       marginTop: 0,
    //       marginBottom: 10,
    //     }}
    //     minimumOutputContentValue={800}
    //     maximumOutputContentValue={1600 * getContentMultiplier()}
    //     handleContentLenghtChange={handleContentLenghtChange}
    //     maxOutputContentLength={maxOutputContentLength}
    //     SliderComponent={ToothFairySlider}
    //  />
  }, [
    isSummaryGenerationOn,
    isTopicModeOn,
    isParser,
    parseInProgress,
    fakeCheckInProgress,
    JSON.stringify(eValue),
    isEssayModeOn,
    createAPIInProgress,
    maxOutputContentLength,
    handleContentLenghtChange,
    getContentMultiplier,
    S3downloadUrlRequest,
  ]);
  return (
    <View style={_styles.container}>
      <GeneratorScreenHeader
        configData={configData}
        scope={scope}
        styles={styles}
        setScope={setScope}
        setStyles={setStyles}
        setPrompt={setPrompt}
        agent={agent}
        setAgent={setAgent}
        prompt={prompt}
        setIsValidationData={setIsValidationData}
        isValidationData={isValidationData}
        parseInProgress={parseInProgress}
        isEssayModeOn={isEssayModeOn}
        setIsEssayModeOn={setIsEssayModeOn}
        handleShowTrData={handleShowTrData}
        handleCheckOnFakeData={handleCheckOnFakeData}
        isTopicTrainingScope={isTopicTrainingScope}
        setisTopicTrainingScopeAsTopic={setisTopicTrainingScopeAsTopic}
        isMainParsingFnDisabled={isMainParsingFnDisabled}
        isPassageNil={isPassageNil}
        documentId={documentId}
        isTopicUpdateModeEnabled={isTopicUpdateModeEnabled}
        setIsTopicUpdateModeEnabled={setIsTopicUpdateModeEnabled}
        setSelectedTopics={setSelectedTopics}
        selectedTopics={selectedTopics}
        essayInProgress={essayInProgress}
        summmaryInProgress={summmaryInProgress}
        setIsModalOpen={setIsModalOpen}
        isParser={isParser}
        handleParse={handleParse}
        isTrainerNoDocumentId={isTrainerNoDocumentId}
        isDirty={isDirty}
        data={null}
        isUploading={isUploading}
        storeInError={storeInError}
        storeInProgress={storeInProgress}
        isSaved={isSaved}
        handleNewDocument={handleNewDocument}
        routeToParsing={routeToParsing}
        setIsParser={setIsParser}
        setIsTrainerNoDocumentId={setIsTrainerNoDocumentId}
        setIsTopicModeOn={setIsTopicModeOn}
        isTopicModeOn={isTopicModeOn}
        isSummaryGenerationOn={isSummaryGenerationOn}
        setIsSummaryGenerationModeOn={setIsSummaryGenerationModeOn}
        trainingContentType={trainingContentType}
        setIsSaveRequested={setIsSaveRequested}
      />
      {topicSelectionComponent}
      {actionsButtons}

      {isUploading ? (
        <LoadingPlaceholder
          backgroundColor={currentTheme?.theme?.transparent}
          visible={true}
          caption="Upload in progress..."
        />
      ) : (
        <View
          style={{
            flex: 1,
            justifyContent: "flex-start",
            minHeight: isTopicModeOn ? 400 : 500,
            zIndex: -1,
          }}
        >
          {isParser && passageComponent}
          {trainerComponent}
          {isParser && outputComponentRenderer}
        </View>
      )}
      {outputModal}
      {trainingDataModal}
      {optionsModal()}
      {saveOutputComponent}
      {feedbackModal}
    </View>
  );
};

const styleGenerator = ({ flexDirection, isParser, currentTheme }) => {
  return StyleSheet.create({
    container: {
      flexDirection: flexDirection,
      flex: 1,
      overflow: Platform.OS === "web" ? "auto" : "scroll",
      justifyContent: isParser ? "space-between" : "flex-start",
      paddingHorizontal: 20,
      paddingVertical: 10,
      minHeight: 400,
    },
  });
};

export default GeneratorScreen;
