import AppAssets from "@toothfairy/shared-ui/AppAssets";
import { AppText } from "@toothfairy/shared-ui";
import AppMultipleSelectInput from "@toothfairy/shared-ui/AppMultipleSelectInput";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Platform, StyleSheet, View } from "react-native";
import EditorWrapper from "../../Components/EditorWrapper";
import LoadingPlaceholder from "../../Components/LoadingPlaceholder";
import ToothFairyClickableOption from "../../Components/ToothFairyComponents/ToothFairyClickableOption";
import ToothFairyModal from "../../Components/ToothFairyComponents/ToothFairyModal";
import ToothFairyOutputModal from "../../Components/ToothFairyComponents/ToothFairyOutputModal";
import ToothFairyResponsiveOptionsModal from "../../Components/ToothFairyComponents/ToothFairyResponsiveOptionsModal";
import ToothFairySaveOutput from "../../Components/ToothFairyComponents/ToothFairySaveOutput";
import ToothFairySwitch from "../../Components/ToothFairyComponents/ToothFairySwitch";
import Constants from "../../Constants";
import useWorkspaces from "../../Hooks/useWorkspaces";
import {
  isLargeScreen,
  isMobileScreen,
  isVeryLargeScreen,
} from "../MainViewContainer";
import DictionarySettingsAI from "../Settings/Components/Dictionary/DictionarySettingsAI";
import SettingsAI from "../Settings/Components/SettingsAI";
import AnswererActionButtons from "./Components/AnswerActionButtons";
import AnswerLanguageDropDown from "./Components/AnswerLanguageDropDown";
import AnswerScreenHeader from "./Components/AnswerScreenHeader";
import InputSwitcher from "./Components/InputSwitcher";
import TopicsSelectionComponent from "../MrcDocuments/Components/TopicsSelectionComponent";
import { initialEditorValue } from "../TestExample/commonUtils";
import ToothFairyLoadingPlaceholder from "../../Components/ToothFairyComponents/ToothFairyLoadingPlaceholder";

const AnswererScreen = ({
  user,
  isInactive,
  handleParse,
  handleOnChange,
  editorState,
  nlpConfig,
  envConfig,
  initialState,
  initialQuestionsState,
  editorReducer,
  nlpData,
  answerInProgress,
  isRefreshRequired,
  value,
  setValue,
  qValue,
  setQValue,
  answerResult,
  questionResult,
  onAnalysisCompleted,
  documentId,
  parseInProgress,
  storeInProgress,
  isSaved,
  storeInError,
  onSocketConnectionChange,
  isParser,
  setIsParser,
  handleNewDocument,
  routeToParsing,
  topics,
  onSelectedTopicsChange,
  selectedTopics,
  setIsTopicModeOn,
  isTopicModeOn,
  setIsTrainerNoDocumentId,
  isTrainerNoDocumentId,
  isDirty,
  setEditor,
  setEditorQA,
  createAPIInProgress = true,
  handleFileSelect,
  isUploading,
  handleEditorError,
  isAutoQuestionOn,
  setIsAutoQuestionModeOn,
  questionInProgress,
  selectedNers,
  onSelectedNersChange,
  ners,
  rawText,
  isOutputUpToDate,
  charactersCount,
  wordsCount,
  questionsWordsCount,
  questionsCharactersCount,
  handleEntityCreation,
  isTopicTrainingScope,
  setisTopicTrainingScopeAsTopic,
  showDictionaryModal,
  setShowDictonaryModal,
  handleAddDictionary,
  initialMapValues,
  onMappingCreated,
  passageForcedLanguage,
  setPassageForcedLanguage,
  requestPayload,
  workspaceid,
  trainingData,
  setIsValidationData,
  isValidationData,
  handleCreationOfDocumentFromQaOutput,
  setReadyForNextQuestion,
  readyForNextQuestion,
  isAnswersEditorDirty,
  handleCancellation,
  outputContainsAnswer,
  agent,
  setAgent,
  outputValue,
  handleOutputCopy,
  S3downloadUrlRequest,
  markdownString,
  setPrompt,
  forceEditorWithValue,
}) => {
  const [isOutputExpanded, setIsOutputExpanded] = useState(true);
  const [isOptionsModalOpen, setOptionsModalOpen] = useState(false);
  const [isTrDataModalOpen, setIsTrDataModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTopicModalOpen, setIsTopicModalOpen] = useState(false);
  const [delayOnRender, setDelayOnRender] = useState(false);
  const [isSaveOutputModalOpen, setIsSaveOutputModalOpen] = useState(false);
  const { currentTheme } = AppTheme.useTheme();
  const { state } = AppStateManager.useAppStateManager();
  const { isUserContentAdmin, getActiveWorkspace } = useWorkspaces();
  const { mode } = AppTheme?.useTheme();
  const cursor = mode === "light" ? "\u26AB" : "\u26AA";
  const _styles = styleGenerator({
    currentTheme,
    flexDirection: "column",

    isParser,
  });
  const [formatSwitch, setFormatSwitch] = useState(true);
  const isMainParsingFnDisabled =
    answerInProgress ||
    questionInProgress ||
    createAPIInProgress ||
    !state?.isWorkspaceReady ||
    (!isParser && !documentId && !isTrainerNoDocumentId);

  const isPassageNil = useMemo(() => {
    return (
      !isTopicModeOn && rawText?.trim() === "Text entry" && value?.length > 1
    );
  }, [isTopicModeOn, selectedTopics, rawText, value]);

  const { t } = useTranslation();
  const handleShowTrData = () => {
    setIsTrDataModalOpen(true);
  };

  const decorateOutputString = (markdownString, answerInProgress) => {
    if (answerInProgress) {
      return markdownString + " " + cursor;
    }
    return markdownString;
  };
  const topicSelectionComponent = useMemo(() => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          zIndex: 1,
        }}
      >
        {state?.agents?.find((u) => u?.id === agent)?.allowedTopics?.length >
        0 ? (
          <TopicsSelectionComponent
            showEditIcon={false}
            isTopicUpdateModeEnabled={true}
            selectedTopics={selectedTopics}
            onSelectedTopicsChange={onSelectedTopicsChange}
            topics={
              state?.agents?.find((u) => u?.id === agent)?.allowedTopics || []
            }
            placeholder="Filter topics"
          />
        ) : (
          <AppText
            style={{
              fontWeight: "bold",
            }}
            color={currentTheme?.theme?.orange}
          >
            No knowledge hub access
          </AppText>
        )}

        {isLargeScreen() && (
          <AnswerLanguageDropDown
            languages={state?.languagesConfig}
            language={passageForcedLanguage}
            setLanguage={setPassageForcedLanguage}
          />
        )}
        {/* 
        <AppIcon
          isClickable={true}
          icon={AppAssets.icons.plus}
          onPress={() => setIsTopicModalOpen(true)}
          size={26}
          style={{ marginLeft: 20 }}
        /> */}
      </View>
    );
  }, [
    selectedTopics?.length,
    JSON.stringify(topics),
    isTopicTrainingScope,
    isParser,
    isLargeScreen(),
    isMobileScreen(),
    state?.agents?.length,
    agent,
    passageForcedLanguage,
  ]);
  const actionsButtons = useMemo(() => {
    return (
      <AnswererActionButtons
        setIsSaveOutputModalOpen={setIsSaveOutputModalOpen}
        qValue={qValue}
        passageForcedLanguage={passageForcedLanguage}
        setPassageForcedLanguage={setPassageForcedLanguage}
        isTopicModeOn={isTopicModeOn}
        data={answerResult["q&a"]}
        handleNewDocument={handleNewDocument}
        handleParse={handleParse}
        isDirty={isDirty}
        isMainParsingFnDisabled={isMainParsingFnDisabled}
        isParser={isParser}
        isPassageNil={isPassageNil}
        isTrainerNoDocumentId={isTrainerNoDocumentId}
        setIsModalOpen={setIsModalOpen}
        handleShowTrData={handleShowTrData}
        setOptionsModalOpen={setOptionsModalOpen}
        isAnswersEditorDirty={isAnswersEditorDirty}
        handleCancellation={handleCancellation}
        answerInProgress={answerInProgress}
        readyForNextQuestion={readyForNextQuestion}
        setReadyForNextQuestion={setReadyForNextQuestion}
        agentId={agent}
        value={qValue}
      />
    );
  }, [
    answerResult["q&a"],
    isDirty,
    answerInProgress,
    isMainParsingFnDisabled,
    isParser,
    isPassageNil,
    isTrainerNoDocumentId,
    setIsModalOpen,
    isAutoQuestionOn,
    qValue,
    selectedTopics?.length,
    isMobileScreen(),
    isLargeScreen(),
    isVeryLargeScreen(),
    handleCreationOfDocumentFromQaOutput,
    isAnswersEditorDirty,
    readyForNextQuestion,
    agent,
  ]);
  const topicCreationComponent = useMemo(() => {
    return (
      <ToothFairyModal
        modalStyle={{
          height: 500,
          width: 450,
          marginHorizontal: "auto",
        }}
        isModalOpen={isTopicModalOpen}
        setIsModalOpen={setIsTopicModalOpen}
        modalTitle="Create topic"
      >
        <SettingsAI
          onEntityCreated={handleEntityCreation}
          settingType="topic"
        />
      </ToothFairyModal>
    );
  }, [isTopicModalOpen, setIsTopicModalOpen]);
  const saveOutputComponent = useMemo(() => {
    return (
      <ToothFairyModal
        modalStyle={{
          height: 608,
          maxWidth: isLargeScreen() ? 700 : 450,
          marginHorizontal: "auto",
        }}
        isModalOpen={isSaveOutputModalOpen}
        setIsModalOpen={setIsSaveOutputModalOpen}
        modalTitle="Save output"
      >
        <ToothFairySaveOutput
          setIsSaveOutputModalOpen={setIsSaveOutputModalOpen}
          handleCreationOfDocumentFromQaOutput={
            handleCreationOfDocumentFromQaOutput
          }
          topics={topics}
        />
      </ToothFairyModal>
    );
  }, [isSaveOutputModalOpen, setIsSaveOutputModalOpen]);
  const dictonaryItemCreationComponent = useMemo(() => {
    return (
      <ToothFairyModal
        modalStyle={{
          height: 500,
          width: 450,
          marginHorizontal: "auto",
          marginTop: "auto",
          marginBottom: "auto",
          margin: "auto",
        }}
        isModalOpen={showDictionaryModal}
        setIsModalOpen={setShowDictonaryModal}
        modalTitle="dictorionaryCreationTitle"
      >
        <DictionarySettingsAI
          initialMapValues={initialMapValues}
          onMappingCreated={onMappingCreated}
        />
      </ToothFairyModal>
    );
  }, [showDictionaryModal, setShowDictonaryModal, initialMapValues]);

  const generateOutputData = useCallback(() => {
    if (isAutoQuestionOn)
      return questionResult?.errorCode ? questionResult : questionResult["q&a"];
    else return answerResult?.errorCode ? answerResult : answerResult["q&a"];
  }, [isAutoQuestionOn, questionResult, answerResult]);

  const outputModal = useMemo(() => {
    return (
      !isUploading && (
        <ToothFairyOutputModal
          path={isAutoQuestionOn ? "question" : "qa"}
          isOutputUpToDate={isOutputUpToDate}
          isOutputExpanded={isOutputExpanded}
          parsingInProgress={questionInProgress || answerInProgress}
          data={generateOutputData()}
          requestPayload={requestPayload}
          subCaption={"AnswererSubCaption"}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          checkLength={false}
        />
      )
    );
  }, [
    isUploading,
    isOutputExpanded,
    questionInProgress,
    answerInProgress,
    isOutputUpToDate,
    answerResult,
    isModalOpen,
    setIsModalOpen,
  ]);

  const optionsModal = () => {
    return (
      isOptionsModalOpen && (
        <ToothFairyResponsiveOptionsModal
          isModalOpen={isOptionsModalOpen}
          setIsModalOpen={setOptionsModalOpen}
          modalTitle="Options"
          scrollViewStyle={{
            height: 250,
          }}
          height={"auto"}
        >
          {Constants.isShowRequestEnabled && (
            <ToothFairyClickableOption
              leftIcon={AppAssets.icons.info}
              onPress={() => {
                setOptionsModalOpen(false);
                setIsModalOpen(true);
              }}
            >
              Show Request
            </ToothFairyClickableOption>
          )}

          {!isParser && (
            <>
              <ToothFairyClickableOption
                leftIcon={AppAssets.icons.data}
                onPress={handleShowTrData}
              >
                Training data
              </ToothFairyClickableOption>
              <ToothFairyClickableOption
                leftIcon={AppAssets.icons.save}
                onPress={() =>
                  handleNewDocument(!isTrainerNoDocumentId, initialEditorValue)
                }
                isClickable={isDirty}
              >
                {isTrainerNoDocumentId ? "Save" : "New"}
              </ToothFairyClickableOption>

              <ToothFairySwitch
                wrapperStyle={{
                  flex: 1,
                  marginRight: 0,
                  backgroundColor: currentTheme?.theme?.inputBackgroundColor,
                }}
                label={"Validation data"}
                onChange={(v) => {
                  setIsValidationData(v);
                }}
                isTicked={isValidationData}
              />
            </>
          )}

          {isParser && (
            <InputSwitcher
              isInModal={true}
              isTopicModeOn={isTopicModeOn}
              setIsTopicModeOn={setIsTopicModeOn}
              isAutoQuestionOn={isAutoQuestionOn}
              setIsAutoQuestionModeOn={setIsAutoQuestionModeOn}
              isParser={isParser}
              parseInProgress={answerInProgress || questionInProgress}
              agent={agent}
              setAgent={setAgent}
              setPrompt={setPrompt}
              setQValue={setQValue}
            />
          )}
          {
            <AnswerLanguageDropDown
              languages={state?.languagesConfig}
              language={passageForcedLanguage}
              setLanguage={setPassageForcedLanguage}
            />
          }
          {isParser && isUserContentAdmin() && (
            <ToothFairyClickableOption
              leftIcon={AppAssets.icons.save}
              // onPress={handleCreationOfDocumentFromQaOutput}
              onPress={() => {
                setOptionsModalOpen(false);
                setIsSaveOutputModalOpen(true);
              }}
              isClickable={
                qValue[0]?.children?.length !== 0 &&
                qValue[0]?.children[0]?.text?.trim() !== "" &&
                !isMainParsingFnDisabled
              }
            >
              Save output
            </ToothFairyClickableOption>
          )}
        </ToothFairyResponsiveOptionsModal>
      )
    );
  };
  const trainingDataModal = useMemo(() => {
    return (
      <ToothFairyOutputModal
        checkLength={false}
        method="GET"
        path={"trainingdata"}
        isOutputUpToDate={true}
        handleOutputResize={() => {}}
        isOutputExpanded={true}
        parsingInProgress={false}
        data={trainingData}
        requestPayload={{
          pathParams: {
            id: documentId || "Undefined - document must be saved",
            workspaceId: workspaceid,
            fields: ["trainingdata"],
          },
        }}
        subCaption={"noTrainingDataInDocumentCaption"}
        isModalOpen={isTrDataModalOpen}
        setIsModalOpen={setIsTrDataModalOpen}
      />
    );
  }, [trainingData, isTrDataModalOpen]);

  const _editorStyles = useMemo(() => {
    return {
      wrapperStyle: {
        // backgroundColor: currentTheme?.theme?.softBackgroundColor,
        marginBottom: 10,
        marginTop: 10,
        flex: 1,
        padding: 10,
        borderWidth: 1,

        borderRadius: 10,
        borderColor: currentTheme?.theme?.border_color,
      },
      style: {
        overflowY: Platform.OS === "web" ? "auto" : "scroll",
        flex: 1,
        zIndex: -1,
        fontFamily: "normalFont",
      },
      titleStyle: {
        fontSize: currentTheme?.theme?.base_font_size,
      },
    };
  }, [currentTheme]);
  const passageComponent = useMemo(() => {
    return createAPIInProgress ? (
      <></>
    ) : (
      <EditorWrapper
        editMode={true}
        isPlaceHolderEnabled
        showLabel="Show passage"
        hideLabel="Hide passage"
        placeholder="Enter your passage here..."
        readOnly={answerInProgress || questionInProgress}
        isInactive={isInactive}
        allowForHidingToolbar
        forceTitle={false}
        {..._editorStyles}
        trainAnswerMode={!isParser}
        onSocketConnectionChange={onSocketConnectionChange}
        onError={handleEditorError}
        handleParse={handleParse}
        baseDocumentRedirectLink="answerer"
        handleAddDictionary={handleAddDictionary}
        initialState={initialState}
        editorReducer={editorReducer}
        editorState={editorState}
        handleOnChange={handleOnChange}
        isRefreshRequired={isRefreshRequired}
        isToolBarVisible={formatSwitch}
        value={value}
        setValue={setValue}
        setEditor={setEditorQA}
        nlpConfig={nlpConfig}
        envConfig={envConfig}
        onAnalysisCompleted={onAnalysisCompleted}
        documentId={documentId}
        maxWords={1000}
        maxCharacters={5000}
        characters={charactersCount}
        words={wordsCount}
      />
    );
  }, [
    createAPIInProgress,
    answerInProgress,
    questionInProgress,
    value,
    documentId,
    formatSwitch,
    isParser,
    charactersCount,
  ]);
  const trainerComponent = useMemo(() => {
    return createAPIInProgress ? (
      <LoadingPlaceholder
        backgroundColor={currentTheme?.theme?.transparent}
        visible={true}
        caption={`Generating document...`}
      />
    ) : (
      <EditorWrapper
        key={documentId?.toString()}
        editMode={true}
        isPlaceHolderEnabled
        showLabel="Show passage"
        hideLabel="Hide passage"
        placeholder="Enter your passage here..."
        readOnly={answerInProgress || questionInProgress}
        isInactive={isInactive}
        allowForHidingToolbar
        {..._editorStyles}
        trainAnswerMode={!isParser}
        onSocketConnectionChange={onSocketConnectionChange}
        onError={handleEditorError}
        handleParse={handleParse}
        baseDocumentRedirectLink="answerer"
        handleAddDictionary={handleAddDictionary}
        initialState={initialState}
        editorReducer={editorReducer}
        editorState={editorState}
        handleOnChange={handleOnChange}
        isRefreshRequired={isRefreshRequired}
        // isToolBarVisible={formatSwitch}
        value={value}
        setValue={setValue}
        setEditor={setEditorQA}
        nlpConfig={nlpConfig}
        envConfig={envConfig}
        onAnalysisCompleted={onAnalysisCompleted}
        documentId={documentId}
        maxWords={1000}
        maxCharacters={5000}
        characters={charactersCount}
        words={wordsCount}
      />
    );
  }, [
    createAPIInProgress,
    answerInProgress,
    questionInProgress,
    value,
    documentId,
    formatSwitch,
    isParser,
    charactersCount,
  ]);
  const generateQuestionsPlaceholder = useMemo(() => {
    if (isAutoQuestionOn)
      return `${t("SelectedTopicPlaceholderAutoQuestion")} . ${t(
        "AnalyseText"
      )}`;
    if (isTopicModeOn)
      return `${t("SelectedTopicPlaceholder")} . ${t("AnalyseText")}`;
    else return `${t("EnterPassagePlaceholder")} . ${t("AnalyseText")}`;
  }, [isAutoQuestionOn, isTopicModeOn]);

  const answererHeader = useMemo(() => {
    return (
      <AnswerScreenHeader
        handleShowTrData={handleShowTrData}
        isTopicTrainingScope={isTopicTrainingScope}
        setisTopicTrainingScopeAsTopic={setisTopicTrainingScopeAsTopic}
        isMainParsingFnDisabled={isMainParsingFnDisabled}
        isPassageNil={isPassageNil}
        questionInProgress={questionInProgress}
        answerInProgress={answerInProgress}
        documentId={documentId}
        setIsModalOpen={setIsModalOpen}
        isParser={isParser}
        handleParse={handleParse}
        isTrainerNoDocumentId={isTrainerNoDocumentId}
        isDirty={isDirty}
        data={answerResult["q&a"]}
        isUploading={isUploading}
        storeInError={storeInError}
        storeInProgress={storeInProgress}
        isSaved={isSaved}
        handleNewDocument={handleNewDocument}
        routeToParsing={routeToParsing}
        setIsParser={setIsParser}
        setIsTrainerNoDocumentId={setIsTrainerNoDocumentId}
        setIsTopicModeOn={setIsTopicModeOn}
        isTopicModeOn={isTopicModeOn}
        isAutoQuestionOn={isAutoQuestionOn}
        setIsAutoQuestionModeOn={setIsAutoQuestionModeOn}
        setIsValidationData={setIsValidationData}
        isValidationData={isValidationData}
        readyForNextQuestion={readyForNextQuestion}
        setReadyForNextQuestion={setReadyForNextQuestion}
        setAgent={setAgent}
        setPrompt={setPrompt}
        agent={agent}
        setQValue={setQValue}
        forceEditorWithValue={forceEditorWithValue}
      />
    );
  }, [
    isTopicTrainingScope,
    isMainParsingFnDisabled,
    isPassageNil,
    questionInProgress,
    answerInProgress,
    documentId,
    isParser,
    isTrainerNoDocumentId,
    isDirty,
    isUploading,
    storeInError,
    storeInProgress,
    isSaved,
    isTopicModeOn,
    isAutoQuestionOn,
    JSON.stringify(qValue),
    JSON.stringify(value),
    setIsValidationData,
    isValidationData,
    isMobileScreen(),
    isLargeScreen(),
    readyForNextQuestion,
    setAgent,
    setPrompt,
    agent,
  ]);

  const questionsComponentRenderer = useMemo(() => {
    if (createAPIInProgress)
      return (
        <LoadingPlaceholder
          backgroundColor={currentTheme?.theme?.transparent}
          visible={true}
          caption={`Generating document...`}
        />
      );

    if (!questionInProgress && !createAPIInProgress)
      return (
        <EditorWrapper
          title={t("Question")}
          // eslint-disable-next-line no-useless-concat
          allowForHidingToolbar
          hideLabel="Hide questions"
          showLabel="Show questions"
          placeholder={generateQuestionsPlaceholder}
          baseDocumentRedirectLink="answerer"
          answerMode
          onError={handleEditorError}
          forceTitle={false}
          editMode
          readOnly={
            isAutoQuestionOn || answerInProgress || outputContainsAnswer
          }
          {..._editorStyles}
          maxWords={1000}
          maxCharacters={5000}
          words={questionsWordsCount}
          characters={questionsCharactersCount}
          initialState={initialQuestionsState}
          editorReducer={editorReducer}
          handleOnChange={handleOnChange}
          isToolBarVisible={false}
          value={qValue}
          setValue={setQValue}
          setEditor={setEditor}
          wrapperTitleStyle={{
            backgroundColor: currentTheme?.theme?.transparent,
          }}
        >
          {isAutoQuestionOn && isParser && (
            <AppMultipleSelectInput
              accessibilityLabel="NERfilter"
              onSelectionChange={onSelectedNersChange}
              selectedItems={selectedNers}
              placeholder="Filter questions by NER"
              items={ners}
              uniqueKey="id"
              showDropDowns={true}
              readOnlyHeadings={true}
              labelOption={"label"}
              groupByProperty="type"
              style={{ paddingTop: 10, paddingRight: 20, maxWidth: 800 }}
            />
          )}
        </EditorWrapper>
      );
    else if (questionInProgress)
      return (
        <LoadingPlaceholder
          backgroundColor={currentTheme?.theme?.transparent}
          visible={true}
          caption={`Generating ${
            questionInProgress ? "questions" : "answers"
          }...`}
        />
      );
    else return <></>;
  }, [
    isAutoQuestionOn,
    isTopicModeOn,
    isParser,
    questionInProgress,
    answerInProgress,
    qValue,
    createAPIInProgress,
    ners?.length,
    answerInProgress,
  ]);
  const outputComponentRenderer = useMemo(() => {
    return createAPIInProgress ? (
      <></>
    ) : answerInProgress &&
      outputValue?.length > 0 &&
      outputValue[0]?.children[0]?.text == "**...**" ? (
      <ToothFairyLoadingPlaceholder
        caption="Generating analysis..."
        style={{
          paddingTop: 50,
          position: "unset",
          alignItems: "flex-start",
          flex: 1,
          flexShrink: 1,
          flexBasis: "100%",
        }}
      />
    ) : (
      <div
        className={
          markdownString?.length > 50
            ? "answerer-container"
            : "answerer-container-empty"
        }
      >
        <AppText
          onCopy={handleOutputCopy}
          S3downloadUrlRequest={S3downloadUrlRequest}
          workspaceid={getActiveWorkspace()?.id}
          renderMarkdown={true}
          showCopyButton={outputValue?.length > 0}
          wrapperStyle={{
            alignItems: "flex-start",
            flex: 1,
            flexShrink: 1,
            flexBasis: "100%",
            // backgroundColor: currentTheme?.theme?.softBackgroundColor,
            borderWidth: 1,
            borderRadius: 10,
            borderColor: currentTheme?.theme?.border_color,
            padding: 10,
            height: "100%",
          }}
          iconSize={16}
          style={{
            color: currentTheme?.theme?.font_color,
            fontWeight: "400",
            lineHeight: 18,
            marginLeft: 10,
          }}
        >
          {markdownString?.length > 0 || answerInProgress
            ? decorateOutputString(markdownString, answerInProgress)
            : "# Analysis output  \nClick on 'Analyse' to start the analysis"}
        </AppText>
      </div>
    );
  }, [
    isTopicModeOn,
    isParser,
    answerInProgress,
    JSON.stringify(outputValue),
    createAPIInProgress,
    S3downloadUrlRequest,
    getActiveWorkspace()?.id,
    markdownString,
  ]);
  // useEffect on unmount
  useEffect(() => {
    setDelayOnRender(true);
    setTimeout(() => {
      setDelayOnRender(false);
    }, [150]);
    return () => {
      setIsParser(true);
      setDelayOnRender(true);
    };
  }, [isParser]);
  //
  return (
    <View style={_styles.container}>
      {answererHeader}

      {optionsModal()}

      <View
        style={{
          flex: 1,
          justifyContent: "flex-start",
          minHeight: isTopicModeOn ? 400 : 500,
        }}
      >
        {(isTopicModeOn || isTopicTrainingScope) &&
          isParser &&
          topicSelectionComponent}
        {actionsButtons}
        {(!isTopicModeOn || isTopicTrainingScope) &&
          isParser &&
          !delayOnRender &&
          passageComponent}
        {!isParser && !delayOnRender && trainerComponent}
        {isParser &&
          !isTopicTrainingScope &&
          !delayOnRender &&
          questionsComponentRenderer}
        {isParser && outputComponentRenderer}
      </View>

      {outputModal}
      {trainingDataModal}
      {topicCreationComponent}
      {dictonaryItemCreationComponent}
      {saveOutputComponent}
    </View>
  );
};

const styleGenerator = ({ flexDirection, isParser, currentTheme }) => {
  return StyleSheet.create({
    container: {
      flexDirection: flexDirection,
      flex: 1,
      overflow: Platform.OS === "web" ? "auto" : "scroll",
      justifyContent: isParser ? "space-between" : "flex-start",
      paddingHorizontal: 20,
      paddingBottom: 10,
      paddingTop: 5,
      minHeight: 400,
    },
  });
};

export default AnswererScreen;
