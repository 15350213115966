import styled from "@emotion/native";
import { AppIcon, AppText } from "@toothfairy/shared-ui";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React from "react";
import { View } from "react-native";
import { useRouteMatch } from "../../Router";
import { MenuLink } from "./index";

const MenuIcon = styled(AppIcon)`
  margin-right: 20px;
`;

export const MenuItem = ({
  icon,
  label,
  to,
  current,
  accessibilityLabel,
  ...props
}) => {
  const _current = useRouteMatch(to)?.isExact;
  const { currentTheme } = AppTheme.useTheme();
  const _viewMemo = React.useMemo(() => {
    return (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          paddingVertical: 10,
          paddingHorizontal: 20,
          width: "100%",
          backgroundColor:
            (_current || current) && currentTheme?.theme?.primary,
        }}
        {...props}
      >
        <MenuLink to={to}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "end",
              width: "100%",
            }}
          >
            {icon && (
              <MenuIcon
                icon={icon}
                size={20}
                isActive={_current || current}
                activeColor={currentTheme?.theme?.black}
              />
            )}
            <AppText
              accessibilityLabel={accessibilityLabel}
              color={
                _current || current
                  ? currentTheme?.theme?.black
                  : currentTheme?.theme?.font_color
              }
              style={{
                fontWeight: "800",
              }}
              current={_current || current}
              isHoverable
            >
              {label}
            </AppText>
          </View>
        </MenuLink>
      </View>
    );
  }, [
    currentTheme,
    JSON.stringify(_current),
    JSON.stringify(current),
    to,
    label,
  ]);
  return _viewMemo;
};
