import useAPI from "@toothfairy/shared-api/useApi";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";
import AppForms from "@toothfairy/shared-ui/AppForms";
import AppToast from "@toothfairy/shared-ui/AppToast";
import { AppText } from "@toothfairy/shared-ui";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { useEffect, useMemo, useState, useRef } from "react";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import envConfig from "../../../envConfig";
import Files from "../../API/Files";
import useWorkspaces from "../../Hooks/useWorkspaces";
import ToothFairyButton from "../ToothFairyComponents/ToothFairyButton";
import { Platform, View, useWindowDimensions } from "react-native";
import ToothFairyModal from "../ToothFairyComponents/ToothFairyModal";
import Constants from "../../Constants";
import { isLargeScreen } from "../../Screens/MainViewContainer";
import trainingAPI from "../../API/Training/trainingAPI";
import DelayedRender from "../DelayRenderer";
import LoadingPlaceholder from "../LoadingPlaceholder";
import hljs from "highlight.js";

const GenericViewer = ({
  path,
  onPDFDocumentLoad = () => {},
  documentId,
  isPDFLoaded,
}) => {
  const [url, setUrl] = useState(null);
  const AppToasty = AppToast.useToast();
  const { height } = useWindowDimensions();
  const isTabularFile =
    path?.split(".").pop() === "xlsx" ||
    path?.split(".").pop() === "csv" ||
    path?.split(".").pop() === "xlsm";
  const [highlightedCode, setHighlightedCode] = useState("");
  const codeFilesExtensions = [
    "py",
    "java",
    "yaml",
    "yml",
    "sql",
    "sh",
    "php",
    "js",
    "ts",
    "tsx",
    "jsx",
    "csharp",
    "rb",
  ];
  const isCode = codeFilesExtensions.includes(path?.split(".").pop());

  const [content, setContent] = useState("");
  const { currentTheme, mode } = AppTheme.useTheme();
  const { getActiveWorkspace } = useWorkspaces();
  const {
    data: S3downloadUrlData,
    loading: urldownloadGenerationInProgress,
    apiRequest: S3downloadUrlRequest,
    response: S3downloadResponse,
  } = useAPI(Files.downloadUrlGeneration, envConfig);
  const {
    loading: fileMetaDataSavingInProgress,
    apiRequest: updateDocumentFileMetaData,
  } = useAPI(trainingAPI.updateTrainingData, null, () => {});
  const [metaDescription, setMetaDescription] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [chunkingModalOpen, setChunkingModalOpen] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const {
    data: getAPIResult,
    error: getAPIError,
    loading: getAPIInProgress,
    apiRequest: getAPIRequest,
    lastUpdate: lastrequestTime,
  } = useAPI(trainingAPI.getDocumentById, envConfig);
  const formValidationSchema = AppForms.yup.object().shape({
    file_description: AppForms.yup
      .string()
      .nullable()
      .label("File description")
      .max(500),
  });
  const fetchDocumentData = async (documentId) => {
    try {
      const response = await getAPIRequest(
        documentId,
        [
          "file_description",
          "workspaceid",
          "tabular_chunk_size",
          "tabular_overlap",
          "tabular_separator",
        ],
        getActiveWorkspace()?.id
      );
      if (response?.data?.length > 0 && response?.data[0])
        setInitialValues({
          file_description: response?.data[0]?.file_description,
          tabular_chunk_size: response?.data[0]?.tabular_chunk_size || 10,
          tabular_overlap: response?.data[0]?.tabular_overlap || 1,
          tabular_separator: response?.data[0]?.tabular_separator || ",",
        });
    } catch (error) {
      console.error("Error while fetching document data", error);
    }
  };
  const mapPathToLanguage = (path) => {
    switch (path?.split(".").pop()) {
      case "py":
        return "python";
      case "java":
        return "java";
      case "yaml":
        return "yaml";
      case "yml":
        return "yaml";
      case "sql":
        return "sql";
      case "sh":
        return "bash";
      case "php":
        return "php";
      case "js":
        return "javascript";
      case "ts":
        return "typescript";
      case "csharp":
        return "csharp";
      case "rb":
        return "ruby";
      default:
        return "javascript";
    }
  };
  useEffect(() => {
    const highlightCode = () => {
      const code = hljs.highlight(content, {
        language: mapPathToLanguage(path),
        ignoreIllegals: true,
      }).value;
      setHighlightedCode(code);
    };

    highlightCode();
    // Cleanup function
    return () => {
      setHighlightedCode("");
    };
  }, [path, mode, content]);
  useEffect(() => {
    if (documentId && getActiveWorkspace()?.id) {
      fetchDocumentData(documentId);
    }
  }, [documentId, getActiveWorkspace()?.id]);
  const handleChunkingFormSubmit = async (formValues) => {
    try {
      await updateDocumentFileMetaData({
        fields: {
          id: documentId,
          workspaceid: getActiveWorkspace()?.id,
          tabular_chunk_size: formValues.tabular_chunk_size,
          tabular_overlap: formValues.tabular_overlap,
          tabular_separator: formValues.tabular_separator,
        },
      });
      AppToasty.show("Chunking configuration updated successfully", {
        type: "success",
      });
      setModalOpen(false);
    } catch (error) {
      console.error("Error while updating the chunking configuration", error);
      AppToasty.show("Error while updating the chunking configuration", {
        type: "danger",
      });
    }
  };
  const handleFormSubmit = async (formValues) => {
    try {
      await updateDocumentFileMetaData({
        fields: {
          id: documentId,
          workspaceid: getActiveWorkspace()?.id,
          file_description: formValues.file_description,
        },
      });
      AppToasty.show("Meta description updated successfully", {
        type: "success",
      });
      setModalOpen(false);
    } catch (error) {
      console.error("Error while updating meta description", error);
      AppToasty.show("Error while updating meta description", {
        type: "danger",
      });
    }
  };
  const metaUpdateComponent = useMemo(() => {
    return (
      <ToothFairyModal
        modalStyle={{
          height: 500,
          width: "90%",
          maxWidth: isLargeScreen() ? 750 : 350,
          marginHorizontal: "auto",
          marginTop: "auto",
          marginBottom: "auto",
          margin: "auto",
        }}
        isModalOpen={modalOpen}
        setIsModalOpen={setModalOpen}
        modalTitle="Update meta description"
      >
        <AppForms.AppForm
          style={{
            minWidth: Platform.OS === "web" && isLargeScreen() ? 350 : 300,
            // maxWidth: 600,
            alignItems: "center",
          }}
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          validationSchema={formValidationSchema}
          enableReinitialize
        >
          <AppForms.AppFormField
            showTextInputLabel
            formFieldContainerStyle={{
              width: "100%",
            }}
            disabled={fileMetaDataSavingInProgress}
            wrapperInputStyle={{
              height: 340,
              marginBottom: 10,
              padding: 10,
            }}
            multiline
            textContainer={{
              padding: 10,
              marginHorizontal: 20,
            }}
            label={`File description`}
            placeholder={`Describe what the file is about`}
            keyboardtype="default"
            textContextType="label"
            autoCorrect={false}
            name="file_description"
          />
          <AppForms.AppSubmitButton
            title={"Update"}
            isDisabled={fileMetaDataSavingInProgress}
            awesomeIconColor={currentTheme?.theme?.white}
            backgroundColor={currentTheme?.theme?.primary}
            btnContentStyle={{
              paddingLeft: 0,
              paddingRight: 0,
            }}
            style={[
              {
                height: Constants.appButtonHeight,
                width: "auto",
                shadowColor: currentTheme?.theme?.transparent,
                marginHorizontal: 10,
              },
            ]}
            btnLabelStyle={{ fontSize: 16 }}
            wrapperStyle={[
              {
                width: "fit-content",
                minWidth: 150,

                height: Constants.appButtonHeight,
              },
            ]}
            // backgroundColor={currentTheme?.theme?.green}

            // awesomeIcon={AppAssets.icons.add_icon}
            // awesomeIconColor={currentTheme?.theme?.white}
          />
        </AppForms.AppForm>
      </ToothFairyModal>
    );
  }, [modalOpen, setModalOpen, fileMetaDataSavingInProgress]);

  const chunkingMetaComponent = useMemo(() => {
    return (
      <ToothFairyModal
        modalStyle={{
          height: path?.split(".").pop() === "csv" ? 408 : 340,
          width: "90%",
          maxWidth: isLargeScreen() ? 750 : 350,
          marginHorizontal: "auto",
          marginTop: "auto",
          marginBottom: "auto",
          margin: "auto",
        }}
        isModalOpen={chunkingModalOpen}
        setIsModalOpen={setChunkingModalOpen}
        modalTitle="Update chunking configuration"
      >
        <AppForms.AppForm
          style={{
            minWidth: Platform.OS === "web" && isLargeScreen() ? 350 : 300,
            // maxWidth: 600,
            alignItems: "center",
          }}
          initialValues={initialValues}
          onSubmit={handleChunkingFormSubmit}
          validationSchema={formValidationSchema}
          enableReinitialize
        >
          <AppForms.AppFormSlider
            step={1}
            maximumValue={50}
            minimumValue={1}
            label="Chunking rows"
            name="tabular_chunk_size"
            // showEditButtons={true}
            containerStyle={{
              width: 300,
              marginBottom: 10,
            }}
          />
          <AppForms.AppFormSlider
            step={1}
            maximumValue={5}
            minimumValue={1}
            label="Rows overlap"
            name="tabular_overlap"
            // showEditButtons={true}
            containerStyle={{
              width: 300,
              marginBottom: 10,
            }}
          />
          {path?.split(".").pop() === "csv" && (
            <AppForms.AppFormField
              formFieldContainerStyle={{
                marginLeft: -85,
              }}
              textInputLabelStyle={{
                marginLeft: 0,
              }}
              showTextInputLabel
              label="Separator"
              name="tabular_separator"
              maxCharacters={2}
              inputType="prompt"
              validationErrorStyle={{
                display: "none",
              }}
            />
          )}

          <AppText
            leftIcon={AppAssets?.icons?.info}
            wrapperStyle={{
              paddingHorizontal: 10,
              maxWidth: 460,
              marginBottom: 10,
            }}
            color={currentTheme?.theme?.orange}
            style={{
              marginLeft: 10,
            }}
            iconSize={14}
          >
            Chunking 50 rows with 1 row overlap means that each chunk will
            include 50 rows for any given sheet with 1 row of overlap with the
            subsequent row.
          </AppText>
          <AppForms.AppSubmitButton
            title={"Update"}
            isDisabled={fileMetaDataSavingInProgress}
            awesomeIconColor={currentTheme?.theme?.white}
            backgroundColor={currentTheme?.theme?.primary}
            btnContentStyle={{
              paddingLeft: 0,
              paddingRight: 0,
            }}
            style={[
              {
                height: Constants.appButtonHeight,
                width: "auto",
                shadowColor: currentTheme?.theme?.transparent,
                marginHorizontal: 10,
              },
            ]}
            btnLabelStyle={{ fontSize: 16 }}
            wrapperStyle={[
              {
                width: "fit-content",
                minWidth: 150,

                height: Constants.appButtonHeight,
              },
            ]}
            // backgroundColor={currentTheme?.theme?.green}

            // awesomeIcon={AppAssets.icons.add_icon}
            // awesomeIconColor={currentTheme?.theme?.white}
          />
        </AppForms.AppForm>
      </ToothFairyModal>
    );
  }, [chunkingModalOpen, setChunkingModalOpen, fileMetaDataSavingInProgress]);

  const getUrl = async (openNewTab = false) => {
    const _url = await S3downloadUrlRequest({
      workspaceid: getActiveWorkspace()?.id,
      logId: null,
      type: path?.split(".").pop(),
      filename: path.replace(
        `s3://files-to-import-aus-${envConfig.REACT_APP_ENV}/`,
        ""
      ),
      context: "pdf",
    });
    setUrl(_url);
    if (openNewTab)
      // trigger the download
      window.open(_url?.url, "_blank");
  };
  useEffect(() => {
    if (!path) return;
    getUrl();
  }, [path]);
  useEffect(() => {
    if (
      url &&
      (path?.split(".").pop() === "txt" ||
        path?.split(".").pop() === "md" ||
        isCode)
    )
      fetch(url?.url)
        .then((response) => {
          return response.text();
        })
        .then((text) => {
          setContent(text);
          onPDFDocumentLoad();
        })
        .catch((error) => console.error(error));
    // else if the extension is docs, ppt, xls and csv jusr call onPDFDocumentLoad
    else if (
      url &&
      (path?.split(".").pop() === "docx" ||
        path?.split(".").pop() === "pptx" ||
        path?.split(".").pop() === "xlsx" ||
        path?.split(".").pop() === "csv" ||
        path?.split(".").pop() === "xlsm" ||
        path?.split(".").pop() === "ppt" ||
        path?.split(".").pop() === "html" ||
        isCode)
    ) {
      setTimeout(() => {
        onPDFDocumentLoad();
      }, [20]);
    }
    return () => {
      setContent("");
    };
  }, [url, path, isPDFLoaded]);
  if (isCode) {
    return (
      <>
        <DelayedRender>
          <View
            style={{
              marginTop: -20,
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <AppText
              isClickable
              isHoverable={false}
              color={currentTheme?.theme?.primary}
              style={{
                fontWeight: "bold",
              }}
              wrapperStyle={{
                marginLeft: 10,
              }}
              onPress={() => {
                setModalOpen(true);
              }}
            >
              Update meta
            </AppText>
            {(initialValues?.file_description == null ||
              initialValues?.file_description == "") && (
              <AppText
                color={currentTheme?.theme?.red}
                style={{
                  fontWeight: "bold",
                }}
                wrapperStyle={{
                  marginLeft: 10,
                  maxWidth: 230,
                }}
              >
                Meta description not available, the file will not be searchable
              </AppText>
            )}
          </View>
          <View
            style={{
              borderRadius: 10,
              padding: 10,
              border: `1px solid ${currentTheme?.theme?.border_color}`,
              marginLeft: 10,
              marginVertical: 10,
              height: height - 230,
              overflow: "auto",
              backgroundColor: currentTheme?.theme?.inputBackgroundColor,
            }}
          >
            <pre
              style={{
                margin: 0,
              }}
            >
              <code className={`language-${mapPathToLanguage(path)}`}>
                <pre>
                  <code
                    dangerouslySetInnerHTML={{ __html: highlightedCode }}
                    className={`hljs ${mode}`}
                    style={{
                      backgroundColor: currentTheme?.theme?.transparent,
                    }}
                  ></code>
                </pre>
              </code>
            </pre>
          </View>
        </DelayedRender>
        {metaUpdateComponent}
      </>
    );
  } else if (path?.split(".").pop() === "html") {
    return (
      <DelayedRender>
        <iframe
          src={url?.url}
          onLoad={onPDFDocumentLoad}
          style={{
            width: "auto",
            height: "100vh",
            border: "none",
            padding: 20,
            backgroundColor: currentTheme?.theme?.white,
            display: !isPDFLoaded && "none",
          }}
          title="HTML Content"
        />
      </DelayedRender>
    );
  } else if (
    path?.split(".").pop() === "txt" ||
    path?.split(".").pop() === "md"
  )
    return (
      <DelayedRender onRender={onPDFDocumentLoad}>
        <LoadingPlaceholder
          style={{
            padding: 10,
          }}
          visible={!isPDFLoaded}
          caption="Loading..."
        />
        <pre
          style={{
            width: "auto",
            height: "100vh",
            overflow: "auto",
            border: "none",
            padding: 20,
            backgroundColor: currentTheme?.theme?.white,
            display: !isPDFLoaded && "none",
          }}
        >
          {content}
        </pre>
      </DelayedRender>
    );

  return (
    <DelayedRender delay={0} key={documentId}>
      <View
        style={{
          maxWidth: 500,
          height: 160,
          marginHorizontal: "auto",
          alignItems: "center",
        }}
      >
        <AppPlaceholderView
          accessibilityLabel="doc-placeholder"
          size={60}
          icon={AppAssets.icons.download}
          mainCaption={"DocumentCannotBeViewedCaption"}
          subCaption={"DocumentCannotBeViewedSubCaption"}
        />
        <ToothFairyButton
          onPress={() => {
            getUrl(true);
          }}
          wrapperStyle={{
            alignSelf: "center",
          }}
          title="Download"
        />
        {isCode && (
          <AppText
            isClickable
            color={currentTheme?.theme?.primary}
            style={{
              fontWeight: "bold",
            }}
            wrapperStyle={{
              marginTop: 10,
            }}
            onPress={() => {
              setModalOpen(true);
            }}
          >
            Update meta
          </AppText>
        )}
        {isTabularFile && (
          <AppText
            isClickable
            color={currentTheme?.theme?.primary}
            style={{
              fontWeight: "bold",
            }}
            wrapperStyle={{
              marginTop: 10,
            }}
            onPress={() => {
              setChunkingModalOpen(true);
            }}
          >
            Update chunking configuration
          </AppText>
        )}

        {metaUpdateComponent}
        {chunkingMetaComponent}
      </View>
    </DelayedRender>
  );
};

export default GenericViewer;
