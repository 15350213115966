import { faAdjust } from '@fortawesome/pro-light-svg-icons/faAdjust';
import { faAlignCenter } from '@fortawesome/pro-light-svg-icons/faAlignCenter';
import { faAlignLeft } from '@fortawesome/pro-light-svg-icons/faAlignLeft';
import { faAlignRight } from '@fortawesome/pro-light-svg-icons/faAlignRight';
import { faArrowCircleRight } from '@fortawesome/pro-light-svg-icons/faArrowCircleRight';
import { faArrowSquareLeft } from '@fortawesome/pro-light-svg-icons/faArrowSquareLeft';
import { faArrowToRight } from '@fortawesome/pro-light-svg-icons/faArrowToRight';
import { faBan } from '@fortawesome/pro-light-svg-icons/faBan';
import { faBars } from '@fortawesome/pro-light-svg-icons/faBars';
import { faBell } from '@fortawesome/pro-light-svg-icons/faBell';
import { faBezierCurve } from '@fortawesome/pro-light-svg-icons/faBezierCurve';
import { faBold } from '@fortawesome/pro-light-svg-icons/faBold';
import { faBookOpen } from '@fortawesome/pro-light-svg-icons/faBookOpen';
import { faBookReader } from '@fortawesome/pro-light-svg-icons/faBookReader';
import { faBookmark } from '@fortawesome/pro-light-svg-icons/faBookmark';
import { faBooks } from '@fortawesome/pro-light-svg-icons/faBooks';
import { faBracketsCurly } from '@fortawesome/pro-light-svg-icons/faBracketsCurly';
import { faBrain } from '@fortawesome/pro-light-svg-icons/faBrain';
import { faHeadSideBrain } from '@fortawesome/pro-light-svg-icons/faHeadSideBrain';
import { faBrowser } from '@fortawesome/pro-light-svg-icons/faBrowser';
import { faCalendarDay } from '@fortawesome/pro-light-svg-icons/faCalendarDay';
import { faCalendarEdit } from '@fortawesome/pro-light-svg-icons/faCalendarEdit';
import { faFileChartLine } from '@fortawesome/pro-light-svg-icons/faFileChartLine';
import { faChartNetwork } from '@fortawesome/pro-light-svg-icons/faChartNetwork';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import { faChevronDoubleUp } from '@fortawesome/pro-light-svg-icons/faChevronDoubleUp';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import { faScannerTouchscreen } from '@fortawesome/pro-light-svg-icons/faScannerTouchscreen';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { faChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp';
import { faCircle } from '@fortawesome/pro-light-svg-icons/faCircle';
import { faClipboard } from '@fortawesome/pro-light-svg-icons/faClipboard';
import { faClipboardListCheck } from '@fortawesome/pro-light-svg-icons/faClipboardListCheck';
import { faSparkles } from '@fortawesome/pro-light-svg-icons/faSparkles';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { faClone } from '@fortawesome/pro-light-svg-icons/faClone';
import { faCloudDownload } from '@fortawesome/pro-light-svg-icons/faCloudDownload';
import { faCloudUpload } from '@fortawesome/pro-light-svg-icons/faCloudUpload';
import { faCode } from '@fortawesome/pro-light-svg-icons/faCode';
import { faCodeMerge } from '@fortawesome/pro-light-svg-icons/faCodeMerge';
import { faCog } from '@fortawesome/pro-light-svg-icons/faCog';
import { faCommentAltLines } from '@fortawesome/pro-light-svg-icons/faCommentAltLines';
import { faCommentLines } from '@fortawesome/pro-light-svg-icons/faCommentLines';
import { faCommentsAlt } from '@fortawesome/pro-light-svg-icons/faCommentsAlt';
import { faCompressAlt } from '@fortawesome/pro-light-svg-icons/faCompressAlt';
import { faCopy } from '@fortawesome/pro-light-svg-icons/faCopy';
import { faCreditCard } from '@fortawesome/pro-light-svg-icons/faCreditCard';
import { faDatabase } from '@fortawesome/pro-light-svg-icons/faDatabase';
import { faDollarSign } from '@fortawesome/pro-light-svg-icons/faDollarSign';
import { faEdit } from '@fortawesome/pro-light-svg-icons/faEdit';
import { faEllipsisH } from '@fortawesome/pro-light-svg-icons/faEllipsisH';
import { faEllipsisV } from '@fortawesome/pro-light-svg-icons/faEllipsisV';
import { faEnvelopeOpenText } from '@fortawesome/pro-light-svg-icons/faEnvelopeOpenText';
import { faEraser } from '@fortawesome/pro-light-svg-icons/faEraser';
import { faExchangeAlt } from '@fortawesome/pro-light-svg-icons/faExchangeAlt';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
import { faExpandAlt } from '@fortawesome/pro-light-svg-icons/faExpandAlt';
import { faExpandWide } from '@fortawesome/pro-light-svg-icons/faExpandWide';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons/faEyeSlash';
import { faFile } from '@fortawesome/pro-light-svg-icons/faFile';
import { faFileAlt } from '@fortawesome/pro-light-svg-icons/faFileAlt';
import { faFileCode } from '@fortawesome/pro-light-svg-icons/faFileCode';
import { faFileCsv } from '@fortawesome/pro-light-svg-icons/faFileCsv';
import { faFileExcel } from '@fortawesome/pro-light-svg-icons/faFileExcel';
import { faFileExport } from '@fortawesome/pro-light-svg-icons/faFileExport';
import { faFileImport } from '@fortawesome/pro-light-svg-icons/faFileImport';
import { faFileInvoiceDollar } from '@fortawesome/pro-light-svg-icons/faFileInvoiceDollar';
import { faFilePdf } from '@fortawesome/pro-light-svg-icons/faFilePdf';
import { faFilePowerpoint } from '@fortawesome/pro-light-svg-icons/faFilePowerpoint';
import { faFileSignature } from '@fortawesome/pro-light-svg-icons/faFileSignature';
import { faFileUpload } from '@fortawesome/pro-light-svg-icons/faFileUpload';
import { faFileWord } from '@fortawesome/pro-light-svg-icons/faFileWord';
import { faFolder } from '@fortawesome/pro-light-svg-icons/faFolder';
import { faFont } from '@fortawesome/pro-light-svg-icons/faFont';
import { faGameBoardAlt } from '@fortawesome/pro-light-svg-icons/faGameBoardAlt';
import { faGlobeEurope } from '@fortawesome/pro-light-svg-icons/faGlobeEurope';
import { faGripLinesVertical } from '@fortawesome/pro-light-svg-icons/faGripLinesVertical';
import { faGripVertical } from '@fortawesome/pro-light-svg-icons/faGripVertical';
import { faH1 } from '@fortawesome/pro-light-svg-icons/faH1';
import { faH2 } from '@fortawesome/pro-light-svg-icons/faH2';
import { faHashtag } from '@fortawesome/pro-light-svg-icons/faHashtag';
import { faHighlighter } from '@fortawesome/pro-light-svg-icons/faHighlighter';
import { faHistory } from '@fortawesome/pro-light-svg-icons/faHistory';
import { faHomeAlt } from '@fortawesome/pro-light-svg-icons/faHomeAlt';
import { faImage } from '@fortawesome/pro-light-svg-icons/faImage';
import { faInbox } from '@fortawesome/pro-light-svg-icons/faInbox';
import { faIndent } from '@fortawesome/pro-light-svg-icons/faIndent';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import { faItalic } from '@fortawesome/pro-light-svg-icons/faItalic';
import { faKeyboard } from '@fortawesome/pro-light-svg-icons/faKeyboard';
import { faLanguage } from '@fortawesome/pro-light-svg-icons/faLanguage';
import { faLaptopCode } from '@fortawesome/pro-light-svg-icons/faLaptopCode';
import { faLayerGroup } from '@fortawesome/pro-light-svg-icons/faLayerGroup';
import { faLightbulbExclamation } from '@fortawesome/pro-light-svg-icons/faLightbulbExclamation';
import { faLink } from '@fortawesome/pro-light-svg-icons/faLink';
import { faListOl } from '@fortawesome/pro-light-svg-icons/faListOl';
import { faListUl } from '@fortawesome/pro-light-svg-icons/faListUl';
import { faLock } from '@fortawesome/pro-light-svg-icons/faLock';
import { faLockOpen } from '@fortawesome/pro-light-svg-icons/faLockOpen';
import { faMeteor } from '@fortawesome/pro-light-svg-icons/faMeteor';
import { faMicrophone } from '@fortawesome/pro-light-svg-icons/faMicrophone';
import { faMoneyCheckAlt } from '@fortawesome/pro-light-svg-icons/faMoneyCheckAlt';
import { faNetworkWired } from '@fortawesome/pro-light-svg-icons/faNetworkWired';
import { faPaintRoller } from '@fortawesome/pro-light-svg-icons/faPaintRoller';
import { faPaperPlaneTop } from '@fortawesome/pro-light-svg-icons/faPaperPlaneTop';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { faPlanetRinged } from '@fortawesome/pro-light-svg-icons/faPlanetRinged';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons/faPlusCircle';
import { faPowerOff } from '@fortawesome/pro-light-svg-icons/faPowerOff';
import { faQuestion } from '@fortawesome/pro-light-svg-icons/faQuestion';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons/faQuestionCircle';
import { faQuoteLeft } from '@fortawesome/pro-light-svg-icons/faQuoteLeft';
import { faRedo } from '@fortawesome/pro-light-svg-icons/faRedo';
import { faReply } from '@fortawesome/pro-light-svg-icons/faReply';
import { faRocketLaunch } from '@fortawesome/pro-light-svg-icons/faRocketLaunch';
import { faSatelliteDish } from '@fortawesome/pro-light-svg-icons/faSatelliteDish';
import { faScrubber } from '@fortawesome/pro-light-svg-icons/faScrubber';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { faSearchMinus } from '@fortawesome/pro-light-svg-icons/faSearchMinus';
import { faSearchPlus } from '@fortawesome/pro-light-svg-icons/faSearchPlus';
import { faShareAlt } from '@fortawesome/pro-light-svg-icons/faShareAlt';
import { faSignOut } from '@fortawesome/pro-light-svg-icons/faSignOut';
import { faSlidersV } from '@fortawesome/pro-light-svg-icons/faSlidersV';
import { faSlidersVSquare } from '@fortawesome/pro-light-svg-icons/faSlidersVSquare';
import { faSpinner } from '@fortawesome/pro-light-svg-icons/faSpinner';
import { faStopCircle } from '@fortawesome/pro-light-svg-icons/faStopCircle';
import { faStream } from '@fortawesome/pro-light-svg-icons/faStream';
import { faStrikethrough } from '@fortawesome/pro-light-svg-icons/faStrikethrough';
import { faSync } from '@fortawesome/pro-light-svg-icons/faSync';
import { faTag } from '@fortawesome/pro-light-svg-icons/faTag';
import { faTerminal } from '@fortawesome/pro-light-svg-icons/faTerminal';
import { faThumbsDown } from '@fortawesome/pro-light-svg-icons/faThumbsDown';
import { faThumbsUp } from '@fortawesome/pro-light-svg-icons/faThumbsUp';
import { faThumbtack } from '@fortawesome/pro-light-svg-icons/faThumbtack';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons/faTimesCircle';
import { faTrash } from '@fortawesome/pro-light-svg-icons/faTrash';
import { faTypewriter } from '@fortawesome/pro-light-svg-icons/faTypewriter';
import { faUnderline } from '@fortawesome/pro-light-svg-icons/faUnderline';
import { faUndo } from '@fortawesome/pro-light-svg-icons/faUndo';
import { faUnlink } from '@fortawesome/pro-light-svg-icons/faUnlink';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { faUserCircle } from '@fortawesome/pro-light-svg-icons/faUserCircle';
import { faUserLock } from '@fortawesome/pro-light-svg-icons/faUserLock';
import { faUserRobot } from '@fortawesome/pro-light-svg-icons/faUserRobot';
import { faUserSecret } from '@fortawesome/pro-light-svg-icons/faUserSecret';
import { faUserShield } from '@fortawesome/pro-light-svg-icons/faUserShield';
import { faUsers } from '@fortawesome/pro-light-svg-icons/faUsers';
import { faUsersCog } from '@fortawesome/pro-light-svg-icons/faUsersCog';
import { faVial } from '@fortawesome/pro-light-svg-icons/faVial';
import { faWaveSquare } from '@fortawesome/pro-light-svg-icons/faWaveSquare';
import { faWifiSlash } from '@fortawesome/pro-light-svg-icons/faWifiSlash';
import { faWindowMinimize } from '@fortawesome/pro-light-svg-icons/faWindowMinimize';
import { faChartBar } from '@fortawesome/pro-light-svg-icons/faChartBar';
import { faSlidersH } from '@fortawesome/pro-light-svg-icons/faSlidersH';
import { faPageBreak } from '@fortawesome/pro-light-svg-icons/faPageBreak';
import { faServer } from '@fortawesome/pro-light-svg-icons/faServer';
import { faTools } from '@fortawesome/pro-light-svg-icons/faTools';
import { faAnalytics } from '@fortawesome/pro-light-svg-icons/faAnalytics';
import { faTrophy } from '@fortawesome/pro-light-svg-icons/faTrophy';
import { faDice } from '@fortawesome/pro-light-svg-icons/faDice';
import { faSwords } from '@fortawesome/pro-light-svg-icons/faSwords';
import { faBoxingGlove } from '@fortawesome/pro-light-svg-icons/faBoxingGlove';
import { faRandom } from '@fortawesome/pro-light-svg-icons/faRandom';
import { faVideoPlus } from '@fortawesome/pro-light-svg-icons/faVideoPlus';
import { faFileAudio } from '@fortawesome/pro-light-svg-icons/faFileAudio';
import { faMagic } from '@fortawesome/pro-light-svg-icons/faMagic';
import { faSitemap } from '@fortawesome/pro-light-svg-icons/faSitemap';
import { faFiles } from '@fortawesome/pro-light-svg-icons/faFiles';
import { faUserShakespeare } from '@fortawesome/pro-light-svg-icons/faUserShakespeare';
import '@fortawesome/pro-light-svg-icons/faUserPilot';
import { faUserPilotTie } from '@fortawesome/pro-light-svg-icons/faUserPilotTie';
import { faUserRobotXmarks } from '@fortawesome/pro-light-svg-icons/faUserRobotXmarks';
import { faUserVisor } from '@fortawesome/pro-light-svg-icons/faUserVisor';
import { faUserNinja } from '@fortawesome/pro-light-svg-icons/faUserNinja';
import { faUserGear } from '@fortawesome/pro-light-svg-icons/faUserGear';
import { faArrowTurnDownRight } from '@fortawesome/pro-light-svg-icons/faArrowTurnDownRight';
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons/faTriangleExclamation';
import { faMessageBot } from '@fortawesome/pro-light-svg-icons/faMessageBot';
import { faVolume } from '@fortawesome/pro-light-svg-icons/faVolume';
import { faVolumeSlash } from '@fortawesome/pro-light-svg-icons/faVolumeSlash';
import { faImages } from '@fortawesome/pro-light-svg-icons/faImages';
import '@fortawesome/pro-light-svg-icons/faSwordLaser';
import { faSwordsLaser } from '@fortawesome/pro-light-svg-icons/faSwordsLaser';
import { faHelmetBattle } from '@fortawesome/pro-light-svg-icons/faHelmetBattle';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/pro-light-svg-icons/faUpRightAndDownLeftFromCenter';
import { faDownLeftAndUpRightToCenter } from '@fortawesome/pro-light-svg-icons/faDownLeftAndUpRightToCenter';
import { faFileSpreadsheet } from '@fortawesome/pro-light-svg-icons/faFileSpreadsheet';
import { faPresentationScreen } from '@fortawesome/pro-light-svg-icons/faPresentationScreen';
import { faFileInvoice } from '@fortawesome/pro-light-svg-icons/faFileInvoice';
import { faChalkboardTeacher } from '@fortawesome/pro-light-svg-icons/faChalkboardTeacher';

var AppAssets = {
  logo: "appLogo.png?1j5ou2-",
  darkLogo: "appLogoDark.png?1QmeT7S",
  social: {
    google: "googleSocialIconCompressed.png?21tzkLJ",
    facebook: "logo_facebookCompressed.png?3PXGfBq",
    apple: "logoAppleCompressed.png?K8WhQl6",
    create: "logo_createaccount.png?3TZcjMJ",
    simpleCircle: faScrubber
  },
  icons: {
    agentsPlan: faSitemap,
    docx: faFileWord,
    api: faBezierCurve,
    ppt: faFilePowerpoint,
    pptx: faFilePowerpoint,
    csv: faFileCsv,
    xlsx: faFileExcel,
    xlsm: faFileExcel,
    download: faCloudDownload,
    account: faUser,
    magic: faMagic,
    thumbsUp: faThumbsUp,
    thumbsDown: faThumbsDown,
    new: faSparkles,
    summary: faIndent,
    handoff: faRandom,
    add_icon: faPlusCircle,
    alignCenter: faAlignCenter,
    alignLeft: faAlignLeft,
    alignRight: faAlignRight,
    arrow_down: faChevronDown,
    arrow_up: faChevronUp,
    arrow_left: faChevronLeft,
    arrow_right: faChevronRight,
    winner: faTrophy,
    boxing: faBoxingGlove,
    round: faDice,
    swords: faSwords,
    simpleCircle: faScrubber,
    back_icon: faChevronLeft,
    blockOption: faGripVertical,
    separator: faGripLinesVertical,
    bold: faBold,
    bookmark: faBookmark,
    centerAlign: faAlignCenter,
    microphone: faMicrophone,
    check: faCheckCircle,
    clear: faEraser,
    js: faCode,
    java: faCode,
    py: faCode,
    yaml: faCode,
    yml: faCode,
    sql: faCode,
    sh: faCode,
    php: faCode,
    js: faCode,
    ts: faCode,
    csharp: faCode,
    rb: faCode,
    jsx: faCode,
    tsx: faCode,
    scan: faScannerTouchscreen,
    clock: faClock,
    close: faTimesCircle,
    code: faCode,
    convert: faExchangeAlt,
    decision: faRocketLaunch,
    agentFunction: faCodeMerge,
    acg: faBookReader,
    response: faBracketsCurly,
    charting: faChartBar,
    basePath: faSatelliteDish,
    idea: faLightbulbExclamation,
    delete: faTrash,
    duplicate: faCopy,
    email: faPaperPlaneTop,
    enableAI: faChartNetwork,
    widget: faBrowser,
    events: faInbox,
    file: faFileAlt,
    txt: faFile,
    md: faCode,
    video: faVideoPlus,
    audio: faFileAudio,
    minimise: faWindowMinimize,
    reload: faSync,
    html: faFileCode,
    contentCreator: faUserShakespeare,
    planner: faUserPilotTie,
    coder: faUserRobotXmarks,
    businessAnalyst: faUserVisor,
    casual: faUserNinja,
    folder: faFolder,
    persona: faUserGear,
    fullscreen: faExpandWide,
    heading1: faH1,
    embeddings: faPageBreak,
    heading2: faH2,
    highlight: faHighlighter,
    history: faHistory,
    home: faHomeAlt,
    import: faFileUpload,
    horizontalOption: faEllipsisH,
    intentSelection: faHeadSideBrain,
    italic: faItalic,
    answer_icon: faReply,
    keyboardHide: faKeyboard,
    layerGroup: faLayerGroup,
    link: faLink,
    lock: faLock,
    writer: faTypewriter,
    meeting: faCalendarEdit,
    menu: faBars,
    issue: faMeteor,
    navigationBack: faChevronLeft,
    quantumChat: faCommentsAlt,
    chatIcon: faCommentAltLines,
    navigationTo: faChevronRight,
    ner: faTag,
    NERentity: faRocketLaunch,
    notes: faBookOpen,
    notification: faBell,
    ol: faListOl,
    option: faEllipsisV,
    arrowAlt: faArrowToRight,
    hash: faHashtag,
    paintRoller: faPaintRoller,
    plan: faClipboardListCheck,
    question_icon: faQuestionCircle,
    day: faCalendarDay,
    quote: faQuoteLeft,
    redo: faRedo,
    ba: faFileChartLine,
    requirement: faPen,
    risk: faExclamationTriangle,
    search: faSearch,
    settings: faSlidersVSquare,
    slidersV: faSlidersV,
    slidersH: faSlidersH,
    strikethrough: faStrikethrough,
    template: faFileAlt,
    theme_adjust: faAdjust,
    show: faEye,
    hide: faEyeSlash,
    times: faTimes,
    timezone: faGlobeEurope,
    trainAI: faUserRobot,
    ul: faListUl,
    image: faImage,
    uncheck: faCircle,
    underlined: faUnderline,
    undo: faUndo,
    unlink: faUnlink,
    unlock: faLockOpen,
    urlGen: faFileImport,
    verticalOption: faEllipsisV,
    workspace: faGameBoardAlt,
    highPriority: faChevronDoubleUp,
    mediumPriority: faChevronUp,
    lowPriority: faChevronDown,
    orbit: faPlanetRinged,
    export: faFileExport,
    generateEmail: faEnvelopeOpenText,
    ai: faBrain,
    cogWheel: faCog,
    pdf: faFilePdf,
    edit: faEdit,
    terminal: faTerminal,
    fontColor: faFont,
    network: faNetworkWired,
    logout: faPowerOff,
    goBack: faArrowSquareLeft,
    parse: faFileSignature,
    share: faShareAlt,
    trainingLog: faStream,
    expandVertically: faExpandAlt,
    collapseVertically: faCompressAlt,
    users: faUsers,
    private: faUserLock,
    usersSettings: faUsersCog,
    userInfo: faUserCircle,
    billing: faCreditCard,
    pin: faThumbtack,
    parser: faLaptopCode,
    admin: faUserShield,
    accessDenied: faBan,
    copy: faClone,
    answerer: faCommentLines,
    signOut: faSignOut,
    save: faCloudUpload,
    info: faInfoCircle,
    progress: faSpinner,
    cost: faDollarSign,
    question: faQuestion,
    enter: faArrowCircleRight,
    plus: faPlus,
    invoice: faFileInvoiceDollar,
    billingDetails: faMoneyCheckAlt,
    trial: faVial,
    data: faDatabase,
    collection: faBooks,
    language: faLanguage,
    networkError: faWifiSlash,
    activeModel: faWaveSquare,
    quickCopy: faClipboard,
    agent: faUserSecret,
    experiment: faVial,
    zoomIn: faSearchPlus,
    zoomOut: faSearchMinus,
    stopGeneration: faStopCircle,
    slidersH: faSlidersH,
    hosting: faServer,
    advanced: faTools,
    dashboard: faAnalytics,
    files: faFiles,
    goTo: faArrowTurnDownRight,
    warning: faTriangleExclamation,
    channels: faMessageBot,
    speakers: faVolume,
    downloadAudio: faFileAudio,
    stopPlaying: faVolumeSlash,
    images: faImages,
    arena: faSwordsLaser,
    newBattle: faHelmetBattle,
    expand: faUpRightAndDownLeftFromCenter,
    collapse: faDownLeftAndUpRightToCenter,
    spreadsheet: faFileSpreadsheet,
    presentation: faPresentationScreen,
    document: faFileInvoice,
    teach: faChalkboardTeacher
  }
};

export { AppAssets as A };
