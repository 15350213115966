import AppForms from "@toothfairy/shared-ui/AppForms";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { useCallback, useEffect } from "react";
import { View } from "react-native";
import ToothFairyButton from "../../../../Components/ToothFairyComponents/ToothFairyButton";
import Constants from "../../../../Constants";
import useWorkspaces from "../../../../Hooks/useWorkspaces";
import { isLargeScreen } from "../../../MainViewContainer";
import validations from "../Functions/validations";
import connectionsHelper from "../../../../API/Connections/connectionsHelper";
import AdminInfoLeaf from "../../../Admin/Components/SubComponents/AdminInfoLeaf";
import authorisationsHelper from "../../../../API/Authorisations/authorisationsHelper";

const FunctionSettingsAIView = ({ isEdit, settingType, handleDeletion }) => {
  const { currentTheme, mode } = AppTheme.useTheme();
  const [availableConnections, setAvailableConnections] = React.useState([]);
  const [availableAuthorisations, setAvailableAuthorisations] = React.useState(
    []
  );
  const entityName = "Functions mapping";
  const { setFieldValue, values, validationSchema } =
    AppForms.useFormikContext();
  const { state } = AppStateManager.useAppStateManager();
  const { getActiveWorkspace, getPlanConfig } = useWorkspaces();
  const fetchAuthorisations = async () => {
    const authorisations = await authorisationsHelper.getAuthorisations(
      getActiveWorkspace()?.id
    );
    const _auth =
      authorisations?.data?.authorisationsByWorkspace?.items?.filter(
        (u) => u?._deleted != true
      ) || [];
    setAvailableAuthorisations(_auth);
  };
  const fetchConnections = async () => {
    const connections = await connectionsHelper.getDatabaseConnections(
      getActiveWorkspace()?.id
    );
    const _conn =
      connections?.data?.connectionsByWorkspace?.items?.filter(
        (u) => u?._deleted != true
      ) || [];
    setAvailableConnections(_conn);
    if (_conn?.length > 0) setFieldValue("dbConnectionID", _conn[0]?.id);
  };
  const _title = useCallback(() => {
    return isEdit ? `Save` : `Create`;
  }, [entityName, isEdit]);
  useEffect(() => {
    if (values?.isDatabaseScript) {
      fetchConnections();
    }
    fetchAuthorisations();
  }, [values?.isDatabaseScript]);
  useEffect(() => {
    if (values?.chatAction === "script") setFieldValue("isCallbackFn", false);
  }, [values?.chatAction]);
  // const { setFieldValue, values } = AppForms.useFormikContext();
  // useEffect(() => {
  //   if (values?.sourceLanguage !== "en") setFieldValue("targetLanguage", "en");
  //   if (values?.targetLanguage !== "en") setFieldValue("sourceLanguage", "en");
  // }, [values]);
  return (
    <>
      <>
        <View>
          {isEdit && (
            <AdminInfoLeaf
              wrapperStyle={{
                alignSelf: "flex-start",
                marginBottom: 10,
              }}
              textContainer={{
                marginLeft: 5,
                marginTop: 10,
                marginBottom: 0,
              }}
              titleStyle={{
                marginTop: 0,
                marginLeft: 20,
              }}
              textStyle={{
                marginLeft: 0,
              }}
              title={"ID"}
              value={values?.id}
              width={420}
            />
          )}
          <AppForms.AppFormField
            showTextInputLabel
            isMandatory
            wrapperInputStyle={{
              paddingTop: 10,
            }}
            label={`Name`}
            placeholder={`function_name`}
            keyboardtype="default"
            textContextType="label"
            autoCorrect={false}
            name="name"
          />
          <AppForms.AppFormField
            showTextInputLabel
            isMandatory
            wrapperInputStyle={{
              paddingTop: 10,
            }}
            multiline
            textContainer={{
              minHeight: 120,
              paddingVertical: 10,
            }}
            label={`Description`}
            placeholder={`Description of what the function does. The more details the better.`}
            keyboardtype="default"
            textContextType="label"
            autoCorrect={false}
            name="description"
          />
          {values?.isDatabaseScript ? (
            <AppForms.AppFormField
              showTextInputLabel
              isMandatory
              wrapperInputStyle={{
                paddingTop: 10,
              }}
              label={`Stored procedure`}
              placeholder={`Stored procedure name`}
              keyboardtype="default"
              textContextType="label"
              autoCorrect={false}
              name={"dbScript"}
            />
          ) : values?.isHtmlFn ? (
            <AppForms.AppFormField
              showTextInputLabel
              isMandatory
              wrapperInputStyle={{
                paddingTop: 10,
              }}
              label={`URL`}
              placeholder={`Full URL of the HTML to render `}
              keyboardtype="default"
              textContextType="label"
              autoCorrect={false}
              name={"htmlUrl"}
            />
          ) : values?.isChatFn ? (
            <></>
          ) : (
            <AppForms.AppFormField
              showTextInputLabel
              isMandatory
              wrapperInputStyle={{
                paddingTop: 10,
              }}
              label={`URL`}
              placeholder={`Full URL of the function endpoint `}
              keyboardtype="default"
              textContextType="label"
              autoCorrect={false}
              name={"url"}
            />
          )}
          {!values?.isHtmlFn && !values?.isChatFn && (
            <AppForms.AppFormFieldJSONV2
              showTextInputLabel
              isMandatory
              wrapperInputStyle={{
                paddingTop: 10,
              }}
              wrapperContainer={{
                height: 450,
              }}
              mode="code"
              schema={validations.parametersVal}
              label={`Parameters`}
              name="parameters"
              darkMode={mode === "dark"}
            />
          )}
          {values?.isChatFn && (
            <AppForms.AppFormField
              showTextInputLabel
              isMandatory
              name="chatScript"
              label={`Chat script`}
              placeholder="Explain what the agent should say or do when this function is called"
              multiline={true}
              numberOfLines={4}
              style={{
                marginTop: 2,
              }}
              wrapperInputStyle={{
                marginBottom: 10,
                marginTop: 10,
              }}
              textContainer={{
                margin: 0,
                padding: 10,
                minHeight: 120,
              }}
              type="text"
            />
          )}
        </View>
        {values?.isDatabaseScript ? (
          <AppForms.AppFormDropDown
            isElevated={false}
            dropDownStyle={{
              borderColor: currentTheme?.theme?.border_color,
              maxWidth: !isLargeScreen() && 250,
            }}
            showTextInputLabel
            label="Connection type"
            isMandatory
            name="dbConnectionID"
            items={availableConnections?.map((item) => ({
              id: item?.id,
              name: `${item?.name} - ${item?.type?.toUpperCase()}`,
            }))}
            propertyLabel="name"
            propertyValue="id"
          />
        ) : values?.isHtmlFn ? (
          <View
            style={{
              zIndex: -1,
            }}
          >
            <AppForms.AppFormSwitch
              wrapperStyle={[
                {
                  backgroundColor: isEdit
                    ? currentTheme?.theme?.grey
                    : currentTheme?.theme?.lightGrey,
                  maxHeight: 30,
                  paddingHorizontal: 10,
                  borderRadius: 20,
                  paddingVertical: 18,
                  margin: 5,
                  marginTop: 10,
                },
              ]}
              labelTextStyle={{
                fontSize: currentTheme?.theme?.small_font_size,
                color: currentTheme?.theme?.darkGrey,
              }}
              showTextInputLabel={true}
              label={"Requires redirect"}
              displayIsTicked={true}
              name="isHtmlRedirectFn"
              value={values?.isHtmlRedirectFn}
            />
            {getActiveWorkspace()?.subscriptionType == "enterprise" && (
              <>
                <AppForms.AppFormField
                  showTextInputLabel
                  wrapperInputStyle={{
                    paddingTop: 10,
                  }}
                  label={`Message on successful submit (Form only)`}
                  placeholder={`Message to display on successful interaction`}
                  keyboardtype="default"
                  textContextType="label"
                  autoCorrect={false}
                  name="htmlFormOnSuccessfulSubmitMessage"
                />
                <AppForms.AppFormField
                  showTextInputLabel
                  wrapperInputStyle={{
                    paddingTop: 10,
                  }}
                  label={`Message on failed submit (Form only)`}
                  placeholder={`Message to display on failed interaction`}
                  keyboardtype="default"
                  textContextType="label"
                  autoCorrect={false}
                  name="htmlFormOnFailedSubmitMessage"
                />
              </>
            )}
          </View>
        ) : values?.isChatFn ? (
          <AppForms.AppFormDropDown
            isElevated={false}
            dropDownStyle={{
              borderColor: currentTheme?.theme?.border_color,
              maxWidth: !isLargeScreen() && 250,
            }}
            showTextInputLabel
            label="Chat action type"
            isMandatory
            name="chatAction"
            items={[
              "suggestion",
              "nextQuestion",
              // "humanSupport",
              // "redirection",
              "script",
            ].map((item) => ({
              id: item,
              name: item,
            }))}
            propertyLabel="name"
            propertyValue="id"
          />
        ) : (
          <View
            style={{
              zIndex: -1,
            }}
          >
            <AppForms.AppFormDropDown
              isElevated={false}
              dropDownStyle={{
                borderColor: currentTheme?.theme?.border_color,
                maxWidth: !isLargeScreen() && 250,
              }}
              showTextInputLabel
              label="Request type"
              isMandatory
              name="requestType"
              items={["GET", "POST"].map((item) => ({
                id: item,
                name: item,
              }))}
              propertyLabel="name"
              propertyValue="id"
            />
            {values?.requestType == "GET" && (
              <AppForms.AppFormSwitch
                wrapperStyle={[
                  {
                    backgroundColor: isEdit
                      ? currentTheme?.theme?.grey
                      : currentTheme?.theme?.lightGrey,
                    maxHeight: 30,
                    paddingHorizontal: 10,
                    borderRadius: 20,
                    paddingVertical: 18,
                    margin: 5,
                    marginTop: 10,
                    marginBottom: 10,
                  },
                ]}
                labelTextStyle={{
                  fontSize: currentTheme?.theme?.small_font_size,
                  color: currentTheme?.theme?.darkGrey,
                }}
                showTextInputLabel={true}
                label={"Params as path"}
                displayIsTicked={true}
                name="paramsAsPath"
                value={values?.paramsAsPath}
              />
            )}

            <AppForms.AppFormDropDown
              isElevated={false}
              dropDownStyle={{
                borderColor: currentTheme?.theme?.border_color,
                maxWidth: !isLargeScreen() && 250,
              }}
              showTextInputLabel
              label="Authorisation type"
              isMandatory
              name="authorisationType"
              items={["BEARER", "APIKEY", "OAUTH", "NONE"].map((item) => ({
                id: item,
                name: item,
              }))}
              propertyLabel="name"
              propertyValue="id"
            />
            {values?.authorisationType !== "NONE" && (
              <AppForms.AppFormDropDown
                isElevated={false}
                showTextInputLabel
                wrapperInputStyle={{
                  paddingTop: 10,
                }}
                items={[
                  { id: "none", name: "none" },
                  ...availableAuthorisations?.filter(
                    (u) => u?.type === values?.authorisationType?.toLowerCase()
                  ),
                ]}
                label={`Authorisation`}
                placeholder={`Authorisation to authenticate the request`}
                keyboardtype="default"
                textContextType="label"
                autoCorrect={false}
                name="authorisationID"
                hideShowIcon={true}
                propertyLabel="name"
                propertyValue="id"
              />
            )}
            {
              <>
                <AppForms.AppFormFieldJSONV2
                  showTextInputLabel
                  wrapperInputStyle={{
                    paddingTop: 10,
                  }}
                  mode="code"
                  darkMode={mode === "dark"}
                  label={`Additional headers`}
                  placeholder={`{"myCustomHeader": "myCustomValue", "myCustomHeader2": "myCustomValue2"}`}
                  name="headers"
                />
                <AppForms.AppFormFieldJSONV2
                  showTextInputLabel
                  wrapperInputStyle={{
                    paddingTop: 10,
                  }}
                  mode="code"
                  formFieldContainerStyle={{
                    zIndex: -1,
                  }}
                  darkMode={mode === "dark"}
                  label={`Static arguments`}
                  placeholder={`{"param1": "myStaticParam1", "param2": "myStaticParam2"}`}
                  name="staticArgs"
                />
              </>
            }
          </View>
        )}
      </>
      {!values?.isHtmlFn && !values?.isChatFn && (
        <>
          <AppForms.AppFormField
            showTextInputLabel
            wrapperInputStyle={{
              paddingTop: 10,
            }}
            label={`JSON path extractor`}
            placeholder={`Extract field from response as dot-separated path (e.g. "customer.tickets.0.id")`}
            keyboardtype="default"
            textContextType="label"
            autoCorrect={false}
            name="jsonPathExtractor"
          />
          <AppForms.AppFormDropDown
            isElevated={false}
            dropDownStyle={{
              borderColor: currentTheme?.theme?.border_color,
              maxWidth: !isLargeScreen() && 250,
            }}
            showTextInputLabel
            label="Scope"
            name="scope"
            items={[
              {
                id: "none",
                name: "none",
              },
              {
                id: "customerAuthentication",
                name: "Customer authentication",
              },
              {
                id: "caseAuthentication",
                name: "Case authentication",
              },
              {
                id: "customer",
                name: "Customer retrieval",
              },
              {
                id: "case",
                name: "Case retrieval",
              },
            ]}
            propertyLabel="name"
            propertyValue="id"
          />
        </>
      )}
      {getActiveWorkspace()?.subscriptionType != "base" && (
        <>
          <AppForms.AppFormDropDown
            isElevated={false}
            dropDownStyle={{
              borderColor: currentTheme?.theme?.border_color,
              maxWidth: !isLargeScreen() && 250,
            }}
            showTextInputLabel
            label="Hand-off to agent on execution"
            name="handOffAgent"
            items={[
              {
                id: null,
                name: "none",
              },
              ...(state?.agents
                ?.map((item) => {
                  if (
                    item?.agentType === "Chatbot" &&
                    item?.id != values?.handOffAgentOnCompletion
                  )
                    return {
                      id: item?.id,
                      name: item?.label,
                    };
                })
                ?.filter((u) => u) || []),
            ]}
            propertyLabel="name"
            propertyValue="id"
          />
          {values?.handOffAgent && (
            <>
              <AppForms.AppFormSwitch
                wrapperStyle={[
                  {
                    backgroundColor: isEdit
                      ? currentTheme?.theme?.grey
                      : currentTheme?.theme?.lightGrey,
                    maxHeight: 30,
                    paddingHorizontal: 10,
                    borderRadius: 20,
                    paddingVertical: 18,
                    margin: 5,
                    marginTop: 10,
                    marginBottom: 10,
                  },
                ]}
                labelTextStyle={{
                  fontSize: currentTheme?.theme?.small_font_size,
                  color: currentTheme?.theme?.darkGrey,
                }}
                showTextInputLabel={true}
                label={"Hand-off to initial agent on completion"}
                displayIsTicked={true}
                name="handOffToOriginalAgentOnCompletion"
                value={values?.handOffToOriginalAgentOnCompletion}
              />
              {!values?.handOffToOriginalAgentOnCompletion && (
                <AppForms.AppFormDropDown
                  isElevated={false}
                  dropDownStyle={{
                    borderColor: currentTheme?.theme?.border_color,
                    maxWidth: !isLargeScreen() && 250,
                  }}
                  showTextInputLabel
                  label="Hand-off to agent on completion"
                  name="handOffAgentOnCompletion"
                  items={[
                    {
                      id: null,
                      name: "none",
                    },
                    ...(state?.agents
                      ?.map((item) => {
                        if (
                          item?.agentType === "Chatbot" &&
                          item?.id != values?.handOffAgent
                        )
                          return {
                            id: item?.id,
                            name: item?.label,
                          };
                      })
                      ?.filter((u) => u) || []),
                  ]}
                  propertyLabel="name"
                  propertyValue="id"
                />
              )}
            </>
          )}
        </>
      )}
      {!values?.isHtmlFn && (
        <AppForms.AppFormSwitch
          wrapperStyle={[
            {
              backgroundColor: isEdit
                ? currentTheme?.theme?.grey
                : currentTheme?.theme?.lightGrey,
              maxHeight: 30,
              paddingHorizontal: 10,
              borderRadius: 20,
              paddingVertical: 18,
              margin: 5,
              marginTop: 10,
            },
          ]}
          labelTextStyle={{
            fontSize: currentTheme?.theme?.small_font_size,
            color: currentTheme?.theme?.darkGrey,
          }}
          showTextInputLabel={true}
          label={"Callback function"}
          displayIsTicked={true}
          name="isCallbackFn"
          value={values?.isCallbackFn}
          disabled={[
            "customer",
            "case",
            "customerAuthentication",
            "caseAuthentication",
          ]?.includes(values?.scope)}
        />
      )}
      <View style={{ alignItems: "center" }}>
        <AppForms.AppSubmitButton
          title={_title()}
          awesomeIconColor={currentTheme?.theme?.white}
          backgroundColor={currentTheme?.theme?.primary}
          btnContentStyle={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          style={[
            {
              height: Constants.appButtonHeight,
              width: "auto",
              shadowColor: currentTheme?.theme?.transparent,
              marginHorizontal: 10,
            },
          ]}
          btnLabelStyle={{ fontSize: 16 }}
          wrapperStyle={[
            {
              width: "fit-content",
              minWidth: 150,
              marginTop: 20,
              height: Constants.appButtonHeight,
            },
          ]}
        />
        {isEdit && (
          <ToothFairyButton
            title={"Delete"}
            onPress={handleDeletion}
            isDeletion
            wrapperStyle={{
              marginTop: 30,
              minWidth: 150,
              height: Constants.appButtonHeight,
            }}
          />
        )}
      </View>
    </>
  );
};

export default FunctionSettingsAIView;
